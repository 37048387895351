// src/store/index.js
import { createStore } from 'vuex';
import sheets from './sheets';
import machineFlow from './machineFlow';

const store = createStore({
  modules: {
    sheets,
    machineFlow
  },
});

export default store;
