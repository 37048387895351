<template>
  <div class="flex h-screen">
    <TreeMenu></TreeMenu>
    <div class="flex-1 p-4 mb-16 body-bg overflow-y-auto">
      <div class="grid grid-rows-[auto,auto,1fr] p-4 h-screen-auto bg-white shadow-md rounded-md p-4 mx-4">
        <div class="h-16 mb-4">
          <h1 class="font-bold text-[2rem] mt-2">
            Scenario Architect
          </h1>
        </div>

        <div class="flex space-x-4">
          <div class="bg-white rounded-md shadow px-3 pt-3 pb-1 max-w-xs w-1/4">
            <div class="text-xxs text-gray-500 font-semibold mb-1">
              Current BF Coke Rate
            </div>
            <div class="flex justify-between items-center mb-1">
              <div class="text-[2rem] font-bold text-ppo-custom-blue">{{ currentBfCokeRate?.toFixed(1) || 0 }}</div>
            </div>
          </div>
          <div class="bg-white rounded-md shadow px-3 pt-3 pb-1 max-w-xs w-1/4" v-if="optimizerResponseData?.Predicted_Yield">
            <div class="text-xxs text-gray-500 font-semibold mb-1">
              Estimated BF Coke Rate
            </div>
            <div class="flex justify-between items-center mb-1">
              <div class="text-[2rem] font-bold text-red-500">{{ optimizerResponseData?.Predicted_Yield?.toFixed(1) || 0 }}</div>
            </div>
          </div>

          <div class="bg-white rounded-md shadow px-3 pt-3 pb-1 max-w-xs w-1/4" v-if="optimizerResponseData?.Optimized_Yield">
            <div class="text-xxs text-gray-500 font-semibold mb-1">
              Optimized BF Coke Rate
            </div>
            <div class="flex justify-between items-center mb-1">
              <div class="text-[2rem] font-bold text-ppo-custom-green">{{ optimizerResponseData?.Optimized_Yield?.toFixed(1) || 0 }}</div>
            </div>
            <div class="mt-4 text-xs" v-if="!loading && percentageChange !== null">{{ percentageChange >= 0 ? percentageChange.toFixed(1) : percentageChange.toFixed(1) * -1 }}% optimized</div>
            <div class="mt-2 text-xs" v-if="!loading && !percentageChange">--</div>
          </div>
        </div>
        <div class="flex items-center justify-between mb-4 pl-1 text-sm">
          <div class="flex mt-4">
            <button v-for="(tab, index) in tabs" :key="tab.name" @click="selectedTab = index" :class="[
              'rounded tab-button px-4 py-2',
              selectedTab === index ? 'blue-custom text-white' : 'bg-white text-black hover:blue-custom'
            ]" class="text-sm" :style="{ marginLeft: '-5px' }">
              {{ tab.name }}
            </button>
          </div>
          <div class="flex gap-4 mt-4" v-if="currentBfCokeRate">
            <button v-if="!loading" @click="predictAndScroll" class="px-12 py-2 rounded-sm blue-custom text-white hover:blue-custom">
              Predict
            </button>
            <div v-if="loading" class="flex justify-center items-center mt-4">
              <div class="spinner-border animate-spin border-4 border-blue-600 border-t-transparent rounded-full w-10 h-10"></div>
            </div>
          </div>
        </div>
        <div class="flex gap-4 py-4 text-sm">
          <div class="flex-1" v-if="selectedTab === 0">
            <div class="shadow-md rounded-md overflow-hidden">
              <table class="w-full text-left">
                <thead class="bg-blue-custom">
                  <tr>
                    <td class="py-2 px-4 border-b bg-blue-custom text-white">Controllable Parameter</td>
                    <td colspan="2" class="py-2 px-4 border-b bg-blue-custom text-white">Value</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(param, key) in dataConfigurableParameters.controllable" :key="key" :class="rowStyle(key)">
                    <td class="py-2 px-4 w-[40%]">{{ param.unit ? `${param.name} (${param.unit})` : param.name }}</td>
                    <td class="py-2 px-4 w-[20%]">{{ param.value }}</td>
                    <td class="py-2 px-4 w-[40%]">
                      <input type="range" :id="'price-range-' + param.key" class="w-full accent-blue-custom"
                        :min="param.min" :max="param.max" v-model="param.proposed"
                        @input="handleInputChange(key, 'controllable')" :step="0.1" />
                      <div class="flex justify-between text-[0.7rem] font-bold mt-[-0.2rem]">
                        <span>{{ Number(param.min) }}</span>
                        <span>{{ Number(param.max) }}</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="flex-1" v-if="selectedTab === 1">
            <div class="shadow-md rounded-md overflow-hidden">
              <table class="w-full text-left">
                <thead class="bg-blue-custom">
                  <tr>
                    <td class="py-2 px-4 border-b bg-blue-custom text-white">Uncontrollable Parameter</td>
                    <td colspan="2" class="py-2 px-4 border-b bg-blue-custom text-white">Value</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(param, key) in dataConfigurableParameters.uncontrollable" :key="key"
                    :class="rowStyle(key)">
                    <td class="py-2 px-4 w-[40%]">{{ param.unit ? `${param.name} (${param.unit})` : param.name }}</td>
                    <td class="py-2 px-4 w-[20%]">{{ param.value }}</td>
                    <td class="py-2 px-4 w-[40%]">
                      <input type="range" :id="'price-range-' + param.key" class="w-full accent-blue-custom"
                        :min="0" :max="4000" v-model="param.proposed"
                        @input="handleInputChange(key, 'uncontrollable')"  :step="0.1" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="w-1/3" v-if="selectedTab === 0">
            <div class="bg-white shadow-md rounded-md overflow-hidden">
              <table class="w-full text-left">
                <thead>
                  <tr class="bg-blue-custom">
                    <td class="py-2 px-4 border-b text-white">Current</td>
                    <td class="py-2 px-4 border-b text-white">Proposed <button @click="clearProposedValues" class="ml-4 underline text-[0.625rem]">Clear Values</button></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in dataConfigurableParameters.controllable" :key="index"
                    :class="rowStyle(index)">
                    <td class="result px-4">{{ data.value }}</td>
                    <td class="result px-4">{{ data.proposed || '-' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="w-1/3" v-if="selectedTab === 1">
            <div class="bg-white shadow-md rounded-md overflow-hidden">
              <table class="w-full text-left">
                <thead>
                  <tr class="bg-blue-custom">
                    <td class="py-2 px-4 border-b text-white">Current</td>
                    <td class="py-2 px-4 border-b text-white">Proposed<button @click="clearProposedValues" class="ml-4 underline text-[0.625rem]">Clear Values</button></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in dataConfigurableParameters.uncontrollable" :key="index"
                    :class="rowStyle(index)">
                    <td class="py-2 pb-3.5 px-4">{{ data.currentSimulator }}</td>
                    <td class="py-2 pb-3.5 px-4">{{ data.proposed || '-' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- Sensitivity Section -->
      <div ref="sensitivitySection" class="w-full p-4">
        <div v-if="tornadChartData && Object.keys(tornadChartData).length" class="bg-white shadow-md rounded-md overflow-hidden p-4">
          <h1 class="font-bold text-[1.5rem] mt-2">
            Sensitivity of Blast Furnace Controllable Parameter on Coke Rate
          </h1>
          <h3 class="text-[1rem] mt-2">
            Tornado Chart : Sensitivity of Blast Furnace Parameters on Coke Rate
          </h3>
          <TornadoChart :tornadoData="tornadChartData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import TreeMenu from './TreeMenu.vue';
import TornadoChart from './TornadoChart.vue';
import dataConfigurableParameters from './dataConfigurableParameters';
import { mapState } from 'vuex';

export default {
  name: "ProcessParameterSimulator",
  components: {
    TreeMenu,
    TornadoChart
  },
  data() {
    return {
      apiBaseUrl: process.env.VUE_APP_COMBUST_API_BASE_URL,
      options: ['Top Gas Pressure', 'Oxygen Enrichment', 'Blast Temperature'],
      selectedTab: 0,
      tabs: [
        { name: 'Controllable Parameters' },
        { name: 'Uncontrollable Parameters' }
      ],
      dataConfigurableParameters,
      responseData: null,
      tornadChartData: null,
      optimizerResponseData: null,
      valueProposedFor: [],
      loading: false,
      desiredSequence: [
        "blast_temp",
        "top_gas_pressure_average",
        "oxygen_enriched_rate",
        "gas_utilization_rate",
        "top_gas_temperature_average",
        "blast_speed",
        "burden_permeability_index",
        "hot_blast_pressure",
        "heat_loss_MJ",
        "steam_addition",
        "RAFT",
        "Bosh Gas Volume (Nm3/min)",
        "Si_1.2_max",
        "S_0.045_max",
        "Ti",
        "Hot_Metal_Temp",
        "Slag_Basicity",
        "Pellet%",
        "Sinter%",
        "Slag Volume",
        "Flux Rate",
        "Burden Fe %",
        "Skull",
        "Moisture_x",
        "ASH_x",
        "Micum_Index_M10_x",
        "MPS_x",
        "CSR_x",
        "Moisture_y",
        "ASH_y",
        "Micum_Index_M10_y",
        "Sinter Basicity",
        "pci_rate"
      ]
    };
  },
  methods: {
    rowStyle(index) {
      return index % 2 !== 0 ? "bg-[#EBF6FF99]" : "bg-white";
    },
    handleInputChange(index, type) {
      if (!this.valueProposedFor.includes(index)) {
        this.valueProposedFor.push(index)
      }
    },
    predictAndScroll() {
      this.submitEstimate();
    },
    scrollToTornadoChart() {
      const sensitivitySection = this.$refs.sensitivitySection;
      if (sensitivitySection) {
        sensitivitySection.scrollIntoView({ behavior: 'smooth' });
      }
    },
    async submitEstimate() {
      const allParams = {};

      // Prepare the payload for the API request
      Object.keys(this.dataConfigurableParameters.controllable).forEach((param) => {
        allParams[param] = this.valueProposedFor.includes(param) ? Number(this.dataConfigurableParameters.controllable[param].proposed) : Number(this.dataConfigurableParameters.controllable[param].value);
      });
      Object.keys(this.dataConfigurableParameters.uncontrollable).forEach((param) => {
        allParams[param] = this.valueProposedFor.includes(param) ? Number(this.dataConfigurableParameters.uncontrollable[param].proposed) : Number(this.dataConfigurableParameters.uncontrollable[param].value);
      });

      const estimatePayload = { allParams };

      console.log(estimatePayload, 'estimatePayload')

      this.loading = true;
      try {
        const optimizerResponse = await axios.post(this.apiBaseUrl+'optimize-setpoints', estimatePayload);
        this.optimizerResponseData = optimizerResponse?.data?.result || null;

        if (this.optimizerResponseData) {
          this.optimizerResponseData.Optimized_Params

          for (let controllableParam in this.dataConfigurableParameters.controllable) {
            if (this.optimizerResponseData.Optimized_Params?.[controllableParam] && !this.valueProposedFor.includes(controllableParam)) {
              console.log(this.optimizerResponseData.Optimized_Params?.[controllableParam], 'this.optimizerResponseData.Optimized_Params?.[controllableParam]')
              this.dataConfigurableParameters.controllable[controllableParam].proposed = this.optimizerResponseData.Optimized_Params[controllableParam].toFixed(1)
            }
          }

          for (let uncontrollableParam in this.dataConfigurableParameters.uncontrollable) {
            if (this.optimizerResponseData.Optimized_Params?.[uncontrollableParam]) {
              this.dataConfigurableParameters.controllable[uncontrollableParam].proposed = this.optimizerResponseData.Optimized_Params[uncontrollableParam].toFixed(1)
            }
          }

        const tornadoPayload = { allParams: { ...this.optimizerResponseData.Optimized_Params, ...this.optimizerResponseData.Input_Params }  }

        const sortedData = {
          allParams: Object.fromEntries(
            this.desiredSequence.map((key) => [key, tornadoPayload.allParams[key]])
          ),
        };

        const response = await axios.post(this.apiBaseUrl+'simulator', sortedData);
        this.responseData = response?.data?.result || null;
        console.log('API Response:', this.responseData);
        }
      } catch (error) {
        console.error('API Error:', error.response || error);
        alert('Failed to submit estimate.');
      } finally {
        this.loading = false;
        // this.scrollToTornadoChart();
      }
    },
    async fetchInitialTornadoChartData() {
      const allParams = {};

      // Collect the current simulator values for controllable and uncontrollable parameters
      for (let controllableParam in this.dataConfigurableParameters.controllable) {
        allParams[controllableParam] = this.dataConfigurableParameters.controllable[controllableParam]?.currentSimulator;
      }

      for (let uncontrollableParam in this.dataConfigurableParameters.uncontrollable) {
        allParams[uncontrollableParam] = this.dataConfigurableParameters.uncontrollable[uncontrollableParam]?.currentSimulator;
      }

      const tornadoPayload = { allParams };

      // Prepare the sorted data
      const sortedData = {
        allParams: Object.fromEntries(
          this.desiredSequence.map((key) => [key, tornadoPayload.allParams[key]])
        ),
      };
      console.log(tornadoPayload, 'sortedData')

      console.log(sortedData, 'sortedData')

      try {
        const response = await axios.post(this.apiBaseUrl + 'simulator', sortedData);
        this.responseData = response?.data?.result || null;  // Update responseData
        console.log('API Response:', this.responseData);
      } catch (error) {
        console.error('API Error:', error.response || error);
        alert('Failed to fetch initial data for Tornado Chart.');
      }
    },
    clearProposedValues() {
      for (let controllableParam in this.dataConfigurableParameters.controllable) {
        this.dataConfigurableParameters.controllable[controllableParam].proposed = 0
      }
      for (let uncontrollableParam in this.dataConfigurableParameters.uncontrollable) {
        this.dataConfigurableParameters.uncontrollable[uncontrollableParam].proposed = 0
      }

    }
  },
  watch: {
    responseData: {
      immediate: true,
      handler(newVal) {
        this.tornadChartData = newVal;
      },
    },
  },
  computed: {
    ...mapState('sheets', [
      'currentBfCokeRate'
    ]),
    percentageChange() {
      if (this.optimizerResponseData) {
        const predictedYield = this.optimizerResponseData?.Predicted_Yield;
        const optimizedYield = this.optimizerResponseData?.Optimized_Yield;

        if (predictedYield != null && optimizedYield != null && optimizedYield !== 0) {
          const change = ((optimizedYield - predictedYield) / predictedYield) * 100;
          return change;  // Return the percentage change
        }
      }
      return null;  // Return null if data is missing or optimizedYield is zero
    }
  },
  mounted() {
    this.fetchInitialTornadoChartData();  // Call an async method to fetch the data
  }
};
</script>

<style>
.result {
  padding: 1.02rem 1.125rem;
}
.blue-custom {
  background: #232B60;
}

.body-bg {
  background: #F5F6FA;
  border-left: #D9D9D9 1px solid;
}

.tab-button {
  border: #232B60 1px solid;
  font-size: 1rem;
}
</style>
