<template>
  <div>
    <div ref="spline3DChart" style="height: 600px; width: 800px;"></div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';

Highcharts3D(Highcharts);

export default {
  name: 'ReverseParabola3D',
  props: {
    chartData: {
      type: Object,
      required: true
    },
    selectedXAxis: {
      type: String,
      required: true
    },
    selectedYAxis: {
      type: String,
      required: true
    },
    dataConfigurableParameters: {
      type: Object,
      required: true
    }
  },
  watch: {
    chartData: {
      handler(newData) {
        if (newData) {
          this.renderChart(newData); // Call renderChart with the new data
        }
      },
      immediate: true
    },
    selectedXAxis() {
      this.renderChart(this.chartData); // Re-render when X axis selection changes
    },
    selectedYAxis() {
      this.renderChart(this.chartData); // Re-render when Y axis selection changes
    }
  },
  methods: {
    generateSpline3DPoints() {
      const { pdp_var_1, pdp_var_2, pdp_output } = this.chartData;
      const points = [];
      
      if (
        Array.isArray(pdp_var_1) &&
        Array.isArray(pdp_var_2) &&
        Array.isArray(pdp_output) &&
        pdp_var_1.length === pdp_var_2.length &&
        pdp_var_2.length === pdp_output.length &&
        pdp_var_1.length > 0 // Ensure data is not empty
      ) {
        for (let i = 0; i < pdp_var_1.length; i++) {
          const x = Math.round(pdp_var_1[i] * 10) / 10;
          const y = Math.round(pdp_var_2[i] * 10) / 10;
          const z = Math.round(pdp_output[i] * 10) / 10;

          points.push([x, y, z]);
        }

        console.log(points, 'points');
      } else {
        console.error('Response data arrays have mismatched lengths or are not arrays!');
      }

      return points;
    },
    renderChart(data) {
      this.$nextTick(() => {
        // Retrieve labels and units from dataConfigurableParameters
        const { name: xLabel, unit: xUnit } = this.dataConfigurableParameters[this.selectedXAxis];
        const { name: yLabel, unit: yUnit } = this.dataConfigurableParameters[this.selectedYAxis];

        const { pdp_var_1, pdp_var_2, pdp_output } = data;

        if (!pdp_var_1 || !pdp_var_2 || !pdp_output) {
          console.error('Response data is missing required properties.');
          return;
        }

        const chartContainer = this.$refs.spline3DChart;
        if (!chartContainer) {
          console.error('Chart container element not found!');
          return;
        }

        // Render the chart
        Highcharts.chart(chartContainer, {
          chart: {
            type: 'scatter3d',
            options3d: {
              enabled: true,
              alpha: 10,
              beta: 30,
              depth: 500,
              viewDistance: 25,
              frame: {
                bottom: { size: 1, color: 'rgba(0,0,0,0.02)' },
                back: { size: 1, color: 'rgba(0,0,0,0.04)' },
                side: { size: 1, color: 'rgba(0,0,0,0.06)' }
              }
            }
          },
          title: {
            text: 'Partial Dependence 3D Plot'
          },
          tooltip: {
            formatter: function() {
              // Format the tooltip to include axis labels and units
              const xValue = this.x;
              const yValue = this.y;
              const zValue = this.point.z;  // Access the z value from the point data

              const xFormatted = xUnit ? `${xValue?.toFixed(1)} ${xUnit}` : `${xValue?.toFixed(1)} ${xLabel}`;
              const yFormatted = yUnit ? `${yValue?.toFixed(1)} ${yUnit}` : `${yValue?.toFixed(1)} ${yLabel}`;
              const zFormatted = `${zValue?.toFixed(1)} Coke Rate (kg/tHM)`; // Static Z axis title

              return `${xFormatted}<br>${yFormatted}<br>${zFormatted}`;
            }
          },
          xAxis: {
            title: {
              text: xUnit ? `${xLabel} ${xUnit}` : xLabel, // Use selectedXAxis prop for title
              margin: 30  
            },
            min: Math.min(...(pdp_var_1 || [])),
            max: Math.max(...(pdp_var_1 || [])),
            labels: {
              formatter() {
                return this.value.toFixed(1); // Format to 1 decimal place
              },
            }
          },
          yAxis: {
            title: {
              text: yUnit ? `${yLabel} ${yUnit}` : yLabel,
              margin: 30  
            },
            min: Math.min(...(pdp_var_2 || [])),
            max: Math.max(...(pdp_var_2 || [])),
            labels: {
              formatter() {
                return this.value.toFixed(1); // Format to 1 decimal place
              }
            }
          },
          zAxis: {
            title: {
              text: 'Coke Rate (kg/tHM)', // Static title for Z axis
              margin: 30
            },
            min: Math.min(...(pdp_output || [])),
            max: Math.max(...(pdp_output || [])),
            labels: {
              formatter() {
                return this.value.toFixed(1); // Format to 1 decimal place
              }
            }
          },
          plotOptions: {
            scatter: {
              lineWidth: 2, // Connect points with lines to form a "spline"
              marker: {
                enabled: false
              }
            }
          },
          series: [
            {
              name: 'Partial dependence 3D plot',
              data: this.generateSpline3DPoints(),
              color: 'blue'
            }
          ],
        });
      });
    }
  }
};
</script>

<style scoped>
#spline3DChart {
  margin: auto;
}
</style>
