<template>
  <div class="px-24 mb-32 w-full h-full">
    <video
      ref="videoPlayer"
      controls
      autoplay
      class="w-full h-full object-cover border border-gray-300 rounded-lg"
    >
      <source :src="videoSource" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  name: 'VideoPlayer',
  props: {
    videoPath: {
      type: String,
      required: true, // Pass the relative or absolute path to the video
    },
  },
  setup(props) {
    const videoPlayer = ref(null);
    const videoSource = props.videoPath;

    onMounted(() => {
      if (videoPlayer.value) {
        videoPlayer.value.load(); // Ensure video loads properly on mount
      }
    });

    return {
      videoPlayer,
      videoSource,
    };
  },
};
</script>

<style>
/* Tailwind handles all styling, so no additional CSS is needed */
</style>
