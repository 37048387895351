<template>
  <div :key="chartKey" id="chart">
    <apexchart type="line" height="300" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

export default {
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
        },
        colors: ['grey', '#8737e1'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [5, 5],
          curve: 'straight'
        },
        title: {
          text: 'Price Trend - Coking Coal, FOB price, Australia',
          align: 'left'
        },
        legend: {
          tooltipHoverFormatter: function(val, opts) {
            return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>';
          }
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return value?.toFixed(2);
            }
          }
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            }
          ]
        },
        grid: {
          borderColor: '#f1f1f1',
          // xaxis: {
          //   lines: {
          //     show: true
          //   }
          // },
          yaxis: {
            lines: {
              show: true
            }
          }
        }
      },
      chartKey: 0
    };
  },
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    currentData: {
      type: Array,
      required: true,
    },
    lineCategory: {
      type: Array,
      required: true,
    },
    viewType: {
      type: String,
      required: true,
    }
  },
  mounted() {
    this.initializeChart();
  },
  watch: {
    viewType() {
      this.initializeChart();
    },
    currentData() {
      this.initializeChart();
    }
  },
  methods: {
    initializeChart() {
      const transformedData = this.transformDataByViewType(this.viewType);
      this.series = [
        {
          name: "Actual Price",
          data: transformedData.currentData
        }
      ];
      this.chartOptions.xaxis.categories = transformedData.categories;

      console.log(transformedData, 'transformedData')

      // Adjust x-axis labels for daily view
      if (this.viewType === 'daily') {
        this.chartOptions.xaxis.tickAmount = Math.min(transformedData.categories?.length, 20); // Show at most 10 ticks
      }

      this.chartKey += 1;
    },
    transformDataByViewType(viewType) {
      const { currentData, lineCategory } = this;

      console.log(currentData, 'currentataopopop')
      if (viewType === 'weekly') {
        return this.mapDataToInterval(currentData, lineCategory, 'isoWeek');
      } else if (viewType === 'monthly') {
        return this.mapDataToInterval(currentData, lineCategory, 'month');
      } else if (viewType === 'yearly') {
        return this.mapDataToInterval(currentData, lineCategory, 'year');
      }
      console.log(lineCategory, 'lineCategoryopopopop')
      return { currentData, categories: lineCategory };
    },
    mapDataToInterval(currentData, lineCategory, interval) {
      let mappedCurrentData = [];
      let mappedCategories = [];
      let tempDataMap = {};
      lineCategory.forEach((date, index) => {
        let period;
        if (interval === 'isoWeek') {
          period = dayjs(date).startOf('isoWeek').format('YYYY-MM-DD');
        } else {
          period = dayjs(date).startOf(interval).format('YYYY-MM-DD');
        }
        if (!tempDataMap[period]) {
          tempDataMap[period] = {
            currentData: null,
          };
        }
        tempDataMap[period].currentData = Number(currentData[index]) == 0 ? null : Number(currentData[index]);
      });

      let sortedPeriods = Object.keys(tempDataMap).sort((a, b) => dayjs(a).isBefore(dayjs(b)) ? -1 : 1);

      sortedPeriods.forEach(period => {
        mappedCategories.push(period);
        mappedCurrentData.push(tempDataMap[period].currentData);
      });
      return {
        currentData: mappedCurrentData,
        categories: mappedCategories
      };
    }
  },
};
</script>

<style scoped>
#chart {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0;
}
</style>
