<template>
    <div v-if="showPopup" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-30">
        <div class="max-w-screen-md p-4 bg-white overflow-auto max-h-screen">
            <p class="text-sm text-black" v-html="processFacts"></p>
            <button @click="$emit('close-popup')" class="mt-4 px-2 py-1 text-white bg-blue-500 hover:bg-blue-600">Close</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showPopup: false,
        fullText: ''
    },
    computed: {
        processFacts() {
            // Remove the  tags and extract the facts
            const positiveFactsStart = this.fullText.indexOf('Positive facts:') + 'Positive facts:'.length;
            const negativeFactsStart = this.fullText.indexOf('Negative facts:') + 'Negative facts:'.length;

            const positiveFactsEnd = this.fullText.indexOf('Negative facts:');
            const negativeFactsEnd = this.fullText.length;

            const positiveFacts = this.fullText.slice(positiveFactsStart+1, positiveFactsEnd).trim();
            const negativeFacts = this.fullText.slice(negativeFactsStart+1, negativeFactsEnd).trim();

            // Output the results in the required format
            return `<b>Positive facts:</b> ${positiveFacts}<br><br><b>Negative facts:</b> ${negativeFacts}`;
        }
    }
}
</script>