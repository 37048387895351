<template>
  <div class="flex flex-col overflow-y-auto px-5 pb-3 pt-10 shadow max-h-screen">
    <Navbar />
    <div class="flex flex-col py-4 px-8">
      <div class="flex items-start justify-between p-4">
        <!-- Left: Center-Left Aligned Heading -->
        <h1 class="text-3xl heading">Procurement Central</h1>

        <!-- Right: v-if Divs -->
        <div v-if="currentCommodity === 'steel'" class="w-1/3 bg-gray-200 rounded pb-2 px-4 flex items-center justify-between border border-gray-600 tile-box"
          @click="redirectToPricePilot('steel')">
          <div class="text-container">
            <p class="title font-bold">Steel</p>
            <p class="percentage-text text-right">{{ commodityTileData.diffPercentage2?.toFixed(2) }}%</p>
          </div>
          <img :src="icons['deltaDown']" alt="trend icon" class="icon">
          <div class="text-container pb-1">
            <p class="title font-bold">£{{ commodityTileData.commodityClosingValue2 }}/mt</p>
            <p class="percentage-text text-right">{{ commodityTileData.diffValue2 }}</p>
            <p class="dateVal-text text-right">{{ commodityTileData.dateVal2 }}</p>
          </div>
        </div>

        <div v-if="currentCommodity === 'brent'" class="w-1/3 bg-gray-200 rounded pb-2 px-4 flex items-center justify-between border border-gray-600 tile-box"
          @click="redirectToPricePilot('brent')">
          <div class="text-container">
            <p class="title font-bold">Brent</p>
            <p class="percentage-text text-right">{{ commodityTileData.diffPercentage4?.toFixed(2) }}%</p>
          </div>
          <img :src="icons['deltaDown']" alt="trend icon" class="icon">
          <div class="text-container">
            <p class="title font-bold">${{ commodityTileData.commodityClosingValue4 }}/bbl</p>
            <p class="percentage-text text-right">{{ commodityTileData.diffValue4 }}</p>
            <p class="dateVal-text text-right">{{ commodityTileData.dateVal4 }}</p>
          </div>
        </div>

        <div v-if="currentCommodity === 'naphtha'" class="w-1/3 bg-gray-200 rounded pb-2 px-4 flex items-center justify-between border border-gray-600 tile-box"
          @click="redirectToPricePilot('naphtha')">
          <div class="text-container">
            <p class="title font-bold">Naphtha</p>
            <p class="percentage-text text-right">{{ commodityTileData.diffPercentage3?.toFixed(2) }}%</p>
          </div>
          <img :src="icons['deltaDown']" alt="trend icon" class="icon">
          <div class="text-container">
            <p class="title font-bold">${{ commodityTileData.commodityClosingValue3 }}/bbl</p>
            <p class="percentage-text text-right">{{ commodityTileData.diffValue3 }}</p>
            <p class="dateVal-text text-right">{{ commodityTileData.dateVal3 }}</p>
          </div>
        </div>

        <div v-if="currentCommodity === 'coking-coal'" class="w-1/3 bg-gray-200 rounded pb-2 px-4 flex items-center justify-between border border-gray-600 tile-box"
          @click="redirectToPricePilot('coking-coal')">
          <div class="text-container">
            <p class="title font-bold">Coking Coal</p>
            <p class="percentage-text text-right">{{ commodityTileData.diffPercentage1?.toFixed(2) }}%</p>
          </div>
          <img :src="icons['deltaDown']" alt="trend icon" class="icon">
          <div class="text-container">
            <p class="title font-bold">${{ commodityTileData.commodityClosingValue1 }}/mt</p>
            <p class="percentage-text text-right">{{ commodityTileData.diffValue1 }}</p>
            <p class="dateVal-text text-right">{{ commodityTileData.dateVal1 }}</p>
          </div>
        </div>
      </div>

      <div class="flex w-full items-center px-8">
        <LineChart :key="`linechart`" :currentData="chartData.currentData" :viewType="selectedViewType"  :lineCategory="chartData.lineCategory" />
      </div>
      <div class="grid w-full max-w-4xl">
        <p class="pt-5 pb-2 border-b mb-2"><strong>Smart Bulletin</strong></p>
      </div>
      <div v-if="!paginatedData && !paginatedData?.length" class="loader">
        <!-- Replace this with your actual loader component or loading indicator -->
        <!-- <p>Loading...</p> -->
      </div>

      <div class="grid grid-cols-2 gap-0 w-[100%] overflow-y-auto">
        <div class="p-4 flex items-center justify-between" v-for="(row, rowIndex) in paginatedData" :key="rowIndex"
          :class="{ 'bg-white left-container': rowIndex % 2 === 0, 'bg-gray-200 right-container': rowIndex % 2 !== 0 }">
          <div class="left-side text-justify mr-4">
            <p class="title-text"><strong>{{ convertString(row[0]) }}</strong></p>
            <p class="k-text-orange"><strong>{{ row[17] }}</strong></p>
            <div class="text-container description-box">
              <p class="description-para" v-if="showFullText[rowIndex]">{{ convertString(row[14]) }}</p>
              <p v-else>{{ truncateString(convertString(row[14]), 100) }}</p>
              <button class="font-bold view-btn" @click="toggleText(rowIndex)">{{ showFullText[rowIndex] ? 'View Less' :
                'View More' }}</button>
            </div>
            <p>
              <span class="s-and-p">S&P Platts &nbsp;&nbsp;&nbsp; {{ row[5] }}</span>
            </p>
          </div>
          <div class="right-side flex flex-col items-center justify-center">
            <p class="bold price-moment justify-center">Price Moment</p>
            <img v-if="row[7] < 0" :src="icons['deltaDown']" alt="trend icon" class="icon">
            <img v-if="row[7] >= 0" :src="icons['deltaUp']" alt="trend icon" class="icon">
            <p class="sentiment-score">Sentiment score</p>
            <span v-if="row[7] < 0" class="pill-down">{{ row[7] }}</span>
            <span v-if="row[7] >= 0" class="pill-up">{{ row[7] }}</span>
          </div>
        </div>
      </div>
      <!-- Pagination Controls -->
      <div class="pagination-controls mt-4 mb-4 pb-12">
        <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive } from 'vue';
import Navbar from '@/components/Navbar.vue';
import LineChart from '@/components/LineChart.vue';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    Navbar,
    LineChart
  },
  setup() {
    const showFullText = reactive({});
    const toggleText = (index) => {
      showFullText[index] = !showFullText[index];
    };

    return {
      showFullText,
      toggleText,
    };
  },
  data() {
    return {
      selectedViewType: 'daily',
      activeTab: 'commodity-pathfinder',
      icons: {
        deltaUp: require('@/assets/img/delta-up.svg'),
        deltaDown: require('@/assets/img/delta-down.svg'),
      },
      currentPage: 1,
      pageSize: 10,
      articleData: []
    };
  },
  computed: {
    ...mapState('sheets', [
      'selectedCommodityTile',
      'articleWiseOutput',
      'priceProjections',
      'dailySummaryAndSentiment'
    ]),
    currentCommodity() {
      return this.$route.params.commodity;
    },
    commodityHomeData() {
      let articleData = this.articleWiseOutput || [];
      articleData = articleData ? articleData.slice(2) : articleData; // Remove the header row
      if (articleData.length === 0) {
        return [];
      }

      articleData = articleData.filter(art => art[17].toLowerCase() // Convert to lowercase
        .replace(/[^a-z0-9]/g, '') === this.currentCommodity.toLowerCase() // Convert to lowercase
          .replace(/[^a-z0-9]/g, ''))

      const sortedData = articleData.sort((a, b) => new Date(b[4]) - new Date(a[4]));
      const suffledArr = this.shuffleAndGroup(sortedData);
      return suffledArr;
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.commodityHomeData.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.commodityHomeData.length / this.pageSize);
    },
    commodityTileData() {
      let dailySummary = this.dailySummaryAndSentiment || [];
      dailySummary = dailySummary ? dailySummary.slice(1) : dailySummary; // Remove the header row

      if (dailySummary.length === 0) {
        return [];
      }

      const sortedData = dailySummary.sort((a, b) => new Date(b[0]) - new Date(a[0]));

      let commoditiesArr = sortedData.map(subarray => subarray[7]);
      commoditiesArr = [...new Set(commoditiesArr)];
      console.log(commoditiesArr, 'commoditiesArr')

      // Coking Coal
      const commodity1 = sortedData.filter((subarray) => subarray[7] === commoditiesArr[0])

      console.log(commodity1, 'commodity1')

      const value1 = parseFloat(commodity1[0][3]);
      const value2 = parseFloat(commodity1[1][3]);

      let diffPercentage1 = 0;
      let diffValue1 = 0;
      if (!isNaN(value1) && !isNaN(value2)) {
        diffValue1 = (value1 - value2).toFixed(2);
        diffPercentage1 = 100 * Math.abs((value1 - value2) / ((value1 + value2) / 2)).toFixed(2);
      }
      const dateVal1 = sortedData[0][0];
      const commodityClosingValue1 = sortedData[0][3]
      ///////////////////
      // Steel
      const commodity2 = sortedData.filter((subarray) => subarray[7] === commoditiesArr[1])
      const commodity2Value1 = parseFloat(commodity2[0][3]);
      const commodity2Value2 = parseFloat(commodity2[1][3]);
      console.log(commodity2, 'commodity1')

      let diffPercentage2 = 0;
      let diffValue2 = 0;
      if (!isNaN(commodity2Value1) && !isNaN(commodity2Value2)) {
        diffValue2 = (commodity2Value1 - commodity2Value2).toFixed(2);
        diffPercentage2 = 100 * Math.abs((commodity2Value1 - commodity2Value2) / ((commodity2Value1 + commodity2Value2) / 2)).toFixed(2);
      }
      const dateVal2 = commodity2[0][0];
      const commodityClosingValue2 = commodity2[0][3]

      ///////////////////
      // Naphtha
      const commodity3 = sortedData.filter((subarray) => subarray[7] === commoditiesArr[2])
      const commodity3Value1 = parseFloat(commodity3[0][3]);
      const commodity3Value2 = parseFloat(commodity3[1][3]);
      console.log(commodity3, 'commodity1')

      let diffPercentage3 = 0;
      let diffValue3 = 0;
      if (!isNaN(commodity3Value1) && !isNaN(commodity3Value2)) {
        diffValue3 = (commodity3Value1 - commodity3Value2).toFixed(2);
        diffPercentage3 = 100 * Math.abs((commodity3Value1 - commodity3Value2) / ((commodity3Value1 + commodity3Value2) / 2)).toFixed(2);
      }
      const dateVal3 = commodity3[0][0];
      const commodityClosingValue3 = commodity3[0][3]

      ///////////////////
      // Brent
      const commodity4 = sortedData.filter((subarray) => subarray[7] === commoditiesArr[3])
      const commodity4Value1 = parseFloat(commodity4[0][3]);
      const commodity4Value2 = parseFloat(commodity4[1][3]);
      console.log(commodity4, 'commodity1')

      let diffPercentage4 = 0;
      let diffValue4 = 0;
      if (!isNaN(commodity4Value1) && !isNaN(commodity4Value2)) {
        diffValue4 = (commodity4Value1 - commodity4Value2).toFixed(2);
        diffPercentage4 = 100 * Math.abs((commodity4Value1 - commodity4Value2) / ((commodity4Value1 + commodity4Value2) / 2)).toFixed(2);
      }
      const dateVal4 = commodity4[0][0];
      const commodityClosingValue4 = Number(commodity4[0][3])

      console.log(commodityClosingValue4, diffPercentage4, diffValue4, dateVal4, 'commodity1 commodityClosingValue4, diffPercentage4, diffValue4, dateVal4')

      return { commodityClosingValue1, diffPercentage1, diffValue1, dateVal1, commodityClosingValue2, diffPercentage2, diffValue2, dateVal2, commodityClosingValue3, diffPercentage3, diffValue3, dateVal3, commodityClosingValue4, diffPercentage4, diffValue4, dateVal4 };
    },
    chartData() {
      let articleData = this.priceProjections?.slice(1) || [];
      articleData = articleData.slice(1); // Remove the header row

      const selectedCommodity = this.$route.params.commodity?.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());

      console.log(articleData, 'articleData')
      articleData = articleData.filter(data => {
        return data[1] === selectedCommodity;
      });

      const seriesData = [];
      const currentData = [];
      const lineCategory = [];

      for (let index = 0; index < articleData.length; index++) {
        console.log(articleData[index][2], '[][][][][]')
        if (articleData[index][2]) {
          seriesData.push({
            x: articleData[index][0],
            y: [
              articleData[index][2], // Actual Prices (Close)
            ],
          });
          
          currentData.push(articleData[index][2]);
          lineCategory.push(articleData[index][0]);
        } 
      }

      console.log(JSON.stringify( {
        series: [{ data: seriesData }],
        currentData,
        lineCategory
      }), 'seriesData1414')

      return {
        series: [{ data: seriesData }],
        currentData,
        lineCategory
      };
    },
  },
  methods: {
    ...mapActions('sheets', [
      'fetchAndStoreDailySummaryAndSentimentOutputData',
      'setSelectedCommodityTile'
    ]),
    convertString(input) {
      console.log(input, 'jkjkjkkjkjk')
      input = input.replace(/’/g, "'");
      input = input.replace(/‘|’/g, "'");
      input = input.replace(/“|”/g, '"');
      input = input.replace(/“|”/g, '—');

      return input;
    },
    redirectToPricePilot(commodity) {
      // this.setSelectedCommodityTile(commodity);
      this.$router.push(`/commodity-pathfinder/buy/${commodity}/price-plot`);
    },
    truncateString(str, maxLength = 50) {
      if (str.length <= maxLength) {
        return str;
      } else {
        return str.substring(0, maxLength) + '...';
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    shuffleAndGroup(array) {
      const shuffledArray = array.slice().sort(() => Math.random() - 0.5); // Shuffle the array

      // Grouping by type
      const groupedArray = shuffledArray.reduce((acc, curr) => {
        const type = curr[curr.length - 3]; // Assuming the type is always the last element

        if (!acc[type]) {
          acc[type] = [];
        }

        acc[type].push(curr);
        return acc;
      }, {});

      // Interleaving groups
      const steelGroup = groupedArray['Steel'] || [];
      const brentGroup = groupedArray['Brent'] || [];
      const naphthaGroup = groupedArray['Naphtha'] || [];
      const cokingCoalGroup = groupedArray['Coking Coal'] || [];

      const maxGroupLength = Math.max(steelGroup.length, brentGroup.length, naphthaGroup.length, cokingCoalGroup.length);
      const result = [];

      for (let i = 0; i < maxGroupLength; i++) {
        if (i < steelGroup.length) result.push(steelGroup[i]);
        if (i < brentGroup.length) result.push(brentGroup[i]);
        if (i < naphthaGroup.length) result.push(naphthaGroup[i]);
        if (i < cokingCoalGroup.length) result.push(cokingCoalGroup[i]);
      }

      return result;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
  mounted() {
    this.fetchAndStoreDailySummaryAndSentimentOutputData();
  },
};
</script>
<style scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  /* Adjust height as needed */
}

.loader::after {
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: #555;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.tile-box:hover {
  background-color: #f0f0f0;
  /* Change background color on hover */
  cursor: pointer;
  /* Change cursor to pointer on hover */
}

.tile-box:hover .icon {
  filter: brightness(80%);
  /* Example: Apply a filter effect to the icon on hover */
}

.aggregated-sentiment {
  background-color: #243058;
  color: white;
}

.heading {
  color: #243058;
}

.bg-gray-200 {
  background-color: #E5E7EB;
}

.title,
.value,
.percentage-text {
  margin: 2px 0;
}

.percentage-text {
  font-size: 0.8em;
}

.dateVal-text {
  font-size: 0.5em;
  font-weight: 400;
  color: gray;
}

.icon {
  width: 24px;
  height: 24px;
  margin: 0 0.5rem;
}

.right-side {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.k-text-orange {
  color: #F37022;
  font-weight: 400;
}

.s-and-p {
  color: gray;
  font-size: 0.6rem;
  margin: 0px;
}

.icon {
  width: 24px;
  height: 24px;
  margin: 0.5rem 0;
  align-items: center;
}

.title-text {
  font-size: 0.875em;
  /* 14px */
}

.normal-text {
  font-size: 0.75em;
}

.price-moment {
  font-size: 0.75em;
  color: #F37022;
  font-weight: bold;
}

.sentiment-score {
  font-size: 0.60em;
  color: #000000;
  font-weight: 400;
}

.pill-down {
  @apply bg-orange-500 text-white px-7 rounded-full text-sm font-semibold;
  background-color: #FF8080;
}

.pill-up {
  @apply text-white px-7 rounded-full text-sm font-semibold;
  background-color: #9CC6A0;
}

.right-container {
  background-color: #dbdbdb;
}

.left-container {
  background-color: #f3f3f3;
}

/* Ensure that the scroll works properly */
.overflow-y-auto {
  overflow-y: auto;
}

.max-h-screen {
  max-height: 100vh;
}

.description-box .description-para {
  max-height: 130px;
  overflow-y: scroll;
  padding-right: 1rem;
}

.description-box p {
  max-height: 130px;
  line-height: normal;
  font-size: 14px;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination-controls button {
  padding: 2px 10px;
  background-color: #F37022;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination-controls button:disabled {
  background-color: #FFDBC6;
  cursor: not-allowed;
}

.view-btn {
  font-size: 12px;
}

.left-side,
.right-side {
  font-size: 14px;
}
</style>
