<template>
  <div class="h-screen overflow-y-auto bg-gray-50 mb-16">
    <div class="flex flex-col md:flex-row items-center px-8 md:px-16 py-12 bg-white">
      <div class="w-full md:w-2/5 pr-8">
        <h1 class="text-4xl font-bold mb-4 purple-text">Commodity Pathfinder</h1>
        <h2 class="text-xl font-semibold text-gray-600 mb-6">
          The one-stop shop for all things commodity
        </h2>
        <p class="text-gray-700 mb-4">
          Welcome to Commodity Pathfinder! <br> Kearney's AI & Generative AI-driven tool to help buyers & sellers make informed decisions on commodities.
        </p>
        <ul class="list-disc pl-5 text-gray-700">
          <li><strong>Market view:</strong> Develop proprietary market view on commodity of your choice</li>
          <li><strong>Event pulse:</strong> Comprehend impact of key geo-political & macro events</li>
          <li><strong>Sentiment check:</strong> Understand & synthesize customer sentiments</li>
          <li><strong>Churn watch:</strong> Anticipate potential customer churn risks</li>
          <li><strong>Pricing engine:</strong> Develop bespoke customer-specific pricing to maximize value</li>
        </ul>
      </div>
      <div class="w-full md:w-3/5">
        <img
          :src="icons.commodityBackground"
          alt="Commodity Pathfinder"
          class="w-full"
        />
      </div>
    </div>
    <div class="flex flex-col md:flex-row justify-center items-center gap-6 px-8 md:px-16 py-12 bg-gray-100 pb-32">
      <div class="bg-white rounded-sm shadow-lg p-6 w-full md:w-1/2">
        <h3 class="text-2xl font-bold mb-4 purple-text">Buy Side Tool Suite</h3>
        <p class="text-gray-700 mb-6 text-sm">
          Empower your procurement strategy with AI-driven insights & analytics. Unlock opportunities to optimize costs, anticipate market trends, and make data-informed decisions that drive competitive advantage.
        </p>
        <router-link
          :to="{ name: 'commodityLandingBuyCommodity', params: { commodity: 'coking-coal' } }"
          class="kearney-purple-button px-6 py-2 rounded-sm hover:kearney-purple-button text-sm"
      >
        Explore
      </router-link>
      </div>
      <div class="bg-white rounded-sm shadow-lg p-6 w-full md:w-1/2">
        <h3 class="text-2xl font-bold purple-text mb-4">Sell Side Tool Suite</h3>
        <p class="text-gray-700 mb-6 text-sm">
          Strengthen your market position with advanced tools and predictive analytics. Gain a clear understanding of demand dynamics, set strategic pricing, and maximize sales potential for sustainable growth.
        </p>
        <router-link
          :to="{ name: 'commodityLandingSellCommodity', params: { commodity: 'sensa' } }"
          class="kearney-purple-button px-6 py-2 rounded-sm hover:kearney-purple-button text-sm"
        >
        Explore
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icons: {
        commodityBackground: require('@/assets/img/commodity-home.png'), // Your image path
      },
    }
  },
  methods: {
    navigateTo(route) {
      this.$router.push({ name: route });
    },
  },
};
</script>

<style scoped>
/* Add any additional custom styles here if needed */
</style>
