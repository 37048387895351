<template>
  <div class="w-48 bg-white text-black pb-16 h-36 overflow-y-auto ml-2 mt-8 rounded-sm custom-scrollbar">
    <ul class="space-y-3 mt-4 mr-2">
      <li v-for="item in menuItems" :key="item.name" class="relative">
        <router-link :to="item.url"
        :class="['font flex mx-8 pl-4 py-2 active-menu rounded hover:text-purple-600', { 'text-purple-600': isActive(item) }]">
        {{ item.name }}

        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuItems: [
        {
          name: 'Blast Furnace', url: '/process-parameter-optimizer/introduction',
          isOpen: true,
        },
        {
          name: 'Sinter', url: '/process-parameter-optimizer/introduction',
          isOpen: false,
        },
        {
          name: 'SMS', url: '/process-parameter-optimizer/introduction',
          isOpen: false,
        }
      ]
    };
  },
  methods: {
    isActive(item) {
      // const currentRoute = this.$route.path;
      // return currentRoute.startsWith(item.url);
      return item.name == 'Blast Furnace'
    }
  }
};
</script>


<style scoped>
/* Custom Scrollbar Styling */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  /* Scrollbar thumb color */
  border-radius: 4px;
  /* Round edges */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
  /* Darker grey on hover */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Scrollbar track color */
  border-radius: 4px;
  /* Round edges */
}

.custom-scrollbar::-webkit-scrollbar-track:hover {
  background-color: #e6e6e6;
  /* Slightly darker grey on track hover */
}

/* Sticky Header Styling */
.sticky {
  position: sticky;
  top: 0;
  /* Stick to the top of the container */
  background-color: white;
  /* Ensure background covers content behind it */
  z-index: 10;
  /* Ensure it stays above other elements */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  /* Optional: Shadow for better separation */
}
.active-menu:hover {
  color: #232B60;
  background-color: #fff;
}
.active-menu1 {
  color: #232B60;
  background-color: #fff;
}
</style>