<template>
  <div class="flex h-screen">
    <TreeMenu></TreeMenu>
    <div class="flex-1 p-4 body-bg overflow-y-auto">
      <div class="grid grid-rows-[auto,auto,1fr] p-4 min-h-screen">
        <div class="h-16 mb-4">
          <h1 class="font-bold text-[2rem] mt-2">
            Optimization Wizard
          </h1>
        </div>
        <div class="flex items-center justify-between w-full mb-4">
          <div class="flex items-center space-x-6">
            <div class="flex items-center">
              <span class="text-xl font-medium text-gray-800">Output</span>
            </div>
            <div class="flex items-center">
              <span class="w-3 h-3 rounded-full bg-ppo-custom-blue mr-2"></span>
              <span class="text-sm font-medium text-gray-800">Current</span>
            </div>
            <div class="flex items-center">
              <span class="w-3 h-3 rounded-full bg-ppo-custom-green mr-2"></span>
              <span class="text-sm font-medium text-gray-800">Optimized</span>
            </div>
          </div>
          <div>
            <button
              v-if="!loading"
              @click="submitEstimate"
              class="px-12 py-2 blue-custom rounded-sm text-white text-sm font-medium hover:blue-custom-400 transition-all"
            >
              Estimate
            </button>
            <div v-if="loading" class="flex justify-center items-center mt-4">
              <div class="spinner-border animate-spin border-4 border-blue-600 border-t-transparent rounded-full w-10 h-10"></div>
            </div>
          </div>
        </div>

        <div class="flex space-x-4">
          <div class="bg-white rounded-md shadow px-3 pt-3 pb-1 max-w-xs w-1/4">
            <div class="text-xxs text-gray-500 font-semibold mb-1">
              Current BF Coke Rate
            </div>
            <div class="flex justify-between items-center mb-1">
              <div class="text-[2rem] font-bold text-ppo-custom-blue">{{ responseData?.Predicted_Yield?.toFixed(1) || 0 }}</div>
            </div>
          </div>

          <div class="bg-white rounded-md shadow px-3 pt-3 pb-1 max-w-xs w-1/4">
            <div class="text-xxs text-gray-500 font-semibold mb-1">
              Optimized BF Coke Rate
            </div>
            <div class="flex justify-between items-center mb-1">
              <div class="text-[2rem] font-bold text-ppo-custom-green">{{ responseData?.Optimized_Yield?.toFixed(1) || 0 }}</div>
            </div>
            <div class="mt-4 text-xs" v-if="!loading && percentageChange !== null">{{ percentageChange?.toFixed(1) }}% optimized</div>
            <div class="mt-2 text-xs" v-if="!loading && !percentageChange">--</div>
          </div>
        </div>

        <div class="mt-8 items-center justify-between pl-1 text-sm">
          <div class="flex">
            <button v-for="(tab, index) in tabs" :key="tab.name" @click="selectedTab = index" :class="[
              'rounded tab-button px-4 py-2',
              selectedTab === index
                ? 'blue-custom text-white'
                : 'bg-white text-black hover:blue-custom'
            ]" class="text-sm" :style="{ marginLeft: '-5px' }">
              {{ tab.name }}
            </button>
          </div>
        </div>
        <div class="flex gap-4 pt-4 mb-8 text-sm flex-wrap">
          <div class="flex-1" v-if="selectedTab === 0">
            <div class="flex items-center space-x-6">
              <div class="flex items-center">
                <span class="text-xl font-medium text-gray-800">Controllable Parameters</span>
              </div>
              <div class="flex items-center">
                <span class="w-3 h-3 rounded-full bg-ppo-custom-blue mr-2"></span>
                <span class="text-sm font-medium text-gray-800">Current</span>
              </div>
              <div class="flex items-center">
                <span class="w-3 h-3 rounded-full bg-ppo-custom-green mr-2"></span>
                <span class="text-sm font-medium text-gray-800">Optimized/Suggested</span>
              </div>
            </div>

            <div class="flex mb-4">
              <p class="text-xs text-gray-400 font-medium">Kindly fill in the current values. Click “Estimate” button to generate optimized values.</p>
            </div>
            <div class="grid grid-cols-4 gap-4">
              <div
                class="bg-white rounded-md shadow p-4 max-w-xs w-full mb-6"
                v-for="(param, key) in dataConfigurableParameters.controllable"
                :key="key"
              >
                <div class="text-[.80rem] text-gray-500 font-semibold mb-4">
                  {{ param.unit ? `${param.name} (${param.unit})` : param.name }}
                </div>
                <div class="flex flex-col mb-1">
                  <div class="bg-ppo-blue-opacity w-full py-1 px-6 mb-2">
                    <input
                      type="number"
                      v-model="param.value"
                      :min="param.min"
                      :max="param.max"
                      class="text-[1.5rem] text-center rounded-sm font-bold text-ppo-custom-blue w-3/4 focus:outline-none ml-4"
                    />
                  </div>
                  <div class="text-[1.5rem] text-center rounded-sm font-bold text-ppo-custom-green bg-ppo-green-opacity py-2 px-6 w-full">
                    {{ responseData?.Optimized_Params && responseData?.Optimized_Params[key] ? responseData?.Optimized_Params[key].toFixed(1) : '-' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-1" v-if="selectedTab === 1">
            <div class="flex items-center space-x-6  mb-4">
              <div class="flex items-center">
                <span class="text-xl font-medium text-gray-800">Unontrollable Parameters</span>
              </div>
              <div class="flex items-center">
                <span class="w-3 h-3 rounded-full bg-ppo-custom-blue mr-2"></span>
                <span class="text-sm font-medium text-gray-800">Current</span>
              </div>
            </div>
            <div class="grid grid-cols-4 gap-4">
              <div class="bg-white rounded-md shadow p-4 max-w-xs w-full mb-6" v-for="(param, index) in dataConfigurableParameters.uncontrollable">
                <div class="text-[.80rem] text-gray-500 font-semibold h-12">
                  {{ param.unit ? `${param.name} (${param.unit})` : param.name }}
                </div>
                <div class="flex flex-col mb-1">
                  <div class="bg-ppo-blue-opacity w-full py-1 px-6 mb-2">
                    <input
                      type="number"
                      v-model="param.value"
                      :min="param.min"
                      :max="param.max"
                      class="text-[1.5rem] text-center rounded-sm font-bold text-ppo-custom-blue w-3/4 focus:outline-none ml-4"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex" v-if="responseData?.Predicted_Yield">
          <cokeLanding :optimizedData="responseData?.Optimized_Params" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import TreeMenu from './TreeMenu.vue';
import LineChart from './LineChart.vue'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import dataConfigurableParameters from './dataConfigurableParameters';
import cokeLanding from './cokeLanding.vue';

export default {
  name: "ProcessParameterPredictor",
  components: {
    TreeMenu,
    LineChart,
    Datepicker,
    cokeLanding
  },
  data() {
    return {
      apiBaseUrl: process.env.VUE_APP_COMBUST_API_BASE_URL,
      selectedTab: 0, // Track the currently selected tab
      loading: false,
      tabs: [
        { name: 'Controllable Parameters' },
        { name: 'Uncontrollable Parameters' }
      ],
      dataConfigurableParameters,
      responseData: null
    }
  },
  methods: {
    ...mapActions('sheets', ['setCurrentBfCokeRate']),
    async submitEstimate() {
      const allParams = {};

      Object.keys(this.dataConfigurableParameters.controllable).forEach((param) => {
        allParams[param] = this.dataConfigurableParameters.controllable[param].value;
      });

      Object.keys(this.dataConfigurableParameters.uncontrollable).forEach((param) => {
        allParams[param] = this.dataConfigurableParameters.uncontrollable[param].value;
      });

      const estimatePayload = {
        allParams,
      };

      this.loading = true;
      try {
        const response = await axios.post(this.apiBaseUrl+'optimize-setpoints', estimatePayload);
        this.responseData = response?.data?.result || null;
        console.log('API Response:', response.data);
        this.setCurrentBfCokeRate(this.responseData?.Predicted_Yield || 0);
      } catch (error) {
        console.error('API Error:', error.response || error);
        alert('Failed to submit estimate.');
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    percentageChange() {
      if (this.responseData) {
        const predictedYield = this.responseData?.Predicted_Yield;
        const optimizedYield = this.responseData?.Optimized_Yield;

        if (predictedYield != null && optimizedYield != null && optimizedYield !== 0) {
          const change = ((predictedYield - optimizedYield) / optimizedYield) * 100;
          return change;  // Return the percentage change
        }
      }
      return null;  // Return null if data is missing or optimizedYield is zero
    }
  },
};
</script>

<style>
.blue-custom {
  background: #232B60;
}

.body-bg {
  background: #F5F6FA;
  border-left: #D9D9D9 1px solid;
}

.tab-button {
  border: #232B60 1px solid;
  font-size: 1rem;
}

.dp__input_icon_pad {
  height: 33px;
}
</style>