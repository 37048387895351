<template>
  <div class="flex h-screen">
    <TreeMenu :treeMenuFor="'SELL'"></TreeMenu>
    <div class="flex-1 p-4 mb-16 body-bg overflow-y-auto">
      <div class="space-y-4 p-4">
        <div class="flex justify-between items-center mt-4 mb-12">
          <h2 class="text-2xl font-bold mr-4">
            {{ selectedCommodity === 'Sensa' ? 'SenSA-2.0 (Sentiment Scoring Algorithm)' : selectedCommodity ===
              'Customer Churn' ? 'Customer Churn Watch Parameters' : 'Raffia - Commodity Fingerprinter' }}
          </h2>
          <button
            class="bg-purple-500 text-white text-sm font-semibold px-6 py-2 rounded-sm shadow-lg hover:bg-purple-600 transition"
            @click="navigateTo('home')">
            Explore
          </button>
        </div>
        <div v-if="selectedCommodity === 'Sensa'"
          class="relative flex flex-col md:flex-row items-center bg-white shadow-lg rounded-sm overflow-hidden">
          <div class="w-full md:w-[40%] pl-8 font-semibold">
            Kearney’s rapid, tried and tested approach leveraging Generative AI to diagnose textual information and
            identify stakeholder pain points
          </div>
          <div class="w-full md:w-[60%]">
            <img :src="icons.sensa" alt="Row 1 Image" class="w-full h-full object-cover" />
          </div>
        </div>
        <div v-if="selectedCommodity === 'Customer Churn'" class="flex flex-col items-center p-6 bg-gray-50">
          <!-- Header Section -->
          <div class="w-full max-w-4xl bg-gray-100 text-center rounded-md p-12">
            <div class="grid grid-cols-3 gap-8">
              <div class="text-left">
                <h3 class="text-purple-600 font-bold text-lg">Recency</h3>
                <p class="text-gray-700">Measures the number of days since the last purchase to evaluate how recently a customer engaged.</p>
              </div>
              <div class="text-left">
                <h3 class="text-purple-600 font-bold text-lg">Consistency</h3>
                <p class="text-gray-700">Assesses how often a customer meets monthly purchase targets to determine regular buying behaviour.</p>
              </div>
              <div class="text-left">
                <h3 class="text-purple-600 font-bold text-lg">Monetary</h3>
                <p class="text-gray-700">Calculates total profitability from a customer by summing all purchases and subtracting associated costs.</p>
              </div>
            </div>
          </div>

          <!-- Icon Section -->
          <div class="w-full max-w-4xl mt-16">
            <div class="grid grid-cols-4 gap-6 text-center">
              <!-- Icon Placeholder 1 -->
              <div>
                <div class="w-16 h-16 rounded-full mx-auto flex items-center justify-center">
                  <!-- <span class="text-gray-500 text-xl">📈</span> -->
                  <img :src="iconsCustomerChurn.cc1" class="h12" />
                </div>
                <p class="mt-4 text-purple-600 font-semibold">
                  Potential churn list identified 1-month in advance
                </p>
              </div>

              <!-- Icon Placeholder 2 -->
              <div>
                <div class="w-16 h-16 rounded-full mx-auto flex items-center justify-center">
                  <img :src="iconsCustomerChurn.cc2" class="h12" />
                </div>
                <p class="mt-4 text-purple-600 font-semibold">
                  One-stop solution - customer insights
                </p>
              </div>

              <!-- Icon Placeholder 3 -->
              <div>
                <div class="w-16 h-16 rounded-full mx-auto flex items-center justify-center">
                  <img :src="iconsCustomerChurn.cc3" class="h12" />
                </div>
                <p class="mt-4 text-purple-600 font-semibold">
                  Automated mail warning system to stakeholders
                </p>
              </div>

              <!-- Icon Placeholder 4 -->
              <div>
                <div class="w-16 h-16 rounded-full mx-auto flex items-center justify-center">
                  <img :src="iconsCustomerChurn.cc4" class="h12" />
                </div>
                <p class="mt-4 text-purple-600 font-semibold">
                  Automated profitability calculation
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedCommodity === 'Raffia'" class="flex flex-col items-center p-6 bg-gray-50">
          <!-- Header Section -->
          <div class="w-full max-w-4xl bg-gray-100 text-center rounded-md p-12">
            <div class="grid grid-cols-3 gap-8">
              <div class="text-left">
                <h3 class="text-purple-600 font-bold text-lg">Future Price Predictions</h3>
                <p class="text-gray-700">The tool forecasts Raffia (petrochemical commodity) prices at various future points, aiding strategic planning and decision-making</p>
              </div>
              <div class="text-left">
                <h3 class="text-purple-600 font-bold text-lg">Comprehensive Analysis</h3>
                <p class="text-gray-700">It considers supply and demand dynamics, inventory levels, and competitor intelligence for holistic price prediction</p>
              </div>
              <div class="text-left">
                <h3 class="text-purple-600 font-bold text-lg">Exclusive Market Insight</h3>
                <p class="text-gray-700">Integrating diverse data sources, the tool offers insights into market trends and potential price movements</p>
              </div>
            </div>
          </div>

          <!-- Icon Section -->
          <div class="w-full max-w-4xl mt-16">
            <div class="grid grid-cols-3 gap-6 text-center">
              <!-- Icon Placeholder 2 -->
              <div>
                <div class="w-16 h-16 rounded-full mx-auto flex items-center justify-center">
                  <img :src="iconsRaffia.r1" class="h12" />
                </div>
                <p class="mt-4 text-purple-600 font-semibold">
                  Tailored recommendations to maximize profitability
                </p>
              </div>

              <!-- Icon Placeholder 3 -->
              <div>
                <div class="w-16 h-16 rounded-full mx-auto flex items-center justify-center">
                  <img :src="iconsRaffia.r2" class="h12" />
                </div>
                <p class="mt-4 text-purple-600 font-semibold">
                  Future price predictions for decision-making
                </p>
              </div>

              <!-- Icon Placeholder 4 -->
              <div>
                <div class="w-16 h-16 rounded-full mx-auto flex items-center justify-center">
                  <img :src="iconsRaffia.r3" class="h12" />
                </div>
                <p class="mt-4 text-purple-600 font-semibold">
                  Insights on supply, demand, and competitors
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TreeMenu from '../components/CommodityPathfinder/TreeMenu.vue';
import { mapActions } from 'vuex';
export default {
  components: {
    TreeMenu
  },
  data() {
    return {
      icons: {
        buy1: require('@/assets/img/buy-1.png'), // Your image path
        buy2: require('@/assets/img/buy-2.png'), // Your image path
        sensa: require('@/assets/img/sensa.png'), // Your image path
      },
      iconsCustomerChurn: {
        cc1: require('@/assets/img/customer-feedback.svg'), // Your image path
        cc2: require('@/assets/img/customer-insights.svg'), // Your image path
        cc3: require('@/assets/img/email.svg'), // Your image path
        cc4: require('@/assets/img/profit.svg'), // Your image path
      },
      iconsRaffia: {
        r1: require('@/assets/img/suggestion.svg'), // Your image path
        r2: require('@/assets/img/forecast.svg'), // Your image path
        r3: require('@/assets/img/supply-chain-management.svg'), // Your image path
      },
    }
  },
  methods: {
    ...mapActions('sheets', [
      'fetchAndStoreArticleWiseOutputData',
      'fetchAndStorePriceProjectionsData'
    ]),
    navigateTo(page) {
      const commodity = this.$route.params.commodity;
      if (commodity === 'sensa') {
        this.$router.push(`/sensa-analysis`);
      } else if (commodity === 'customer-churn') {
        this.$router.push(`/customer-churn`);
      } else {
        this.$router.push(`/raffia`);
      }
    },
    updateCommodity() {
      this.selectedCommodity = this.$route.params.commodity?.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
    }
  },
  mounted() {
    this.fetchAndStorePriceProjectionsData()
    this.fetchAndStoreArticleWiseOutputData();
  },
  watch: {
    '$route'(to, from) {
      this.updateCommodity();
    }
  },
  created() {
    this.updateCommodity();
  }
};
</script>

<style scoped>
.body-bg {
  border: none !important;
}

.box-badge {
  top: 0;
  align-items: first baseline;
  text-align: center;
  left: 80%;
}
</style>
