<template>
  <div ref="chartContainer" class="chart-main">
    <highcharts ref="highchartsChart" :options="chartOptions"></highcharts>
    <button @click="toggleFullScreen"><img :src="icons['fullScreen']" alt="Image" class="caret h-4" /></button>
  </div>
</template>

<script>
import HighchartsVue from "highcharts-vue";
import minimalDataConfigurableParameters from './minimalDataConfigurableParameters';

export default {
  components: {
    highcharts: HighchartsVue.component
  },
  props: {
    categories: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    seriesData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      icons: {
        fullScreen: require('@/assets/img/fullscreen.svg'),
      },
      isFullScreen: false, // Track full-screen mode
      dataConfigurableParameters: minimalDataConfigurableParameters,
      chartOptions: {} // Initialize an empty object for chartOptions
    };
  },
  computed: {
    parameterInfo() {
      return (
        this.dataConfigurableParameters[this.title] || {
          name: '',
          unit: '',
          tickInterval: 100
        }
      );
    }
  },
  mounted() {
    this.updateChartOptions(); // Initialize chart options on mount
  },
  methods: {
    updateChartOptions() {
      // Define default or full-screen height based on isFullScreen flag
      const height = this.isFullScreen ? window.innerHeight : 180;

      // Update chartOptions with new height, forcing Vue to re-render
      this.chartOptions = {
        chart: {
          type: 'line',
        },
        title: {
          text: this.parameterInfo.unit ? `${this.parameterInfo.name} (${this.parameterInfo.unit})` : this.parameterInfo.name,
          align: 'left',
          style: {
            color: '#202223',
            fontSize: '14px'
          }
        },
        xAxis: {
          categories: this.categories,
          title: {
            text: null
          },
          labels: {
            formatter: function () {
              const date = new Date(this.value);
              const day = String(date.getDate()).padStart(2, '0');
              
              const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
              
              const month = monthNames[date.getMonth()];
              
              return `${day}/${month}`;
            }
          }
        },
        yAxis: {
          title: {
            text: null
          },
          tickAmount: 4,
        },
        series: [
          {
            name: this.parameterInfo.name,
            data: this.seriesData,
            color: '#234080',
            lineWidth: 2
          }
        ],
        tooltip: {
          valueSuffix: this.parameterInfo.unit
        },
        plotOptions: {
          line: {
            marker: {
              enabled: true,
              radius: 3
            }
          }
        },
        legend: {
          enabled: false
        }
      };
    },
    toggleFullScreen() {
      const chartContainer = this.$refs.chartContainer;
      if (!document.fullscreenElement) {
        chartContainer.requestFullscreen()
          .then(() => {
            this.isFullScreen = true;  // Set full-screen mode flag
            this.updateChartOptions(); // Update chart options with full-screen height
          })
          .catch((err) => {
            console.error(`Error attempting to enter fullscreen mode: ${err.message}`);
          });
      } else {
        document.exitFullscreen()
          .then(() => {
            this.isFullScreen = false; // Reset full-screen mode flag
            this.updateChartOptions(); // Update chart options with default height
          });
      }
    }
  }
};
</script>


<style scoped>
button {
  margin-top: 10px;
  padding: 8px 16px;
  /* background-color: #234080; */
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  position: absolute;
  top: -3%;
  right: -5%;
}
.chart-main {
  position: relative;
}

button:hover {
  color: #1a3060;
}

.fullscreen {
  padding-top: 25vh;
  width: 100vw;
  height: 50vh;
  padding-bottom: 25vh;
  overflow: hidden;
}
</style>
