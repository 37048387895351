<template>
  <div v-if="analysedSentimentsResult?.is_bulk">
    <div class="flex space-x-4 mx-10">
      <div class="flex-1 p-4">
        <h3 class="box-title">Aspect Score Snapshot</h3>
        <div class="grid grid-cols-4 gap-1 w-full max-w-screen-lg mt-2">
          <div class="py-1 px-4 border border-gray-300 rounded-md"
            v-for="(snapshot, index) in analysedSentimentsResult?.aspect_score_snapshot">
            <span class="flex flex-col items-start">
              <div class="text-xl font-bold">
                <span class="text-1xl">{{ snapshot.value_nm?.toFixed(2) }}</span><small class="text-sm">/{{
                  snapshot.value_dnm }}</small>
              </div>
              <small class="quality-score">{{ snapshot.title }}</small>
            </span>
          </div>
        </div>
      </div>
      <div class="flex-1 p-4">
        <h3 class="box-title">Sentiment Snapshot</h3>
        <div class="grid grid-cols-4 gap-1 w-full max-w-screen-lg mt-2 sentiment-snapshot">
          <div class="py-1 px-4 border border-gray-300 rounded-md"
            v-for="(snapshot, index) in analysedSentimentsResult?.sentiment_snapshot" :key="index"
            :style="{ backgroundColor: snapshot.color }">
            <span class="flex flex-col items-start">
              <div class="text-xl font-bold">
                <span class="text-1xl">{{ Math.round(snapshot.value_nm) }}</span><small class="text-sm"
                  v-if="snapshot.value_dnm > 0">/{{ snapshot.value_dnm }}</small>
              </div>
              <small class="snapshot-title">{{ snapshot.title }}</small>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex spec-x-4 mx-10">
      <div class="flex-1">
        <h3 class="box-title pb-2 ml-4">Aspect by Sentiment</h3>
        <div class="px-2 pb-4 w-full max-w-screen-lg">
          <highcharts :options="chartOptions" />
        </div>
      </div>
      <div class="flex-1">
        <h3 class="box-title pb-2 ml-6">Aspect Co-occurance</h3>
        <div class="pb-4 w-full max-w-screen-lg">
          <div ref="chart" class="chart-container"></div>

        </div>
      </div>
    </div>
    <div class="flex spec-x-4 mx-10">
      <div class="flex-1 px-4">
        <div class="p-2 key-pleasure-point">
          <h3>Key Pleasure Points</h3>
        </div>
        <div class="px-2 pb-4 w-full max-w-screen-lg key-points key-pleasure-point-description">
          {{ analysedSentimentsResult?.key_pleasure_points }}
        </div>
      </div>
      <div class="flex-1 px-4">
        <div class="p-2 key-pain-point">
          <h3>Key Pain Points</h3>
        </div>
        <div class="px-2 pb-4 w-full max-w-screen-lg key-points key-pain-point-description">
          {{ analysedSentimentsResult?.key_pain_points }}
        </div>
      </div>
    </div>
    <div class="flex spec-x-4 mx-10 mb-20">
      <div class="flex-1 px-4">
        <div class="py-1 rating-heading">
          <h3>Highest Rating Review</h3>
        </div>

        <div class="grid grid-cols-10 gap-2 w-full max-w-screen-lg rating-box">
          <div class="col-span-6 h-16 overflow-auto-y px-2 pb-4 key-points rating-description custom-scrollbar">
            {{ analysedSentimentsResult?.highest_rating_review?.description }}
          </div>
          <div class="col-span-4 h-48 px-2 pb-2 aspect-rating m-2">
            <div class="font-bold">Aspect-wise Rating</div>
            <div class="grid grid-cols-1 gap-1">
              <div v-for="(item, index) in analysedSentimentsResult?.highest_rating_review?.aspect_wise_rating?.data"
                :key="index" class="flex justify-between items-center">
                <span class="w-15 rating">{{ item.title.charAt(0).toUpperCase() + item.title.slice(1) }}</span>
                <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded">{{ item.value }}</span>
              </div>
            </div>
          </div>
          <div class="mx-4 mb-8">
            <div class="text-left my-2 font-bold">Keywords:</div>
            <div class="keywords-container">
              <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded mr-2 my-1"
                v-for="(item, index) in highestRatingKeywordsArray" :key="index">
                {{ item }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-1 px-4">
        <div class="py-1 rating-heading">
          <h3>Lowest Rating Review</h3>
        </div>

        <div class="grid grid-cols-10 gap-2 w-full max-w-screen-lg rating-box">
          <div class="col-span-6 h-16 overflow-auto-y px-2 key-points rating-description custom-scrollbar">
            {{ analysedSentimentsResult?.lowest_rating_review?.description }}
          </div>
          <div class="col-span-4 px-2 pb-2 aspect-rating m-2 h-48">
            <div class="font-bold">Aspect-wise Rating</div>
            <div class="grid grid-cols-1 gap-1">
              <div v-for="(item, index) in analysedSentimentsResult?.lowest_rating_review?.aspect_wise_rating?.data"
                :key="index" class="flex justify-between items-center">
                <span class="w-15 rating">{{ item.title.charAt(0).toUpperCase() + item.title.slice(1) }}</span>
                <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded">{{ item.value }}</span>
              </div>
            </div>
          </div>
          <div class="mx-4 mb-8">
            <div class="text-left my-2 font-bold">Keywords:</div>
            <div class="keywords-container">
              <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded mr-2 my-1"
                v-for="(item, index) in lowestRatingKeywordsArray" :key="index">
                {{ item }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="flex mx-10">
      <!-- Aspect Score Snapshot -->
      <div class="w-[60%] p-4 border-r border-gray-300">
        <h3 class="box-title">Aspect Score Snapshot</h3>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
          <div class="py-2 px-4 border border-gray-300 rounded-md"
            v-for="(snapshot, index) in analysedSentimentsResult?.aspect_score_snapshot" :key="index">
            <span class="flex flex-col items-start">
              <div class="text-xl font-bold">
                <span>{{ snapshot.value_nm?.toFixed(2) }}</span>
                <small class="text-sm">/{{ snapshot.value_dnm }}</small>
              </div>
              <small class="quality-score">{{ snapshot.title }}</small>
            </span>
          </div>
        </div>
      </div>

      <div class="w-[40%] p-4">
        <div class="mb-6">
          <h3 class="box-title">Sentiment Type</h3>
          <div class="py-2 mt-4 px-4 border border-gray-300 rounded-md text-white" :class="{
            'bg-[#77DD77]': analysedSentimentsResult?.sentiment_type === 'Positive',
            'bg-[#FF6962]': analysedSentimentsResult?.sentiment_type === 'Negative',
            'bg-[#FFB246]': analysedSentimentsResult?.sentiment_type === 'Neutral'
          }">
            <span class="flex flex-col items-start">
              <div class="text-xl font-bold">
                <span class="text-1xl">{{ analysedSentimentsResult?.sentiment_type }}</span>
              </div>
            </span>
          </div>
        </div>
        <div>
          <h3 class="box-title">Overall Sentiment Score</h3>
          <div class="py-2 mt-4 px-4 border border-gray-300 rounded-md">
            <span class="flex flex-col items-start">
              <div class="text-xl font-bold">
                <span class="text-1xl">{{ Math.round(analysedSentimentsResult?.overall_sentiment_score?.value_nm)
                  }}</span>
                <small class="text-sm" v-if="analysedSentimentsResult?.overall_sentiment_score?.value_dnm > 0">
                  /{{ analysedSentimentsResult?.overall_sentiment_score?.value_dnm }}
                </small>
              </div>
              <small class="snapshot-title">{{ analysedSentimentsResult?.overall_sentiment_score?.title }}</small>
            </span>
          </div>
        </div>
      </div>
    </div>
    
    <div class="flex w-[80%] mx-8 mt-8" v-if="analysedSentimentsResult?.summary">
      <span class="font-bold purple-text">Summary:</span><i>&nbsp;{{ analysedSentimentsResult?.summary }}</i>
    </div>

  </div>
</template>

<script>
import Highcharts from 'highcharts';
import heatmap from 'highcharts/modules/heatmap';
import { mapState } from 'vuex';

// Initialize the heatmap module
heatmap(Highcharts);

export default {
  name: 'TwoBoxes',
  mounted() {
    this.renderChart();
  },
  computed: {
    ...mapState('sheets', [
      'analysedSentimentsResult'
    ]),
    highestRatingKeywordsArray() {
      let keywords = this.analysedSentimentsResult?.highest_rating_review?.aspect_wise_rating?.keywords;
      keywords = keywords
        ? keywords.split(",").map((keyword) => keyword.trim().replace(/\s+/g, "-"))
        : [];
      return keywords
    },
    lowestRatingKeywordsArray() {
      let keywords = this.analysedSentimentsResult?.lowest_rating_review?.aspect_wise_rating?.keywords;
      keywords = keywords
        ? keywords.split(",").map((keyword) => keyword.trim().replace(/\s+/g, "-"))
        : [];

      return keywords
    },
  },
  data() {
    return {
      chartOptions: {
        series: [
          {
            name: "Positive Sentiment",
            data: [], // Populated dynamically
          },
          {
            name: "Negative Sentiment",
            data: [], // Populated dynamically
          },
        ],
        chart: {
          type: "bar", // Horizontal bar chart
          height: 440,
          stacked: true, // Stacked bars for positive and negative values
        },
        colors: ["#77DD77", "#FF6962"], // Custom colors for positive and negative bars
        plotOptions: {
          bar: {
            borderRadius: 5,
            horizontal: true, // Ensures the categories appear on the y-axis
            barHeight: "80%",
          },
        },
        dataLabels: {
          enabled: false, // Hide data labels
        },
        stroke: {
          width: 1,
          colors: ["#fff"], // Adds a border to the bars
        },
        grid: {
          xAxis: {
            lines: {
              show: false,
            },
          },
        },
        xAxis: {
          labels: {
            formatter: function () {
              console.log(this.value, 'valval')
              return this.value; // Display percentage values
            },
          },
          title: {
            text: "Percent", // Label for the x-axis
          },
        },
        yAxis: {
          categories: [], // Populated dynamically
          title: {
            text: "Aspects", // Label for the y-axis
          },
        },
        tooltip: {
          shared: false, // Separate tooltips for positive and negative bars
          y: {
            formatter: function (val) {
              return val; // Format tooltip values
            },
          },
        },
        title: {
          text: "Sentiment Analysis", // Chart title
        },
      },

    }
  },
  methods: {
    renderChart() {
      if (!this.analysedSentimentsResult?.is_bulk) {
        return
      }
      // Extract unique categories for X and Y axes
      const xCategories = [...new Set(this.analysedSentimentsResult?.aspect_co_occurrence.map(item => item.aspect1))];
      const yCategories = [...new Set(this.analysedSentimentsResult?.aspect_co_occurrence.map(item => item.aspect2))];

      // Prepare heatmap data
      const heatmapData = [];
      for (let i = 0; i < xCategories.length; i++) {
        for (let j = 0; j < yCategories.length; j++) {
          const aspect1 = xCategories[i];
          const aspect2 = yCategories[j];
          // Find matching entry or default to 0
          const entry = this.analysedSentimentsResult?.aspect_co_occurrence.find(
            item => item.aspect1 === aspect1 && item.aspect2 === aspect2
          );
          heatmapData.push([i, j, entry ? entry.cooccurrence_percentage : 0]);
        }
      }


      console.log(heatmapData, 'heatmapData')

      // Highcharts configuration
      Highcharts.chart(this.$refs.chart, {
        chart: {
          type: 'heatmap',
          plotBorderWidth: 1,
          height: '600px'
        },
        title: {
          text: 'Dynamic Heatmap'
        },
        xAxis: {
          categories: xCategories,
          title: {
            text: 'Aspects (X-Axis)'
          }
        },
        yAxis: {
          categories: yCategories,
          title: {
            text: 'Aspects (Y-Axis)'
          }
        },
        colorAxis: {
          min: 0,
          minColor: '#F4EDFB',
          maxColor: '#54179C'
        },
        series: [{
          name: 'Aspect Co-occurrence',
          borderWidth: 1,
          data: heatmapData,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return Highcharts.numberFormat(this.point.value, 2); // Format to two decimal places
            }
          }
        }]
      });

      const aspectData = {};
      this.analysedSentimentsResult?.aspect_by_sentiment.forEach((item) => {
        if (!aspectData[item.Aspect]) {
          aspectData[item.Aspect] = { Positive: 0, Negative: 0 };
        }
        aspectData[item.Aspect][item["Sentiment type"]] += item.Count;
      });

      const categories = [];
      const positiveData = [];
      const negativeData = [];

      for (const [aspect, counts] of Object.entries(aspectData)) {
        categories.push(aspect);
        const total = counts.Positive + counts.Negative;
        positiveData.push(counts.Positive); // Convert to percentage
        negativeData.push(-counts.Negative); // Negative value for stacking
      }

      this.chartOptions.xAxis.categories = categories; // Update y-axis categories
      this.chartOptions.series[0].data = positiveData; // Positive data series
      this.chartOptions.series[1].data = negativeData; // Negative data series
    },
  },

};
</script>

<style scoped>
.box-title {
  color: #390B67;
  font-weight: 600;
}

.key-points {
  font-size: small;
  text-align: justify;
}

.key-pleasure-point {
  background-color: #54179C;
  color: #fff;
  font-size: 1.25rem;
}

.key-pain-point {
  background-color: #525252;
  color: #fff;
  font-size: 1.25rem;
}

.key-pain-point-description {
  background-color: #EDEDED;
  color: #000;
  font-size: 0.75rem;
}

.key-pleasure-point-description {
  background-color: #F4EDFB;
  color: #54179C;
  font-size: 0.75rem;
}

.rating-heading {
  font-size: 1.25rem;
  border-top: 1px solid #525252;
  border-right: 1px solid #525252;
  border-left: 1px solid #525252;
  text-align: center;
}

.rating-box {
  border: 1px solid #525252;
}

.rating-description {
  font-size: 0.75rem;
}

.aspect-rating {
  /* border: 1px solid #525252; */
  color: #390B67;
  font-size: 0.75rem;
  text-align: center;
  border: 1px solid #390B67;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
  /* Thin scrollbar width */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  /* Scrollbar thumb color */
  border-radius: 4px;
  /* Rounded scrollbar thumb */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  /* Scrollbar track color */
}

.quality-score {
  font-size: .70rem;
  color: #7B7B7B;
}

.sentiment-snapshot {
  color: #fff;
}

.snapshot-title {
  font-size: .70rem;
  color: #fff;
}

.custom-badge {
  background-color: #E6D2FA !important;
  color: #390B67 !important;
}

.rating {
  text-align: left
}

.rating-box {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  /* Ensure the container doesn't overflow */
}

.key-points {
  max-height: 100%;
  /* Restrict height */
  overflow-y: auto;
  /* Add scrolling for overflow */
}

.custom-badge {
  background-color: #f3f4f6;
  /* Add a background color */
  border: 1px solid #d1d5db;
  /* Optional border for better visibility */
  white-space: nowrap;
  /* Prevent line breaks in individual badges */
  overflow: hidden;
  /* Prevent content overflow in badges */
  text-overflow: ellipsis;
  /* Add ellipsis if text overflows */
}

.keywords-container {
  display: flex;
  /* Use flexbox for layout */
  flex-wrap: wrap;
  /* Allow wrapping of keywords */
  gap: 0.5rem;
  /* Add spacing between badges */
  max-width: 100%;
  /* Prevent overflow */
}
</style>
