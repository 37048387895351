import { createRouter, createWebHistory } from "vue-router";
import SootBlowingView from '../views/SootBlowingView.vue';
import YieldOptimizerView from '../views/YieldOptimizerView.vue';
import HeatExchangerView from '../views/HeatExchangerView.vue';
import PredictiveMaintenanceMainView from "@/views/PredictiveMaintenanceMainView.vue"
import SolarInverterNetworkView from "@/views/PredictiveMaintenance/SolarInverter/NetworkView.vue"
import SolarInverterAssetView from "@/views/PredictiveMaintenance/SolarInverter/AssetView.vue";
import SolarInverterMTRiskView from "@/views/PredictiveMaintenance/SolarInverter/MTRiskView.vue";
import SolarInverterSTRiskView from "@/views/PredictiveMaintenance/SolarInverter/STRiskView.vue"
import SolarInverterDCHealthRiskView from "@/views/PredictiveMaintenance/SolarInverter/DCHealth.vue"
import SolarInverterCombinerBoxThroughputView from "@/views/PredictiveMaintenance/SolarInverter/CombinerBoxThroughput.vue"
import WindTurbineNetworkView from "@/views/PredictiveMaintenance/WindTurbine/NetworkView.vue"
import WindTurbineAssetView from "@/views/PredictiveMaintenance/WindTurbine/AssetView.vue";
import WindTurbineMTRiskView from "@/views/PredictiveMaintenance/WindTurbine/MTRiskView.vue";
import WindTurbineSTRiskView from "@/views/PredictiveMaintenance/WindTurbine/STRiskView.vue"
import CombustionAndSADCView from '../views/CombustionAndSADCView.vue';
import Home from '../components/Home.vue'
import TextSynthesizerView from '../views/TextSynthesizerView.vue'
import NewsBulletinView from "../views/NewsBulletinView.vue";
import AnalyticsView from "../views/Analytics.vue";
import CommodityPathHome from "@/views/CommodityPathHome.vue";
import CommodityLanding from "@/views/CommodityLanding.vue";
import SensaAnalyticsView from "../views/SensaAnalyticsView.vue";
import ProcessParameterDashboardView from "../views/ProcessParameterOptimzer/ProcessParameterDashboardView.vue"
import ProcessParameterIntroductionView from "../views/ProcessParameterOptimzer/ProcessParameterIntroductionView.vue"
import ProcessParameterSimulatorView from "../views/ProcessParameterOptimzer/ProcessParameterSimulatorView.vue"
import ProcessParameterPredictorView from "../views/ProcessParameterOptimzer/ProcessParameterPredictorView.vue"
import ProcessParameterPlotView from "../views/ProcessParameterOptimzer/ProcessParameterPlotView.vue"
import ProcessParameterMachineFlowView from "../views/MachineFlow/ProcessParameterMachineFlowView.vue"
import ProcessParameterMachineFlowIntroductionView from "../views/MachineFlow/ProcessParameterMachineFlowIntroductionView.vue"
import ProcessParameterMachineFlowOutputView from "../views/MachineFlow/ProcessParameterMachineFlowOutputView.vue"
import ProcessParameterCokeLandingView from "../views/ProcessParameterOptimzer/ProcessParameterCokeLandingView.vue"
import CommodityLandingBuy from "@/views/CommodityLandingBuy.vue";
import CommodityLandingSell from "@/views/CommodityLandingSell.vue";
import CustomerChurn from "@/views/CustomerChurn.vue";
import Raffia from "@/views/Raffia.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/sootblowing",
    name: "sootblowing",
    component: SootBlowingView,
  },
  {
    path: "/yield-optimizer",
    name: "yieldOptimizer",
    component: YieldOptimizerView,
  },
  {
    path: "/heat-exchanger-scheduler",
    name: "heatExchanger",
    component: HeatExchangerView,
  },
  {
    path: "/predictive-maintenance",
    name: "predictiveMaintenanceMainView",
    component: PredictiveMaintenanceMainView,
  },
  {
    path: "/solar-inverter/network-overview",
    name: "solarInverterNetworkView",
    component: SolarInverterNetworkView,
  },
  {
    path: "/solar-inverter/:asset/overview",
    name: "solarInverterAssetView",
    component: SolarInverterAssetView,
  },
  {
    path: "/solar-inverter/:asset/medium-term-risk",
    name: "solarInverterMTRiskView",
    component: SolarInverterMTRiskView,
  },
  {
    path: "/solar-inverter/:asset/short-term-risk",
    name: "solarInverterSTRiskView",
    component: SolarInverterSTRiskView,
  },
  {
    path: "/solar-inverter/:asset/dc-health",
    name: "solarInverterDCHealthRiskView",
    component: SolarInverterDCHealthRiskView,
  },
  {
    path: "/solar-inverter/:asset/combiner-box-throughput",
    name: "solarInverterCombinerBoxThroughputView",
    component: SolarInverterCombinerBoxThroughputView,
  },
  {
    path: "/wind-turbine/network-overview",
    name: "windTurbineNetworkView",
    component: WindTurbineNetworkView,
  },
  {
    path: "/wind-turbine/:asset/overview",
    name: "windTurbineAssetView",
    component: WindTurbineAssetView,
  },
  {
    path: "/wind-turbine/:asset/medium-term-risk",
    name: "windTurbineMTRiskView",
    component: WindTurbineMTRiskView,
  },
  {
    path: "/wind-turbine/:asset/short-term-risk",
    name: "windTurbineSTRiskView",
    component: WindTurbineSTRiskView,
  },
  {
    path: "/combustion-and-sadc",
    name: "combustionAndSadC",
    component: CombustionAndSADCView,
  },
  {
    path: "/commodity-pathfinder",
    name: "commodityLanding",
    component: CommodityLanding
  },
  {
    path: "/commodity-pathfinder/buy",
    name: "commodityLandingBuy",
    component: CommodityLandingBuy
  },
  {
    path: "/commodity-pathfinder/sell",
    name: "commodityLandingSell",
    component: CommodityLandingSell
  },
  {
    path: "/commodity-pathfinder/buy/:commodity/home",
    name: "commodityPathHome",
    component: CommodityPathHome
  },
  {
    path: "/commodity-pathfinder/buy/:commodity/text-synthesizer",
    name: "textsynthesizer",
    component: TextSynthesizerView,
  },
  {
    path: '/commodity-pathfinder/buy/:commodity/news',
    name: 'mewsBulletin',
    component: NewsBulletinView,
  },
  {
    path: '/commodity-pathfinder/buy/:commodity/price-plot',
    name: 'analyticsView',
    component: AnalyticsView,
  },
  {
    path: "/commodity-pathfinder/buy/:commodity",
    name: "commodityLandingBuyCommodity",
    component: CommodityLandingBuy
  },
  {
    path: "/commodity-pathfinder/sell/:commodity",
    name: "commodityLandingSellCommodity",
    component: CommodityLandingSell
  },
  {
    path: '/sensa-analysis',
    name: 'sensaAnalyticsView',
    component: SensaAnalyticsView,
  },
  {
    path: "/process-parameter-optimizer/introduction",
    name: "processParameterIntroductionView",
    component: ProcessParameterIntroductionView
  },
  {
    path: "/process-parameter-optimizer/dashboard",
    name: "processParameterOptimizerView",
    component: ProcessParameterDashboardView
  },
  {
    path: "/process-parameter-optimizer/simulator",
    name: "processParameterSimulatorView",
    component: ProcessParameterSimulatorView
  },
  {
    path: "/process-parameter-optimizer/predictor",
    name: "processParameterPredictorView",
    component: ProcessParameterPredictorView
  },
  {
    path: "/process-parameter-optimizer/plots",
    name: "ProcessParameterPlotView",
    component: ProcessParameterPlotView
  },
  {
    path: "/customer-churn",
    name: "customerChurn",
    component: CustomerChurn
  },
  {
    path: "/raffia",
    name: "raffia",
    component: Raffia
  },
  {
    path: "/process-parameter-optimizer/landing",
    name: "ProcessParameterCokeLandingView",
    component: ProcessParameterCokeLandingView
  },
  {
    path: "/machine-flow/introduction",
    name: "ProcessParameterMachineFlowIntroductionView",
    component: ProcessParameterMachineFlowIntroductionView
  },
  {
    path: "/machine-flow/input",
    name: "ProcessParameterMachineFlowView",
    component: ProcessParameterMachineFlowView
  },
  {
    path: "/machine-flow/output",
    name: "ProcessParameterMachineFlowOutputView",
    component: ProcessParameterMachineFlowOutputView
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
