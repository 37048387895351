const dataConfigurableParameters = {
    'controllable': {
        'blast_temp': {
            name: 'Blast Temperature',
            unit: '°C',
            min: "1140.0",
            max: "1180.0",
            value: 1155,
            current: 0,
            proposed: 0,
            currentSimulator: 1150
        },
        'oxygen_enriched_rate': {
            name: 'Oxygen Enriched Rate',
            unit: '%',
            min: "4.0",
            max: "6.5",
            value: 5.3,
            current: 0,
            proposed: 0,
            currentSimulator: 5.3
        },
        'steam_addition': {
            name: 'Steam Addition',
            unit: 'kg/thm',
            min: "5.0",
            max: "30.0",
            value: 10,
            current: 0,
            proposed: 0,
            currentSimulator: 10
        },
        'top_gas_pressure_average': {
            name: 'Top Gas Pressure Avg',
            unit: 'gm/cm2',
            min: "135.0",
            max: "155.0",
            value: 140,
            current: 0,
            proposed: 0,
            currentSimulator: 140
        },
        'Pellet%': {
            name: 'Pellet',
            unit: '%',
            min: "0",
            max: "30",
            value: 10,
            current: 0,
            proposed: 0,
            currentSimulator: 10
        },
        'Sinter%': {
            name: 'Sinter',
            unit: '%',
            min: "60",
            max: "80",
            value: 70,
            current: 0,
            proposed: 0,
            currentSimulator: 70
        },
    },
    'uncontrollable': {
        'Hot_Metal_Temp': {
            name: 'Hot Metal Temperature',
            unit: '°C',
            value: 1493.4,
            current: 0,
            proposed: 0,
            currentSimulator: 1493.4
        },
        'S_0.045_max': {
            name: 'Sulphur content in the hot metal',
            unit: '%',
            value: 0.02,
            current: 0,
            proposed: 0,
            currentSimulator: 0.02
        },
        'Si_1.2_max': {
            name: 'Silicon content in the hot metal',
            unit: '%',
            value: 0.7,
            current: 0,
            proposed: 0,
            currentSimulator: 0.7
        },
        'Ti': {
            name: 'Titanium content in the hot metal',
            unit: '%',
            value: 0.06,
            current: 0,
            proposed: 0,
            currentSimulator: 0.06
        },
        'Bosh Gas Volume (Nm3/min)': {
            name: 'Bosh Gas Volume',
            unit: 'Nm3/min',
            value: 2527.9,
            current: 0,
            proposed: 0,
            currentSimulator: 2527.9
        },
        'pci_rate': {
            name: 'PCI Rate',
            unit: 'kg/thm',
            min: "135.0",
            max: "200.0",
            value: 155,
            current: 0,
            proposed: 0,
            currentSimulator: 155
        },
        'RAFT': {
            name: 'Raceway Adiabatic Flame Temperature',
            unit: '°C',
            value: 2560.5,
            current: 0,
            proposed: 0,
            currentSimulator: 2560.5
        },
        'blast_speed': {
            name: 'Blast Speed',
            unit: 'm/sec',
            value: 211.7,
            current: 0,
            proposed: 0,
            currentSimulator: 211.7
        },
        'burden_permeability_index': {
            name: 'Burden Permeability Index',
            unit: '',
            value: 14.3,
            current: 0,
            proposed: 0,
            currentSimulator: 14.3
        },
        'gas_utilization_rate': {
            name: 'Gas Utilization Rate',
            unit: '%',
            value: 44.2,
            current: 0,
            proposed: 0,
            currentSimulator: 44.2
        },
        'heat_loss_MJ': {
            name: 'Heat loss',
            unit: 'MJ',
            value: 37.5,
            current: 0,
            proposed: 0,
            currentSimulator: 37.5
        },
        'hot_blast_pressure': {
            name: 'Hot Blast Pressure',
            unit: 'gm/cm2',
            value: 299.1,
            current: 0,
            proposed: 0,
            currentSimulator: 299.1
        },
        'top_gas_temperature_average': {
            name: 'Top Gas Temperature Avg',
            unit: '°C',
            value: 147.4,
            current: 0,
            proposed: 0,
            currentSimulator: 147.4
        },
        'ASH_x': {
            name: 'Coke ash level',
            unit: '%',
            value: 13.2,
            current: 0,
            proposed: 0,
            currentSimulator: 13.2
        },
        'ASH_y': {
            name: 'Coke ash level for purchased coke',
            unit: '%',
            value: 12.8,
            current: 0,
            proposed: 0,
            currentSimulator: 12.8
        },
        'Burden Fe %': {
            name: 'Fe content in burden(Sinter + Pellet + Ore)',
            unit: '%',
            value: 57.7,
            current: 0,
            proposed: 0,
            currentSimulator: 57.7
        },
        'CSR_x': {
            name: 'Coke Strength after Reaction',
            unit: '%',
            value: 0.6,
            current: 0,
            proposed: 0,
            currentSimulator: 0.6
        },
        'Flux Rate': {
            name: 'Flux Rate',
            unit: 'kg/thm',
            value: 63,
            current: 0,
            proposed: 0,
            currentSimulator: 63
        },
        'MPS_x': {
            name: 'Coke mean particle size',
            unit: 'mm',
            value: 50.9,
            current: 0,
            proposed: 0,
            currentSimulator: 50.9
        },
        'Micum_Index_M10_x': {
            name: 'M10 Coke Micum index',
            unit: '%',
            value: 5.2,
            current: 0,
            proposed: 0,
            currentSimulator: 5.2
        },
        'Micum_Index_M10_y': {
            name: 'M10 Coke Micum index for purchased coke',
            unit: '%',
            value: 5.7,
            current: 0,
            proposed: 0,
            currentSimulator: 5.7
        },
        'Moisture_x': {
            name: 'Coke moisture',
            unit: '%',
            value: 4.9,
            current: 0,
            proposed: 0,
            currentSimulator: 4.9
        },
        'Moisture_y': {
            name: 'Coke moisture level for purchased coke',
            unit: '%',
            value: 4.2,
            current: 0,
            proposed: 0,
            currentSimulator: 4.2
        },
        'Sinter Basicity': {
            name: 'Sinter Basicity',
            unit: '',
            value: 1.9,
            current: 0,
            proposed: 0,
            currentSimulator: 1.9
        },
        'Skull': {
            name: 'Skull',
            unit: 'mm',
            value: 44.6,
            current: 0,
            proposed: 0,
            currentSimulator: 44.6
        },
        'Slag Volume': {
            name: 'Slag Volume',
            unit: 'kg/thm',
            value: 351.5,
            current: 0,
            proposed: 0,
            currentSimulator: 351.5
        },
        'Slag_Basicity': {
            name: 'Slag Basicity',
            unit: '',
            value: 0.4,
            current: 0,
            proposed: 0,
            currentSimulator: 0.4
        }
    }
};

export default dataConfigurableParameters;
