<template>
  <div class="flex h-screen">
    <TreeMenu></TreeMenu>
    <div class="flex-1 p-4 mb-16 body-bg overflow-y-auto">
      <div class="grid grid-rows-[auto,auto,1fr] p-4 h-screen-auto bg-white shadow-md rounded-md p-4 mx-4">
        <div class="h-22 mb-4">
          <h1 class="font-bold text-[2rem] mt-2">Welcome to the Advanced Coal Beneficiation Digital Twin</h1>
        </div>
        <div class="flex-1 mb-4 pl-1 text-sm ">
          <div class="w-full flex font-semibold purple-text"><p>We have developed an advanced digital twin for coal beneficiation, integrating process digitalization and equipment optimization. It identifies optimal load factors and key levers to achieve over 30% increased clean coal output while ensuring target ash content compliance. </p></div>
          <div class="w-full flex mt-4"><p>Efficient coal beneficiation requires a balancing of load, yield and ash content.</p></div>
          <div class="flex-1 flex flex-row mt-4">
            <div class="w-1/2 flex items-center justify-center m-4 border-1 border border-gray-200">
              <img :src="icons.factoryLoad" class="h-72 mr-2" />
            </div>
            <div class="w-1/2 flex items-center mr-4 m-4">
              <div class="flex flex-col space-y-2">
                <div class="w-full flex items-center bg-white shadow-md rounded-md p-4">
                  <img :src="icons.load" class="h-16 mr-4" />
                  <ul>
                    <li>
                      <p><b>Load</b> of factory defines mined rock processing volume, ton per hour</p>
                    </li>
                    <li class="ml-4 list-disc list-inside">Increasing load may reduce equipment efficiency - Classification, extraction, dewatering and other processes
                    </li>
                  </ul>
                </div>
                <div class="w-full flex items-center bg-white shadow-md rounded-md p-4">
                  <img :src="icons.yield" class="h-16 mr-4" />
                  <ul>
                    <li>
                      <p><b>Yield</b> defines clean coal volume of target ash content extracted from mined rock, %</p>
                    </li>
                    <li class="ml-4 list-disc list-inside">The lower the ash content the lower the yield</li>
                  </ul>
                </div>
                <div class="w-full flex items-center bg-white shadow-md rounded-md p-4">
                  <img :src="icons.ash" class="h-16 mr-4" />
                  <ul>
                    <li>
                      <p><b>Ash</b> is defined by market demand and determined by technological process</p>
                    </li>
                    <li class="ml-4 list-disc list-inside">The lower the ash the higher the waste, though the higher efficiency of coke production (where metallurgical coal is used)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full flex mt-4"><p>Our model builds yield vs. ash content curved for each load level. This is used to determine the economically optimal load level, after considering production cost.</p></div> 
          <div class="w-full flex mt-4"><p>The digital twin finds an optimal yield-ash balance for several process stages, given the optimal load level.</p></div>
          <div class="w-full flex mt-4"><p>The result of optimization is projected total yield of coal concentrate and optimal values of control parameters, which operator sets manually.</p></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TreeMenu from './TreeMenu.vue';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'machineFlowIntroduction',
  components: { TreeMenu },
  data() {
    return {
      icons: {
        machineFlow: require('@/assets/img/machine_flow.png'), // Your image path
        factoryLoad: require('@/assets/img/factory-load.png'), // Your image path
        yield: require('@/assets/img/yield.png'), // Your image path
        load: require('@/assets/img/load.png'), // Your image path
        ash: require('@/assets/img/ash.png'), // Your image path
      },
    };
  },
  computed: {
    ...mapState('machineFlow', ['machineData', 'ash']),
  },
  methods: {
    ...mapMutations('machineFlow', ['SET_MACHINE_DATA', 'SET_ASH'])
  },
};
</script>


<style>
.body-bg {
  background: #F5F6FA;
  border-left: #D9D9D9 1px solid;
}
</style>
