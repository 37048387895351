<template>
    <div v-if="showPopup" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-30">
        <div class="max-w-screen-md p-4 bg-white overflow-auto max-h-screen">
            <p class="text-sm text-black" v-html="fullText"></p>
            <button @click="$emit('close-popup')" class="mt-4 px-2 py-1 text-white bg-blue-500 hover:bg-blue-600">Close</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showPopup: false,
        fullText: ''
    },
}
</script>