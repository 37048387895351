<template>
  <div class="left-sidebar text-black border-2 shadow-lg z-10 overflow-auto-y">
    <div class="main-options">
      <!-- Operations Main Option -->
      <div class="main-option px-3 py-1 font-semibold flex justify-between text-sm" @click="toggleOptions">
        <div class="flex items-center"><span>Throughput & Yield</span></div>
        <img :src="showOptionsDropdown ? icons['collapseIcon'] : icons['expandIcon']" alt=""
          class="inline mr-1 cursor-pointer" />
      </div>
      <div class="options-dropdown text-sm">
        <div v-for="subOption in processSubOptions" :key="subOption.id" class="sub-option text-xs flex"
          :class="{ 'selected-sub-option': selectedSubOption === subOption.id }"
          @click="handleSubOptionClick(subOption.id)">
          <span >⦿ {{ subOption.name }}</span> 
        </div>
        <div v-for="subOption in disabledProcessSubOptions" :key="subOption.id" class="sub-option text-xs flex justify-between"
          :class="{ 'selected-sub-option': selectedSubOption === subOption.id }">
          <span >⦿ {{ subOption.name }}</span> 
          <img :src="icons['clockIcon']" alt="" class="inline mr-1 h-4">
        </div>
      </div>
    </div>
    <div class="main-options">
      <!-- Operations Main Option -->
      <div class="main-option px-3 py-1 font-semibold flex justify-between text-sm" @click="toggleAvailablity">
        <div class="flex items-center"><span>Availability</span></div>
        <img :src="showAvailablityDropdown ? icons['collapseIcon'] : icons['expandIcon']" alt=""
          class="inline mr-1 cursor-pointer" />
      </div>
      <div class="options-dropdown text-sm">
        <div v-for="subOption in availablitySuboptions" :key="subOption.id" class="sub-option text-xs flex justify-between"
          :class="{ 'selected-sub-option': selectedSubOption === subOption.id }"
          @click="handleSubOptionClick(subOption.id)">
          <span >⦿ {{ subOption.name }}</span> 
          <!-- <img :src="icons['clockIcon']" alt="" class="inline mr-1 h-4"> -->
        </div>
      </div>
    </div>
    <div class="main-options">
      <!-- Operations Main Option -->
      <div class="main-option px-3 py-1 font-semibold flex justify-between text-sm" @click="toggleCommercial">
        <div class="flex items-center"><span>Commercial Optimization</span></div>
        <img :src="showCommercialDropdown ? icons['collapseIcon'] : icons['expandIcon']" alt=""
          class="inline mr-1 cursor-pointer" />
      </div>
      <div class="options-dropdown text-sm">
        <div v-for="subOption in commercialSubOptions" :key="subOption.id" class="sub-option text-xs flex justify-between"
          :class="{ 'selected-sub-option': selectedSubOption === subOption.id }"
          @click="handleSubOptionClick(subOption.id)">
          <span >⦿ {{ subOption.name }}</span> 
        </div>
        <div v-for="subOption in disabledCommercialSubOptions" :key="subOption.id" class="sub-option text-xs flex justify-between"
          :class="{ 'selected-sub-option': selectedSubOption === subOption.id }">
          <span >⦿ {{ subOption.name }}</span> 
          <img :src="icons['clockIcon']" alt="" class="inline mr-1 h-4">
        </div>
      </div>
    </div>
    <div class="main-options">
      <!-- Operations Main Option -->
      <div class="main-option px-3 py-1 font-semibold flex justify-between text-sm" @click="toggleAncillary">
        <div class="flex items-center"><span>Ancillary & Indirect Cost</span></div>
        <img :src="showAncillaryDropdown ? icons['collapseIcon'] : icons['expandIcon']" alt=""
          class="inline mr-1 cursor-pointer" />
      </div>
      <div class="options-dropdown text-sm">
        <div v-for="subOption in ancillarySubOptions" :key="subOption.id" class="sub-option text-xs flex justify-between"
          :class="{ 'selected-sub-option': selectedSubOption === subOption.id }"
          @click="handleSubOptionClick(subOption.id)">
          <span >⦿ {{ subOption.name }}</span> 
          <!-- <img :src="icons['clockIcon']" alt="" class="inline mr-1 h-4"> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showOptionsDropdown: false,
      showAvailablityDropdown: false,
      showCommercialDropdown: false,
      showAncillaryDropdown: false,

      localSelectedSubOption: this.selectedSubOption,
      processSubOptions: [
        { id: 1, name: "YIELDify" },
        { id: 2, name: "Deep Thermal" },
        { id: 9, name: "Heat Exchanger Scheduler" },
        { id: 10, name: "Blast Furnace Coke Rate Optimizer" },
        { id: 11, name: "Coal Beneficiation Optimizer" },
      ],
      disabledProcessSubOptions: [
        { id: 3, name: "Smart Soot-Blower" },
      ],

      availablitySuboptions: [
        { id: 4, name: "Predictive Maintenance" }
      ],

      commercialSubOptions: [
        { id: 5, name: "Commodity Pathfinder" },
        { id: 6, name: "CFP Raffia" },
      ],

      disabledCommercialSubOptions: [
        { id: 7, name: "Prescriptive Capex" },
      ],

      ancillarySubOptions: [
        { id: 8, name: "SenSA" }
      ],
      icons: {
        operationsHome: require('@/assets/img/Home.svg'),
        expandIcon: require('@/assets/img/expand-icon.svg'),
        collapseIcon: require('@/assets/img/collapse-icon.svg'),
        clockIcon: require('@/assets/img/sand-clock.svg')
      }
    };
  },
  props: {
    selectedSubOption: {
      type: Number,
      default: null,
    },
  },
  methods: {
    toggleOptions() {
      this.showOptionsDropdown = !this.showOptionsDropdown;
    },

    toggleAvailablity() {
      this.showAvailablityDropdown = !this.showAvailablityDropdown;
    },

    toggleCommercial() {
      this.showCommercialDropdown = !this.showCommercialDropdown;
    },

    toggleAncillary() {
      this.showAncillaryDropdown = !this.showAncillaryDropdown;

    },
    handleSubOptionClick(subOptionId) {
      let routePath = "/home";
      this.localSelectedSubOption = subOptionId;

      if (subOptionId === 1) {
        routePath = '/yield-optimizer';
      } else if (subOptionId === 2) {
        routePath = '/combustion-and-sadc';
      } else if (subOptionId === 3) {
        routePath = "/sootblowing";
      } else if (subOptionId === 5){
        routePath = '/commodity-pathfinder';
      } else if (subOptionId === 6){
        routePath = '/commodity-pathfinder/sell/raffia';
      } else if (subOptionId === 9) {
        routePath = '/heat-exchanger-scheduler';
      } else if (subOptionId === 10) {
        routePath = '/process-parameter-optimizer/dashboard';
      } else if (subOptionId === 11) {
        routePath = '/machine-flow/introduction';
      } else if (subOptionId === 4) {
        routePath = '/predictive-maintenance';
      } else if (subOptionId === 8) {
        routePath = '/commodity-pathfinder/sell/sensa';
      }
      this.$emit('update-tab', this.localSelectedSubOption)
      this.$router.push(routePath);
    },
  },
};
</script>

<style scoped>
.wip {
  color:#8737E1;
  font-weight: 700;
}
.main-options {
  position: relative;
}

.main-option {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #EBEBEB;
  border-top: 1px solid #EBEBEB;
  margin-bottom: 10px;
}

.options-dropdown {
  top: 100%;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #EBEBEB;
}

.sub-option {
  padding: 5px 15px;
  cursor: pointer;
  padding: 0.5rem 3rem;
  font-weight: 500;
}

.sub-option:hover {
  border-right: 2px solid #8737E1;
}

.subheading {
  padding: 5px 15px;
  cursor: pointer;
  padding-left: 2rem;
}

.selected-sub-option {
  background-color: #F5EDFF !important;
  border-right: 6px solid #8737E1 !important;
}
</style>
