<template>
  <div class="mt-4">
    <h3 class="font-semibold text-[1rem]">
      Select Parameters
    </h3>
    <div class="flex items-center space-x-4 mb-4">
      <label v-for="(parameter, key) in dataConfigurableParameters" :key="key" class="flex items-center text-[0.825rem]">
        <input type="checkbox" v-model="selectedParameters" :value="key" @change="renderChart" class="mr-2 accent-blue-custom" />
        {{ parameter.name }}
      </label>
    </div>
    <div ref="tornadoChart" style="height: 400px; width: 100%;"></div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';

export default {
  name: 'TornadoChart',
  props: {
    tornadoData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedParameters: [], // Track selected parameters
      dataConfigurableParameters: {
        'Pellet%': {
          name: 'Pellet (%)',
          unit: '%'
        },
        'Sinter%': {
          name: 'Sinter (%)',
          unit: '%'
        },
        'top_gas_pressure_average': {
          name: 'Top Gas Pressure Average',
          unit: 'Nm3/min'
        },
        'oxygen_enriched_rate': {
          name: 'Oxygen Enriched Rate',
          unit: '°C'
        },
        'steam_addition': {
          name: 'Steam Addition',
          unit: '°C'
        },
        'blast_temp': {
          name: 'Blast Temperature',
          unit: '°C'
        },
      }
    }
  },
  mounted() {
    this.selectedParameters = Object.keys(this.dataConfigurableParameters); // Select all initially
    this.renderChart();
  },
  methods: {
    renderChart() {
      if (!this.tornadoData || !this.$refs.tornadoChart) return;

      const orderedKeys = Object.keys(this.dataConfigurableParameters);
      const categories = this.selectedParameters.sort(
        (a, b) => orderedKeys.indexOf(a) - orderedKeys.indexOf(b)
      );

      const positiveImpact = categories.map(key => 
        this.tornadoData[key]?.[1] !== undefined ? parseFloat(this.tornadoData[key][1].toFixed(1)) : 0
      );
      const negativeImpact = categories.map(key => 
        this.tornadoData[key]?.[0] !== undefined ? parseFloat(this.tornadoData[key][0].toFixed(1)) : 0
      );
      const chartCategories = categories.map(category => this.dataConfigurableParameters[category]?.name);
      Highcharts.chart(this.$refs.tornadoChart, {
        chart: {
          type: 'bar',
        },
        title: {
          text: 'Impact of Change in Parameters on Coke Rate',
        },
        xAxis: {
          categories: chartCategories,
          title: { text: null },
        },
        yAxis: {
          title: { text: 'Parameter Change' },
          min: -100,
          max: 100,
          tickInterval: 20,
        },
        plotOptions: {
          series: {
            stacking: 'normal',
          },
        },
        series: [
          {
            name: 'Negative Parameter Change',
            data: negativeImpact,
            color: '#C62828',
          },
          {
            name: 'Positive Parameter Change',
            data: positiveImpact,
            color: '#2E7D32',
          },
        ],
      });
    }
  },
  watch: {
    tornadoData: {
      immediate: true,
      handler(newData) {
        if (newData) this.renderChart();
      },
    },
  },
};
</script>
