<template>
  <div class="h-full overflow-auto">
    <div class="px-24 py-8 w-full text-left">
      <h2 class="text-2xl font-bold mr-4">
        Identify potential churn and enhance customer retention with our predictive analytics tool
      </h2>
    </div>
    <VideoPlayer :videoPath="customerChurnVideo" />
  </div>
</template>

<script>
import VideoPlayer from '@/components/CommodityPathfinder/VideoPlayer.vue';
import customerChurnVideo from "@/assets/img/Customer_Churn_Edited.webm";

export default {
  name: 'CustomerChurn',
  components: {
    VideoPlayer
  },
  data() {
    return {
      customerChurnVideo
    }
  },
};
</script>
