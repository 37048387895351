<template>
  <div class="flex h-screen">
    <TreeMenuLanding></TreeMenuLanding>
    <div class="flex-1 p-4 mb-16 body-bg overflow-y-auto">
      <div class="grid grid-rows-[auto,auto,1fr] p-4 h-screen-auto bg-white shadow-md rounded-md p-4 mx-4">
        <div class="flex justify-between items-center mb-8">
          <div class="flex flex-col">
            <div>
              <h1 class="font-bold text-[2rem]">Blast Furnace Coke Rate Optimizer</h1>
            </div>
            <div class="w-full font-semibold purple-text">
              <p>Revolutionize Steelmaking with Real-Time Insights</p>
            </div>
          </div>
          <div>
            <button
              class="bg-purple-500 text-white text-sm font-semibold px-6 py-2 rounded-sm shadow-lg hover:bg-purple-600 transition"
              @click="navigateTo('dashboard')"
            >
              Explore
            </button>
          </div>
        </div>

        <div class="flex-1 mb-8 pl-1 text-sm ">
          <div class="flex flex-wrap items-center gap-6 p-6 bg-gray-50">
            <!-- Image Section -->
            <div class="w-full md:w-[30%]">
              <img :src="icons.cokeRateOptimizer" alt="Blast Furnace" class="w-full h-auto object-cover rounded-lg" />
            </div>

            <!-- Content Section -->
            <div class="w-full md:w-[60%]">
              <p class="text-gray-700 text-base leading-relaxed">
                Harness the power of our advanced
                <b class="text-gray-900">Blast Furnace Coke Rate Optimizer</b> - a
                Digital-Twin online simulation platform designed to replicate real-world
                furnace behavior. Make smarter decisions by understanding the impact of
                controllable and non-controllable parameters on critical performance
                metrics like the coke rate, fuel efficiency, and productivity.
              </p>
            </div>
          </div>
          <div class="flex-1 flex flex-row mt-4">
            <div class="flex justify-between items-stretch gap-6 px-10 py-8 bg-gray-50">
              <router-link v-for="(item, index) in items" :to="item.url" :key="index"
                class="flex flex-col items-center w-1/3 bg-white border border-gray-200 rounded-lg shadow-lg p-4 hover:shadow-xl transition-transform transform hover:-translate-y-1">
                <!-- Heading -->
                <h2 class="text-lg font-semibold text-gray-800 mb-4">{{ item.heading }}</h2>
                <!-- Image -->
                <img :src="item.image" alt="Item Image" class="w-full h-40 object-cover rounded-md mb-4" />
                <!-- Description -->
                <p class="text-center text-gray-700 text-base">
                  {{ item.description }}
                </p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TreeMenuLanding from './TreeMenuLanding.vue';

export default {
  name: 'processParameterIntroduction',
  components: { TreeMenuLanding },
  data() {
    return {
      icons: {
        cokeRateOptimizer: require('@/assets/img/coke-rate-optimizer.jpg'), // Your image path
      },
      items: [
        {
          heading: "Data Pulse",
          image: require('@/assets/img/data-pulse.jpg'),
          description: "Access a comprehensive archive of historical data, enabling trend analysis, performance benchmarking, and informed decision-making based on past operational insights.",
          url: '/process-parameter-optimizer/dashboard'
        },
        {
          heading: "Optimization Wizard",
          image: require('@/assets/img/optimization-wizard.jpg'),
          description: "Instantly fine-tune operations by simulating and implementing parameter adjustments in real-time, ensuring peak performance and adaptability to changing conditions.",
          url: '/process-parameter-optimizer/predictor'
        },
        {
          heading: "Scenario Architect",
          image: require('@/assets/img/scenario-architect.jpg'),
          description: "Explore multiple operational scenarios to evaluate outcomes, optimize strategies, and prepare for real-world challenges with confidence.",
          url: '/process-parameter-optimizer/simulator'
        },
      ],
    };
  },
  methods: {
    navigateTo(page) {
      this.$router.push(`/process-parameter-optimizer/${page}`);
    },
  }
};
</script>


<style>
.body-bg {
  background: #F5F6FA;
  border-left: #D9D9D9 1px solid;
}
</style>
