<template>
  <div class="w-64 bg-white text-black pb-16 h-44 overflow-y-auto ml-8 mt-8 rounded-sm custom-scrollbar">
    <div
      class="sticky top-0 bg-white z-10 relative flex font-bold text-lg items-center pl-8 py-2 active-menu rounded shadow-sm">
      Select Tool
    </div>
    <ul class="space-y-2 mt-4 mr-2" v-if="treeMenuFor === 'BUY'">
      <li v-for="item in menuItemsBuy" :key="item.name" class="relative">
        <router-link 
          v-if="!item.disabled"
          :to="{ name: 'commodityLandingBuyCommodity', params: { commodity: item.commodity } }"
          :class="['font-semibold flex items-center mx-8 pl-4 text-center py-2 active-menu rounded hover:text-purple-600', { 'text-purple-600': isActive(item) }]">
          <span class="purple-text" style="letter-spacing: -4px;">&gt; &gt;</span> &nbsp; {{ item.name }}
        </router-link>
        <span v-else class="font-semibold flex items-center mx-8 pl-4 text-center py-2 active-menu rounded cursor-not-allowed text-gray-500">
          <span class="purple-text" style="letter-spacing: -4px;">&gt; &gt;</span> &nbsp; {{ item.name }}
        </span>
      </li>
    </ul>
    <ul class="space-y-2 mt-4 mr-2" v-if="treeMenuFor === 'SELL'">
      <li v-for="item in menuItemsSell" :key="item.name" class="relative">
        <router-link 
          v-if="!item.disabled"
          :to="{ name: 'commodityLandingSellCommodity', params: { commodity: item.commodity } }"
          :class="['font-semibold flex items-center mx-8 pl-4 text-center py-2 active-menu rounded hover:text-purple-600', { 'text-purple-600': isActive(item) }]">
          <span class="purple-text" style="letter-spacing: -4px;">&gt; &gt;</span> &nbsp; {{ item.name }}
        </router-link>
        <span v-else class="font-semibold flex items-center mx-8 pl-4 text-center py-2 active-menu rounded cursor-not-allowed text-gray-500">
          <span class="purple-text" style="letter-spacing: -4px;">&gt; &gt;</span> &nbsp; {{ item.name }}
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    treeMenuFor: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      menuItemsBuy: [
        { name: "Coking Coal", commodity: "coking-coal", disabled: false },
        { name: "Brent", commodity: "brent", disabled: false },
        { name: "Naphtha", commodity: "naphtha", disabled: false },
        { name: "Steel", commodity: "steel", disabled: false },
        { name: "Commodity 1", commodity: "commodity-1", disabled: true }, // Disabled item
        { name: "Commodity 2", commodity: "commodity-2", disabled: true },
        { name: "Commodity 3", commodity: "commodity-3", disabled: true },
        { name: "Commodity 4", commodity: "commodity-4", disabled: true },
        { name: "Commodity 5", commodity: "commodity-5", disabled: true },
        { name: "Commodity 6", commodity: "commodity-6", disabled: true },
      ],
      menuItemsSell: [
        { name: "SenSA", commodity: "sensa", disabled: false },
        { name: "Customer Churn", commodity: "customer-churn", disabled: false },
        { name: "Raffia", commodity: "raffia", disabled: false },
      ]
    };
  },
  methods: {
    isActive(item) {
      return this.$route.params.commodity === item.commodity;
    },
  },
};
</script>


<style scoped>
/* Custom Scrollbar Styling */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  /* Scrollbar thumb color */
  border-radius: 4px;
  /* Round edges */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
  /* Darker grey on hover */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Scrollbar track color */
  border-radius: 4px;
  /* Round edges */
}

.custom-scrollbar::-webkit-scrollbar-track:hover {
  background-color: #e6e6e6;
  /* Slightly darker grey on track hover */
}

/* Sticky Header Styling */
.sticky {
  position: sticky;
  top: 0;
  /* Stick to the top of the container */
  background-color: white;
  /* Ensure background covers content behind it */
  z-index: 10;
  /* Ensure it stays above other elements */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  /* Optional: Shadow for better separation */
}
</style>