<template>
  <div class="flex h-screen">
    <TreeMenu></TreeMenu>
    <div class="flex-1 p-4 overflow-y-auto body-bg">
      <div class="grid grid-rows-[auto,auto,1fr] p-4 min-h-screen overflow-y-auto">
        <div class="h-16 mb-4">
          <h1 class="font-bold text-[2rem] mt-2">
            Data Pulse
          </h1>
        </div>
        <div class="flex items-center justify-between mb-4 pl-1">
          <div class="flex space-x-1">
            <button v-for="(tab, index) in tabs" :key="tab.name" @click="selectedTab = index" :class="[
              'rounded tab-button px-4 py-1',
              selectedTab === index
                ? 'button-custom text-white'
                : 'bg-white text-black hover:button-custom'
            ]" :style="{ marginLeft: '-5px' }">
              {{ tab.name }}
            </button>
          </div>

          <div class="flex items-center space-x-2">
            <div class="w-15 py-2 flex items-center space-x-2" v-if="!loading">
              <input 
                type="date" 
                v-model="startDate" 
                @change="updateStartDate" 
                class="rounded"
                :style="{ border: '1px solid #232B60', width: '120px', padding: '3px' }" />
            </div>
            <span v-if="!loading">-</span>
            <div class="w-15 py-2 flex items-center space-x-2" v-if="!loading">
              <input 
                type="date" 
                v-model="endDate" 
                @change="updateEndDate" 
                class="rounded" 
                :style="{ border: '1px solid #232B60', width: '120px', padding: '3px' }" />
            </div>
            <!-- Add Search Button -->
            <button v-if="!loading" class="px-1 py-1 tab-button rounded" @click="searchData"
              :disabled="loading">
              <img :src="icons['search']" alt="Image" class="h-6 p-1" />
            </button>
            <div v-if="loading" class="flex justify-center items-center mt-2">
              <div
                class="spinner-border animate-spin border-4 border-ppo-custom-blue border-t-transparent rounded-full w-6 h-6">
              </div>
            </div>
          </div>
        </div>

        <!-- Render Tabs and Data Here -->
        <div class="mt-4 mb-16 w-full" v-if="selectedTab === 0" :key="hotMetalQualityKey">
          <h2 class="font-semibold text-[1.5rem] ml-1" v-if="chartDataSegmentation?.hotMetalQuality?.controllable && Object.keys(chartDataSegmentation.hotMetalQuality.controllable).length > 0">Controllable Parameters</h2>
          <div class="grid grid-cols-3 gap-2 mb-8 mt-2" v-if="chartDataSegmentation?.hotMetalQuality?.controllable && Object.keys(chartDataSegmentation.hotMetalQuality.controllable).length > 0">
            <div class="['border bg-white shadow-lg rounded px-4 w-full', { 'fullscreen': isFullScreen }]"
              v-for="(item, index) of chartDataSegmentation.hotMetalQuality?.controllable" :key="index">
              <div :ref="`controllableFullscreenContainer${index}`" class="content">
                <LineChart :title="index" :categories="chartDataSegmentation.timestamps" :series-data="item" />
              </div>
            </div>
          </div>
          <h2 class="font-semibold text-[1.5rem] mb-2 ml-1">Uncontrollable Parameters</h2>
          <div class="grid grid-cols-3 gap-2" v-if="chartDataSegmentation.hotMetalQuality?.uncontrollable">
            <div class="['border bg-white shadow-lg rounded px-4 w-full', { 'fullscreen': isFullScreen }]"
              v-for="(item, index) of chartDataSegmentation.hotMetalQuality?.uncontrollable" :key="index">
              <div :ref="`uncontrollableFullscreenContainer${index}`" class="content">
                <LineChart :title="index" :categories="chartDataSegmentation.timestamps" :series-data="item" />
              </div>
            </div>
          </div>
        </div>
        <!-- Similar structure for other tabs -->

        <div class="mt-4 mb-16 w-full" v-if="selectedTab === 1" :key="rawMetalQualityKey">
          <h2 class="font-semibold text-[1.5rem] ml-1">
            Controllable Parameters
          </h2>
          <div class="grid grid-cols-3 gap-2 mt-2" v-if="chartDataSegmentation.rawMetalQuality?.controllable">
            <div class="['border bg-white shadow-lg rounded px-4 w-full', { 'fullscreen': isFullScreen }]"
              v-for="(item, index) of chartDataSegmentation.rawMetalQuality?.controllable" :key="index">
              <div ref="fullscreenContainer" class="content">
                <LineChart :title="index" :categories="chartDataSegmentation.timestamps" :series-data="item" />
              </div>
            </div>
          </div>
          <h2 class="font-semibold text-[1.5rem] mt-8 mb-2 ml-1">
            Uncontrollable Parameters
          </h2>
          <div class="grid grid-cols-3 gap-2" v-if="chartDataSegmentation.rawMetalQuality?.uncontrollable">
            <div class="['border bg-white shadow-lg rounded px-4 w-full', { 'fullscreen': isFullScreen }]"
              v-for="(item, index) of chartDataSegmentation.rawMetalQuality?.uncontrollable" :key="index">
              <div ref="fullscreenContainer" class="content">
                <LineChart :title="index" :categories="chartDataSegmentation.timestamps" :series-data="item" />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4 mb-16 w-full" v-if="selectedTab === 2" :key="processKey">
          <h2 class="font-semibold text-[1.5rem] ml-1">
            Controllable Parameters
          </h2>
          <div class="grid grid-cols-3 gap-2 mt-2" v-if="chartDataSegmentation.process?.controllable">
            <div class="['border bg-white shadow-lg rounded px-4 w-full', { 'fullscreen': isFullScreen }]"
              v-for="(item, index) of chartDataSegmentation.process?.controllable" :key="'controllable-' + index">
              <div ref="fullscreenContainer" class="content">
                <LineChart :title="index" :categories="chartDataSegmentation.timestamps" :series-data="item" :key="'controllable-linechart-' + index" />
              </div>
            </div>
          </div>
          <h2 class="font-semibold text-[1.5rem] mt-8 mb-2 ml-1">
            Uncontrollable Parameters
          </h2>
          <div class="grid grid-cols-3 gap-2" v-if="chartDataSegmentation.process?.uncontrollable">
            <div class="['border bg-white shadow-lg rounded px-4 w-full', { 'fullscreen': isFullScreen }]"
              v-for="(item, index) of chartDataSegmentation.process?.uncontrollable" :key="'uncontrollable-' + index">
              <div ref="fullscreenContainer" class="content">
                <LineChart :title="index" :categories="chartDataSegmentation.timestamps" :series-data="item" :key="'uncontrollable-linechart-' + index" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import TreeMenu from './TreeMenu.vue';
import LineChart from './LineChart.vue'

export default {
  name: "ProcessParameterDashboard",
  components: {
    TreeMenu,
    LineChart
  },
  data() {
    return {
      hotMetalQualityKey: 0,
      rawMetalQualityKey: 0,
      chartKey: 0, // Reactive key for forcing chart updates
      apiBaseUrl: process.env.VUE_APP_COMBUST_API_BASE_URL,
      icons: {
        search: require('@/assets/img/search.svg'),
      },
      selectedTab: 0,
      tabs: [
        { name: 'Hot Metal Quality' },
        { name: 'Raw Metal Quality' },
        { name: 'Process' },
      ],
      startDate: '2021-10-10',
      endDate: '2021-10-17',
      loading: false,
      responseData: null,
      isFullScreen: false
    }
  },
  mounted() {
    this.loadDataFromSessionStorage(); // Load data from sessionStorage if available
  },
  methods: {
    loadDataFromSessionStorage() {
      const storedData = sessionStorage.getItem('responseData');
      if (storedData) {
        this.responseData = JSON.parse(storedData);
        console.log('Loaded data from sessionStorage:', this.responseData);
      } else {
        this.searchData(); // Fetch data from API if not available in sessionStorage
      }
    },
    async searchData() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
      this.loading = true;

      try {
        const response = await axios.get(this.apiBaseUrl+'process-data-fetch-result', { params });
        this.responseData = response?.data?.result || null;

        // Store the response data in sessionStorage
        if (this.responseData) {
          sessionStorage.setItem('responseData', JSON.stringify(this.responseData));
          console.log('Stored response data in sessionStorage');
        }
        this.$forceUpdate();

        this.hotMetalQualityKey++;
        this.rawMetalQualityKey++;
        this.processKey++;

        console.log('API Response:', this.responseData);
      } catch (error) {
        console.error('API Error:', error);
      } finally {
        this.loading = false;
      }
    },
    getDateOneYearBack() {
      const today = new Date();
      const oneYearBack = new Date(today.setFullYear(today.getFullYear() - 1));
      return oneYearBack;
    },
    getFormattedDate(date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    updateStartDate(event) {
      const date = new Date(event.target.value);
      this.startDate = this.formatDateToYYYYMMDD(date);
    },

    updateEndDate(event) {
      const date = new Date(event.target.value);
      this.endDate = this.formatDateToYYYYMMDD(date);
    },

    // Format the date for backend or API call: YYYY-MM-DD
    formatDateToYYYYMMDD(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },

    aggregateValues(values) {
      if (values.length === 0) return 0;
      const sum = values.reduce((acc, val) => acc + val, 0);
      return sum / values.length;
    }
  },
  computed: {
    chartDataSegmentation() {
      const timestamps = this.responseData?.timestamps || [];
      const process = this.responseData?.['Process']
      const hotMetalQuality = this.responseData?.['Hot Metal Quality']
      const rawMetalQuality = this.responseData?.['Raw Material Quality']
      return { timestamps, process, hotMetalQuality, rawMetalQuality }
    }
  }
};
</script>

<style>
.button-custom {
  background: #232B60;
}

.body-bg {
  background: #F5F6FA;
  border-left: #D9D9D9 1px solid;
}

.tab-button {
  border: #232B60 1px solid;
  font-size: 1rem;
}

/* Fullscreen mode styling */
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  /* Optional: Set background to white or desired color */
  z-index: 9999;
  overflow: hidden;
}
</style>
