<template>
  <div>
    <div ref="reverseParabolaChart" style="height: 400px; width: 100%;"></div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';

export default {
  name: 'ReverseParabolaChart',
  mounted() {
    this.renderChart();
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    selectedOptionName: {
      type: String,
      required: true
    },
    dataConfigurableParameters: {
      type: Object,
      required: true
    }
  },
  methods: {
    renderChart() {
      let { pdp_var, pdp_output } = this.chartData; // Destructure chartData

      // Check if data exists
      if (!pdp_var || !pdp_output || pdp_var.length === 0 || pdp_output.length === 0) {
        console.error('No chart data available');
        return; // Stop rendering the chart if data is missing
      }

      pdp_var = pdp_var.map(value => parseFloat(value).toFixed(1));  // Format x-axis values (Hot Blast Temperature)
      pdp_output = pdp_output.map(value => parseFloat(value).toFixed(1)); // Format y-axis values (Coke Rate)

      const {name, unit} = this.dataConfigurableParameters[this.selectedOptionName]

      Highcharts.chart(this.$refs.reverseParabolaChart, {
        chart: {
          type: 'spline',
          type: 'spline',
        },
        title: {
          text: '', 
          text: '', 
        },
        xAxis: {
          title: {
            text: this.dataConfigurableParameters[this.selectedOptionName].unit ? `${this.dataConfigurableParameters[this.selectedOptionName].name} ${this.dataConfigurableParameters[this.selectedOptionName].unit}` : this.dataConfigurableParameters[this.selectedOptionName].name,
          },
          categories: pdp_var, // Use formatted pdp_var for X-axis values
          labels: {
            formatter: function() {
              return Number(this.value); // Format X-axis labels to 1 decimal place
            },
          },
          categories: pdp_var, // Use formatted pdp_var for X-axis values
          labels: {
            formatter: function() {
              return Number(this.value); // Format X-axis labels to 1 decimal place
            },
          },
        },
        yAxis: {
          title: {
            text: 'Coke Rate (kg/tHM)',
          },
          max: Math.max(...pdp_output), // Dynamically set the max Y value based on the data
          min: Math.min(...pdp_output), // Dynamically set the min Y value based on the data
          labels: {
            formatter: function() {
              return Number(this.value); // Format Y-axis labels to 1 decimal place
            },
          },
        },
        series: [
          {
            name: 'Coke Rate vs ' + this.dataConfigurableParameters[this.selectedOptionName].name,
            data: pdp_output.map(value => parseFloat(value)), // Use formatted pdp_var for Y-axis values (ensure they are numbers)
            marker: {
              enabled: false,
            },
          },
        ],
        tooltip: {
          pointFormatter: function () {
            return `${parseFloat(this.y).toFixed(1)} kg/tHM`; // Format tooltip to show 1 decimal place
          },
        },
      });
    },
  },
};
</script>
