const minimalDataConfigurableParameters = {
    'blast_temp': {
        name: 'Blast Temperature',
        unit: '°C',
        min: "1140.0",
        max: "1180.0",
        value: 1155,
        current: 100,
        proposed: 0
    },
    'oxygen_enriched_rate': {
        name: 'Oxygen Enriched Rate',
        unit: '%',
        min: "4.0",
        max: "6.5",
        value: 5.3,
        current: 100,
        proposed: 0
    },
    'pci_rate': {
        name: 'PCI Rate',
        unit: 'kg/thm',
        min: "135.0",
        max: "200.0",
        value: 155,
        current: 100,
        proposed: 0
    },
    'steam_addition': {
        name: 'Steam Addition',
        unit: 'kg/thm',
        min: "5.0",
        max: "30.0",
        value: 10,
        current: 100,
        proposed: 0
    },
    'top_gas_pressure_average': {
        name: 'Top Gas Pressure Avg',
        unit: 'gm/cm2',
        min: "135.0",
        max: "155.0",
        value: 140,
        current: 100,
        proposed: 0
    },
    'Pellet%': {
        name: 'Pellet',
        unit: '%',
        min: "0",
        max: "30",
        value: 10,
        current: 100,
        proposed: 0
    },
    'Sinter%': {
        name: 'Sinter',
        unit: '%',
        min: "60",
        max: "80",
        value: 70,
        current: 100,
        proposed: 0
    },
    'Hot_Metal_Temp': {
        name: 'Hot Metal Temperature',
        unit: '°C',
        value: 0,
        current: 100,
        proposed: 0
    },
    'S_0.045_max': {
        name: 'Sulphur content in the hot metal',
        unit: '%',
        value: 0,
        current: 100,
        proposed: 0
    },
    'Si_1.2_max': {
        name: 'Silicon content in the hot metal',
        unit: '%',
        value: 0,
        current: 100,
        proposed: 0
    },
    'Ti': {
        name: 'Titanium content in the hot metal',
        unit: '%',
        value: 0,
        current: 100,
        proposed: 0
    },
    'Bosh Gas Volume (Nm3/min)': {
        name: 'Bosh Gas Volume',
        unit: 'Nm3/min',
        value: 0,
        current: 100,
        proposed: 0
    },
    'RAFT': {
        name: 'Raceway Adiabatic Flame Temperature',
        unit: '°C',
        value: 0,
        current: 100,
        proposed: 0
    },
    'blast_speed': {
        name: 'Blast Speed',
        unit: 'm/sec',
        value: 0,
        current: 100,
        proposed: 0
    },
    'burden_permeability_index': {
        name: 'Burden Permeability Index',
        unit: '',
        value: 0,
        current: 100,
        proposed: 0
    },
    'gas_utilization_rate': {
        name: 'Gas Utilization Rate',
        unit: '%',
        value: 0,
        current: 100,
        proposed: 0
    },
    'heat_loss_MJ': {
        name: 'Heat loss',
        unit: 'MJ',
        value: 0,
        current: 100,
        proposed: 0
    },
    'hot_blast_pressure': {
        name: 'Hot Blast Pressure',
        unit: 'gm/cm2',
        value: 0,
        current: 100,
        proposed: 0
    },
    'top_gas_temperature_average': {
        name: 'Top Gas Temperature Avg',
        unit: '°C',
        value: 0,
        current: 100,
        proposed: 0
    },
    'ASH_x': {
        name: 'Coke ash level',
        unit: '%',
        value: 0,
        current: 100,
        proposed: 0
    },
    'ASH_y': {
        name: 'Coke ash level for purchased coke',
        unit: '%',
        value: 0,
        current: 100,
        proposed: 0
    },
    'Burden Fe %': {
        name: 'Fe content in burden(Sinter + Pellet + Ore)',
        unit: '%',
        value: 0,
        current: 100,
        proposed: 0
    },
    'CSR_x': {
        name: 'Coke Strength after Reaction',
        unit: '%',
        value: 0,
        current: 100,
        proposed: 0
    },
    'Flux Rate': {
        name: 'Flux Rate',
        unit: 'kg/thm',
        value: 0,
        current: 100,
        proposed: 0
    },
    'MPS_x': {
        name: 'Coke mean particle size',
        unit: 'mm',
        value: 0,
        current: 100,
        proposed: 0
    },
    'Micum_Index_M10_x': {
        name: 'M10 Coke Micum index',
        unit: '%',
        value: 0,
        current: 100,
        proposed: 0
    },
    'Micum_Index_M10_y': {
        name: 'M10 Coke Micum index for purchased coke',
        unit: '%',
        value: 0,
        current: 100,
        proposed: 0
    },
    'Moisture_x': {
        name: 'Coke moisture',
        unit: '%',
        value: 0,
        current: 100,
        proposed: 0
    },
    'Moisture_y': {
        name: 'Coke moisture level for purchased coke',
        unit: '%',
        value: 0,
        current: 100,
        proposed: 0
    },
    'Sinter Basicity': {
        name: 'Sinter Basicity',
        unit: '',
        value: 0,
        current: 100,
        proposed: 0
    },
    'Skull': {
        name: 'Skull',
        unit: 'mm',
        value: 0,
        current: 100,
        proposed: 0
    },
    'Slag Volume': {
        name: 'Slag Volume',
        unit: 'kg/thm',
        value: 0,
        current: 100,
        proposed: 0
    },
    'Slag_Basicity': {
        name: 'Slag Basicity',
        unit: '',
        value: 0,
        current: 100,
        proposed: 0
    }
}

export default minimalDataConfigurableParameters