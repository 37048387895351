<template>
  <div class="flex h-screen">
    <TreeMenu></TreeMenu>
    <div class="flex-1 p-4 mb-16 body-bg overflow-y-auto">
      <div class="grid grid-rows-[auto,auto,1fr] p-4 h-screen-auto bg-white shadow-md rounded-md p-4 mx-4">
        <div class="h-16 mb-4">
          <h1 class="font-bold text-[2rem] mt-2">
            Partial Dependence Plot
          </h1>
        </div>
        <div class="flex items-center justify-between mb-4 pl-1 text-sm">
          <div class="flex">
            <button v-for="(tab, index) in tabs" :key="tab.name" @click="selectedTab = index" :class="[
              'rounded tab-button px-4 py-2',
              selectedTab === index
                ? 'blue-custom text-white'
                : 'bg-white text-black hover:blue-custom'
            ]" class="text-sm" :style="{ marginLeft: '-5px' }">
              {{ tab.name }}
            </button>
          </div>
        </div>
        <div class="flex gap-4 py-4 text-sm">
          <div class="flex-1" v-if="selectedTab === 0">
            <div class="overflow-hidden">
              <div class="pb-6 max-w-sm text-left">
                <label for="dropdown" class="block mb-2 font-semibold text-gray-700">Select Parameter:</label>
                <select
                  id="dropdown"
                  v-model="selectedOption2d"
                  @change="handle2DChange"
                  class="w-full p-2 border text-black border-gray-300 focus:outline-none"
                >
                  <option v-for="(option, key) in dropdownOptions" :key="key" :value="key">
                    {{ option.name }} {{ option.unit }}
                  </option>
                </select>
              </div>
              <div v-if="loading" class="w-[20%] mt-7 flex justify-center items-center">
                <div
                  class="spinner-border animate-spin border-4 border-ppo-custom-blue border-t-transparent rounded-full w-6 h-6">
                </div>
              </div>
              <ReverseParabolaChart v-if="!loading && Object.keys(responseData).length" :chartData="responseData" :selectedOptionName="selectedOption2d" :dataConfigurableParameters="dropdownOptions" />
            </div>
          </div>

          <div class="flex-1" v-if="selectedTab === 1">
            <div class="overflow-hidden">
              <div class="pb-6 text-left flex space-x-6">
                <!-- First Dropdown (30%) -->
                <div class="w-[30%]">
                  <label for="dropdown2" class="block mb-2 font-semibold text-gray-700">X-axis:</label>
                  <select
                    id="dropdown2"
                    v-model="selectedOption3dXAxis"
                    @change="handle3DChangeXAxis"
                    class="w-full p-2 border border-gray-300 focus:outline-none"
                  >
                    <option v-for="(option, key) in dropdownOptions" :key="key" :value="key">
                      {{ option.name }} {{ option.unit }}
                    </option>
                  </select>
                </div>

                <!-- Second Dropdown (30%) -->
                <div class="w-[30%]">
                  <label for="dropdown3" class="block mb-2 font-semibold text-gray-700">Y-axis:</label>
                  <select
                    id="dropdown3"
                    v-model="selectedOption3dYAxis"
                    @change="handle3DChangeYAxis"
                    class="w-full p-2 border border-gray-300 focus:outline-none"
                  >
                    <option v-for="(option, key) in dropdownOptions" :key="key" :value="key">
                      {{ option.name }} {{ option.unit }}
                    </option>
                  </select>
                </div>

                <!-- Button (20%) -->
                <div class="w-[20%] mt-7" v-if="!loading">
                  <button @click="plot3dChart" class="px-4 py-2 rounded-sm blue-custom text-white hover:blue-custom">
                    Go
                  </button>
                </div>

                <div v-if="loading" class="w-[20%] mt-7 flex justify-center items-center">
                  <div
                    class="spinner-border animate-spin border-4 border-ppo-custom-blue border-t-transparent rounded-full w-6 h-6">
                  </div>
                </div>
                
                <!-- Empty space (remaining 20%) -->
                <div class="w-[20%]"></div>
              </div>
              <ReverseParabola3D v-if="!loading && Object.keys(responseData).length" :chartData="responseData" :selectedXAxis="selectedOption3dXAxis" :selectedYAxis="selectedOption3dYAxis" :dataConfigurableParameters="dropdownOptions"  />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import TreeMenu from './TreeMenu.vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import ReverseParabolaChart from "@/components/ProcessParameterOptimizer/ReverseParabolaChart.vue";
import ReverseParabola3D from "@/components/ProcessParameterOptimizer/ReverseParabola3D.vue";
import dataConfigurableParameters from '@/components/ProcessParameterOptimizer/dataConfigurableParameters.js';
import * as XLSX from 'xlsx';

export default {
  name: "ProcessParameterSimulator",
  components: {
    TreeMenu,
    Datepicker,
    ReverseParabolaChart,
    ReverseParabola3D
  },
  data() {
    return {
      apiBaseUrl: process.env.VUE_APP_COMBUST_API_BASE_URL,
      selectedTab: 0, // Track the currently selected tab
      tabs: [
        { name: '2D Plot' },
        { name: '3D Plot' }
      ],
      selectedOption2d: 'blast_temp',
      selectedOption3dXAxis: 'blast_temp',
      selectedOption3dYAxis: 'oxygen_enriched_rate',
      dropdownOptions: { ...dataConfigurableParameters.controllable, ...dataConfigurableParameters.uncontrollable },
      loading: false,
      responseData: {}
    }
  },
  methods: {
    async handle2DChange() {
      console.log("Selected option:", this.selectedOption2d);
      
      const plotPayload = {
        param_to_plot: this.selectedOption2d,
        plot_type: '2D'
      };

      await this.generatePlot(plotPayload)
    },
    handle3DChangeXAxis() {
      console.log("Selected option:", this.selectedOption3dXAxis);
    },
    handle3DChangeYAxis() {
      console.log("Selected option:", this.selectedOption3dYAxis);
    },
    async plot3dChart() {
      console.log("Selected option:", this.selectedOption3dXAxis);
      console.log("Selected option:", this.selectedOption3dYAxis);

      const plotPayload = {
        param_to_plot: [this.selectedOption3dXAxis, this.selectedOption3dYAxis],
        plot_type: '3D'
      };

      await this.generatePlot(plotPayload)
    },
    async generatePlot(plotPayload) {
      this.loading = true;
      try {
        const response = await axios.post(this.apiBaseUrl+'plot-partial-dependence', plotPayload);
        this.responseData = response?.data?.result || null;
        console.log('API Response:', response.data);
      } catch (error) {
        console.error('API Error:', error.response || error);
        alert('Failed to submit estimate.');
      } finally {
        this.loading = false;
      }
    },
    // async generatePlot(plotPayload) {
    //   this.loading = true;

    //   try {
    //     const response = await fetch('/Data for Impact Charts.xlsx');
    //     const arrayBuffer = await response.arrayBuffer();

    //     const workbook = XLSX.read(arrayBuffer, { type: 'array' });

    //     const sheetName = workbook.SheetNames[6];
    //     const worksheet = workbook.Sheets[plotPayload.param_to_plot];

    //     const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    //     this.columnBData = data.map(row => row[0]).slice(5).filter(item => item !== undefined);
    //     this.columnCData = data.map(row => row[1]).slice(5).filter(item => item !== undefined);
    //     this.responseData = { pdp_output: this.columnBData, pdp_var: this.columnCData }
    //   } catch (error) {
    //     console.error("Error reading Excel file:", error);
    //   } finally {
    //     this.loading = false;
    //   }
    // }
  },
  mounted() {
    this.handle2DChange()
  }
};
</script>

<style>
.blue-custom {
  background: #232B60;
}

.body-bg {
  background: #F5F6FA;
  border-left: #D9D9D9 1px solid;
}

.tab-button {
  border: #232B60 1px solid;
  font-size: 1rem;
}

.dp__input_icon_pad {
  height: 33px;
}
</style>