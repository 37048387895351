<template>
  <div class="flex h-screen">
    <TreeMenu></TreeMenu>
    <div class="flex-1 p-4 mb-16 body-bg overflow-y-auto">
      <div class="grid grid-rows-[auto,auto,1fr] p-4 h-screen-auto bg-white shadow-md rounded-md p-4 mx-4">
        <div class="h-16 mb-4">
          <h1 class="font-bold text-[2rem] mt-2">Coal Beneficiation Digital Twin - Output</h1>
        </div>
        <div class="flex items-center justify-between mb-4 pl-1 text-sm" v-if="machineData">
          <div class="ml-8 bg-white rounded-md overflow-hidden w-[90%] pb-2 relative image-container" :style="{
            backgroundImage: 'url(' + icons.machineFlow + ')',
            backgroundSize: 'contain',      // Ensures image fits without being cut off
            backgroundPosition: 'center',   // Centers the image within the container
            backgroundRepeat: 'no-repeat',  // Prevents the image from repeating
            height: '100vh', //+ 'px'       // Dynamically set the height based on the image aspect ratio
            position: 'relative'
          }">
            <div id="magnetic_separator"
               :class="['value-transition', isConcentrateChanged ? 'fade-in' : 'fade-in-active']"
               class="absolute md:bottom-[82.5%] md:left-[34%] text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData?.magnetic_separator?.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.magnetic_separator?.ash) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.magnetic_separator?.slurry_flow) }}
              </div>
            </div>
            <div id="classification_screen"
               :class="['value-transition', isConcentrateChanged ? 'fade-in' : 'fade-in-active']"
               class="absolute md:bottom-[72.5%] md:left-[17.5%] text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.classification_screen?.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.classification_screen?.ash) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.classification_screen?.slurry_flow) }}
              </div>
            </div>
            <div id="heavy_medium_cyclone"
               :class="['value-transition', isConcentrateChanged ? 'fade-in' : 'fade-in-active']"
               class="absolute md:bottom-[49.5%] md:left-[25.5%] text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.heavy_medium_cyclone?.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.heavy_medium_cyclone?.ash) }}
              </div>
              <div class="mb-2">
                {{ Math.round(machineData.heavy_medium_cyclone?.slurry_flow) }}
              </div>
            </div>
            <div id="refuse_screen1"
               :class="['value-transition', isConcentrateChanged ? 'fade-in' : 'fade-in-active']"
               class="absolute md:bottom-[26%] md:left-[13%] text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.refuse_screen1?.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.refuse_screen1?.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.refuse_screen1?.slurry_flow) }}
              </div>
            </div>
            <div id="clean_coal_screen1"
               :class="['value-transition', isConcentrateChanged ? 'fade-in' : 'fade-in-active']"
               class="absolute md:bottom-[29.5%] md:left-[35.5%] text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.clean_coal_screen1?.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.clean_coal_screen1?.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.clean_coal_screen1?.slurry_flow) }}
              </div>
            </div>
            <div id="centrifuge1"
               :class="['value-transition', isConcentrateChanged ? 'fade-in' : 'fade-in-active']"
               class="absolute md:bottom-[7%] md:left-[28%] text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.centrifuge1?.load) }}
              </div>
              <div class="mb-2">
                {{ Math.round(machineData.centrifuge1?.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.centrifuge1?.slurry_flow) }}
              </div>
            </div>
            <div id="centrifuge2"
               :class="['value-transition', isConcentrateChanged ? 'fade-in' : 'fade-in-active']"
               class="absolute md:bottom-[2%] md:left-[53%] text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.centrifuge2?.load) }}
              </div>
              <div class="mb-2">
                {{ Math.round(machineData.centrifuge2?.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.centrifuge2?.slurry_flow) }}
              </div>
            </div>
            <div id="refuse_screen2"
               :class="['value-transition', isConcentrateChanged ? 'fade-in' : 'fade-in-active']"
               class="absolute md:bottom-[24%] md:left-[58.5%] text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.refuse_screen2?.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.refuse_screen2?.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.refuse_screen2?.slurry_flow) }}
              </div>
            </div>
            <div id="clean_coal_screen2"
               :class="['value-transition', isConcentrateChanged ? 'fade-in' : 'fade-in-active']"
               class="absolute md:bottom-[24.5%] md:left-[74%] text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.clean_coal_screen2?.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.clean_coal_screen2?.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.clean_coal_screen2?.slurry_flow) }}
              </div>
            </div>
            <div id="hydrosizer"
               :class="['value-transition', isConcentrateChanged ? 'fade-in' : 'fade-in-active']"
               class="absolute md:bottom-[46.5%] md:left-[59%] text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.hydrosizer?.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.hydrosizer?.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.hydrosizer?.slurry_flow) }}
              </div>
            </div>
            <div id="hydroclone_1_stage"
               :class="['value-transition', isConcentrateChanged ? 'fade-in' : 'fade-in-active']"
               class="absolute md:bottom-[73.5%] md:left-[61%] text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.hydroclone_1_stage?.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.hydroclone_1_stage?.ash) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.hydroclone_1_stage?.slurry_flow) }}
              </div>
            </div>
            <div id="decanter"
               :class="['value-transition', isConcentrateChanged ? 'fade-in' : 'fade-in-active']"
               class="absolute md:bottom-[23%] md:left-[91.5%] text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.decanter?.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.decanter?.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.decanter?.slurry_flow) }}
              </div>
            </div>
            <div id="filter_flotation"
               :class="['value-transition', isConcentrateChanged ? 'fade-in' : 'fade-in-active']"
               class="absolute md:bottom-[45.5%] md:left-[96.5%] text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.filter_flotation?.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.filter_flotation?.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.filter_flotation?.slurry_flow) }}
              </div>
            </div>
            <div id="main_flotation"
               :class="['value-transition', isConcentrateChanged ? 'fade-in' : 'fade-in-active']"
               class="absolute md:bottom-[59%] md:left-[80%] text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.main_flotation?.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.main_flotation?.ash) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.main_flotation?.slurry_flow) }}
              </div>
            </div>
            <div id="hydroclone_2_stage"
               :class="['value-transition', isConcentrateChanged ? 'fade-in' : 'fade-in-active']"
               class="absolute md:bottom-[82%] md:left-[86%] text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.hydroclone_2_stage?.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.hydroclone_2_stage?.ash) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.hydroclone_2_stage?.slurry_flow) }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between mb-4 pl-1 text-sm" v-else>
          <p>Output will be available after filling the inputs in Control Central Input. 
          <router-link
            to="/machine-flow/input"
            class="rounded text-blue-custom underline"
          >Click here to go to Control Central Input</router-link></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TreeMenu from './TreeMenu.vue';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'machineFlowOutput',
  components: { TreeMenu },
  data() {
    return {
      value1: '',
      value2: '',
      // ash: 0,
      icons: {
        machineFlow: require('@/assets/img/machine_flow.png'), // Your image path
      },
      imageHeight: 0,
      isConcentrateChanged: false,
      isAshChanged: false,
      loading: false,
    };
  },
  computed: {
    ...mapState('machineFlow', ['machineData', 'ash']),
  },
  methods: {
    ...mapMutations('machineFlow', ['SET_MACHINE_DATA', 'SET_ASH']),
  },
};
</script>


<style>

.body-bg {
  background: #F5F6FA;
  border-left: #D9D9D9 1px solid;
}

.tab-button {
  border: #232B60 1px solid;
  font-size: 1rem;
}

.dp__input_icon_pad {
  height: 33px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.value-transition {
  transition: transform 1s ease, opacity 1s ease;
}

.fade-in {
  opacity: 0;
}

.fade-in-active {
  opacity: 1;
  transform: scale(1);
}

/* Default CSS: Applied to all resolutions */
.image-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Maintain 16:9 aspect ratio */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.image-container div[id^="magnetic_separator"],
.image-container div[id^="classification_screen"],
.image-container div[id^="heavy_medium_cyclone"],
.image-container div[id^="refuse_screen1"],
.image-container div[id^="clean_coal_screen1"],
.image-container div[id^="centrifuge1"],
.image-container div[id^="centrifuge2"],
.image-container div[id^="refuse_screen2"],
.image-container div[id^="clean_coal_screen2"],
.image-container div[id^="hydrosizer"],
.image-container div[id^="hydroclone_1_stage"],
.image-container div[id^="decanter"],
.image-container div[id^="filter_flotation"],
.image-container div[id^="main_flotation"],
.image-container div[id^="hydroclone_2_stage"] {
  position: absolute;
  font-size: calc(0.5vw + 0.5vh); /* Dynamic scaling */
  line-height: 1.3;
  text-align: center;
  transform: translate(-30%, 5%);
  transition: all 0.3s ease; /* Smooth transitions */
}

/* Target viewport 1280x665 */
@media (min-width: 1280px) and (max-width: 1280px) and (min-height: 665px) and (max-height: 665px) {
  .image-container {
    width: 100%; /* Full width for scaling */
    height: 100%; /* Adjust to viewport height */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .image-container div[id^="magnetic_separator"],
  .image-container div[id^="classification_screen"],
  .image-container div[id^="heavy_medium_cyclone"],
  .image-container div[id^="refuse_screen1"],
  .image-container div[id^="clean_coal_screen1"],
  .image-container div[id^="centrifuge1"],
  .image-container div[id^="centrifuge2"],
  .image-container div[id^="refuse_screen2"],
  .image-container div[id^="clean_coal_screen2"],
  .image-container div[id^="hydrosizer"],
  .image-container div[id^="hydroclone_1_stage"],
  .image-container div[id^="decanter"],
  .image-container div[id^="filter_flotation"],
  .image-container div[id^="main_flotation"],
  .image-container div[id^="hydroclone_2_stage"] {
    font-size: 0.9rem; /* Font size adjusted for clarity */
    line-height: 1.2;
  }

  #magnetic_separator {
    bottom: 82%; /* Adjusted placement */
    left: 34%;
  }
  #classification_screen {
    bottom: 72%;
    left: 18%;
  }
  #heavy_medium_cyclone {
    bottom: 50%;
    left: 25%;
  }
  #refuse_screen1 {
    bottom: 26%;
    left: 13%;
  }
  #clean_coal_screen1 {
    bottom: 30%;
    left: 35%;
  }
  #centrifuge1 {
    bottom: 7%;
    left: 28%;
  }
  #centrifuge2 {
    bottom: 2%;
    left: 53%;
  }
  #refuse_screen2 {
    bottom: 24%;
    left: 58%;
  }
  #clean_coal_screen2 {
    bottom: 24%;
    left: 74%;
  }
  #hydrosizer {
    bottom: 46%;
    left: 59%;
  }
  #hydroclone_1_stage {
    bottom: 73%;
    left: 61%;
  }
  #decanter {
    bottom: 23%;
    left: 91%;
  }
  #filter_flotation {
    bottom: 45%;
    left: 96%;
  }
  #main_flotation {
    bottom: 59%;
    left: 80%;
  }
  #hydroclone_2_stage {
    bottom: 82%;
    left: 86%;
  }
}

/* General scaling for other resolutions */
@media (max-width: 1280px) and (max-height: 800px) {
  .image-container div {
    font-size: calc(0.8vw + 0.8vh);
    transform: translate(-50%, -50%);
  }
}

/* Smaller screens (e.g., tablets) */
@media (max-width: 1024px) {
  .image-container {
    padding-top: 75%; /* Adjust aspect ratio */
  }
  .image-container div {
    font-size: calc(1vw + 1vh);
  }
}
</style>
