<template>
  <div class="h-full overflow-auto">
    <div class="px-24 py-8 w-full text-left">
      <h2 class="text-2xl font-bold mr-4">
        Optimize pricing strategies and gain market insights with our bespoke Raffia Pricing Engine
      </h2>
    </div>
    <VideoPlayer :videoPath="raffiaVideo" />
  </div>
</template>

<script>
import VideoPlayer from '@/components/CommodityPathfinder/VideoPlayer.vue';
import raffiaVideo from "@/assets/img/Raffia_edited.webm";

export default {
  name: 'Raffia',
  components: {
    VideoPlayer
  },
  data() {
    return {
      raffiaVideo
    }
  },
};
</script>
