<template>
  <div class="flex flex-col overflow-y-auto pb-3 pt-3 shadow max-h-screen">
    <div class="flex justify-between px-10">
      <button :class="`${activeTab === tabs.INPUTS ? 'active-tab' : 'non-active-tab'} tab-button`">
        <div class="flex items-center justify-center gap-1 text-xl leading-7">
          <img :src="`${activeTab === tabs.INPUTS ? inputActiveIcon : inputInactiveIcon}`" alt="logo"
            class="tab-item-icon" />
          Inputs
        </div>
      </button>
      <button :class="`${activeTab === tabs.RESULTS ? 'active-tab' : 'non-active-tab'} tab-button`">
        <div class="flex items-center justify-center gap-1 text-xl leading-7">
          <img :src="`${activeTab === tabs.RESULTS ? resultsActiveIcon : resultsInactiveIcon}`" alt="logo"
            class="tab-item-icon" />
          View Results
        </div>
      </button>
    </div>
    <hr class="inputs-tabs-divider" />
    <div v-if="activeTab === tabs.INPUTS">
      <SensaAnalyticsInput :analyze-sentiments-handler="analyzeSentiments" @result-received="handleResultReceived" />
    </div>
    <div v-if="activeTab === tabs.RESULTS">
      <div class="back-button font-bold mt-4 text-sm text-left mx-10 purple-text">
        <button @click="handleTempResultsBack"><u>&lt;&lt; Previous Page</u></button>
      </div>
      <Output />
    </div>
  </div>
</template>

<script>
import { reactive, nextTick } from 'vue';
import SensaAnalyticsInput from "@/views/SensaAnalyticsInput.vue";
import Output from "@/components/SensaAnalysis/Output/Output.vue"
import inputActiveIcon from "../assets/img/in_active.svg";
import inputInactiveIcon from "../assets/img/in_inactive.svg";
import resultsActiveIcon from "../assets/img/eye_active.svg";
import resultsInactiveIcon from "../assets/img/eye_inactive.svg";

const TABS = {
  INPUTS: "INPUTS",
  RESULTS: "RESULTS"
}
export default {
  name: "SensaAnalyticsView",
  data() {
    return {
      tabs: TABS,
      activeTab: TABS.INPUTS,
      inputActiveIcon,
      inputInactiveIcon,
      resultsActiveIcon,
      resultsInactiveIcon,
      analysisResult: reactive({}),
    };
  },
  components: {
    SensaAnalyticsInput,
    Output,
  },
  methods: {
    analyzeSentiments(result) {
      this.analysisResult = result; // Store the result
      this.activeTab = TABS.RESULTS; // Switch to Results tab
    },
    async handleResultReceived(result) {
      const testResult = { key: 'value' };
      this.analysisResult = testResult; // Assign hardcoded result
      await nextTick(); // Wait for the DOM to update
      this.activeTab = this.tabs.RESULTS; // Switch to the Results tab
    },
    handleTempResultsBack() {
      this.activeTab = TABS.INPUTS;
    },
  },
};
</script>

<style scoped>
.active-tab {
  background-color: #390b67;
  color: #ffffff;
}

.non-active-tab {
  background-color: #d9d9d9;
  color: #333;
}

.tab-button {
  border-width: 1px;
  width: 50%;
  margin-left: 8px;
  margin-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 6px;
  box-shadow: 0 4px 1px rgb(81 78 78 / 40%);
}

.tab-item-icon {
  height: 30px;
  width: 30px
}

.inputs-tabs-divider {
  margin-top: 12px;
  margin-bottom: 0;
  border: 1px solid #9f9f9f
}

.back-button {
  text-decoration: underline;
  /* Adding an underscore to the text */
}
</style>
