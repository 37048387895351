<template>
  <div :class="`${isActive ? 'active-box': 'inactive-box'} p-4 normal-input-text`">
    <div :class="`section-number text-white p-1 ${isActive ? 'active-section-number': 'inactive-section-number'}`">
      3
    </div>
    <div class="section-header">
      Input Review
    </div>
    <div class="relative flex justify-between items-center w-full h-8 bg-gray-200 rounded-full p-1 my-2">
      <button @click="toggleReview('single')" :class="[
        'py-3 flex-1 h-full flex items-center justify-center rounded-full text-sm font-medium transition-all',
        selectedReview === 'single' ? 'kearney-purple-button text-white' : 'text-gray-700',
      ]">
        Single Review
      </button>
      <button @click="toggleReview('bulk')" :class="[
        'py-3 flex-1 h-full flex items-center justify-center rounded-full text-sm font-medium transition-all',
        selectedReview === 'bulk' ? 'kearney-purple-button text-white' : 'text-gray-700',
      ]">
        Bulk Reviews
      </button>
    </div>
    <div v-if="selectedReview === 'bulk'">
      <div class="font-extrabold brand-text">Important</div>
      <div class="meta-info-area-white brand-text upload-important-section">
        <ol class="upload-important-section-terms h-24 overflow-auto">
          <li class="ulliitem">Your review dataset has exactly 3 columns.</li>
          <li class="ulliitem">Your first column is not named.</li>
          <li class="ulliitem">Your second column is named "Review".</li>
          <li class="ulliitem">Your third column is named "Rating".</li>
          <li class="ulliitem">If your data doesn't have rating, still make the "Rating" column and keep it empty.</li>
          <li class="ulliitem">Your first column has numbers sequenced as: 0, 1, 2, 3, ..</li>
        </ol>
      </div>
      <div>
        On following all the above steps, your excel should look like the following table:
      </div>
      <div>
        <table class="sample-table">
          <tr class="table-header">
            <th></th>
            <th>Review</th>
            <th>Rating</th>
          </tr>
          <tr>
            <td>0</td>
            <td>This is a sample review</td>
            <td>5</td>
          </tr>
          <tr>
            <td>1</td>
            <td>This is a sample review</td>
            <td>4</td>
          </tr>
        </table>
        <div class="flex leading-7 items-center gap-1 ml-5">
          <img :src="DownloadIcon"
               class="download-icon"/>
          <a :href="SampleSensaInputXlsx"
             class="underline normal-input-text cursor-pointer"
             download="sensa_sample.xlsx">
            Download sample excel file
          </a>
        </div>
      </div>

      <div class="mt-3 mb-3">
        Upload your excel file with column headers 'Review' and 'Rating'
      </div>
      <div>
        <div class="drag-drop-container p-3 w-full flex items-center text-center flex-col"
             @dragleave="dragleave"
             @dragover="dragover"
             @drop="drop">
          <div class="drag-drop-container-text font-extrabold w-full ml-auto">
            Drag and drop file here
          </div>

          <div class="flex justify-end">
            <input id="assetsFieldHandle"
                   ref="file"
                   :accept="`${ACCEPTABLE_INPUT_DOC_FORMATS.join(',')}`"
                   class="w-px h-px opacity-0 overflow-hidden absolute"
                   name="fields[assetsFieldHandle][]"
                   type="file"
                   @change="onChange">
          </div>
          <div>
            <img :src="UploadIcon"
                 alt="Upload Logo" class="upload-icon"/>
          </div>
          <div class="drag-drop-container-text">
            <div>
              Limit 200MB per file
            </div>
            <div>
              .xlsx format only
            </div>
          </div>
          <div :class="`${!isActive? 'view-hidden': ''} w-full flex justify-end`">
            <label class="input-button w-28 drag-drop-container-label-button border mt-2 px-2 text-white"
                   for="assetsFieldHandle">
              Browse file
            </label>
          </div>
        </div>
      </div>
      <div>
        <div :class="`${!selectedFile? 'view-hidden': ''} mt-3 mb-3`">
          <div class="selected-file-text font-bold">
            Selected file:
          </div>
          <div class="selected-file-container p-1">
            {{ selectedFile?.name }}
            <div class="cross-div-button"
                 @click="remove">
              X
            </div>
          </div>
        </div>

      </div>
      <div class="flex justify-end">
        <button
            :class="`input-button ${isActive ? 'button-enabled' : 'button-disabled'}`"
            :disabled="!isActive"
            @click="handleBackFromSection">
          Back
        </button>
      </div>
    </div>
    <div v-else>
      <div>
        <label for="review" class="block text-sm font-medium text-gray-700">Review</label>
        <textarea id="review" v-model="singleReview" @input="$emit('single-review-updated', singleReview)" rows="16"
          class="mt-1 block w-full h-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500 resize-none"></textarea>
      </div>
      <div class="flex justify-end">
        <button
            :class="`input-button ${isActive ? 'button-enabled' : 'button-disabled'}`"
            :disabled="!isActive"
            @click="handleBackFromSection">
          Back
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import UploadIcon from "../../../assets/img/cloud-upload.svg";
import DownloadIcon from "../../../assets/img/download.svg";
import SampleSensaInputXlsx from "./docs/sample_sensa_input.jpg";

const ACCEPTABLE_INPUT_DOC_FORMATS = ['.xlsx']; // Provide acceptable extensions in lower case ONLY.

export default {
  name: "SensaAnalysisUploadExcelSection",
  delimiters: ['${', '}'],
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    keyAspects: {
      type: String,
      required: true,
    },
    handleNext: {
      type: Function,
      required: true,
    },
    handleBack: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      selectedFile: null,
      UploadIcon,
      DownloadIcon,
      SampleSensaInputXlsx,
      ACCEPTABLE_INPUT_DOC_FORMATS,
      selectedReview: 'single', // Default selected option
      singleReview: ''
    }
  },
  methods: {
    toggleReview(type) {
      this.selectedReview = type;
      this.$emit('review-type-changed', type);
    },
    onChange() {
      if (!this.isActive) return;
      this.selectedFile = [...this.$refs.file.files];

      let selectedFile = null;
      try {
        const extensionMatchPattern = /.*(\.[\w]*)/;
        const extension = extensionMatchPattern.exec(this.selectedFile[0].name)[1];
        if (ACCEPTABLE_INPUT_DOC_FORMATS.includes(extension.toLowerCase())) {
          selectedFile = this.selectedFile[0]
        }
      } catch (e) {
      }

      this.selectedFile = selectedFile;
      this.handleNext(this.selectedFile);
    },
    remove() {
      if (!this.isActive) return;
      this.selectedFile = null;
      this.$refs.file.value = "";
      this.handleNext(this.selectedFile);
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave(event) {
      // Clean up
    },
    drop(event) {
      event.preventDefault();
      if (event.dataTransfer.files.length === 1) {
        this.$refs.file.files = event.dataTransfer.files;
        this.onChange(); // Trigger the onChange event manually
      }
    },
    handleBackFromSection() {
      this.selectedFile = null;
      if (this.selectedReview === 'bulk') {
        this.$refs.file.value = "";
      }
      this.handleBack();
    }
  },
  mounted() {
    this.toggleReview('single')
  }
};
</script>

<style scoped>
.drag-drop-container {
  background-color: #fbf7ff;
  border: 3px dashed #af7deb
}

.drag-drop-container-text {
  color: #af7deb;
}

.selected-file-text {
  color: #7e2fdc
}

.drag-drop-container-label-button {
  background-color: #af7deb;
  cursor: pointer
}

.selected-file-container {
  background-color: #f7f7f7;
  width: 100%;
  box-shadow: 0 5px 16px rgb(81 78 78 / 10%);
  display: flex;
  justify-content: space-between;
  border-radius: 24px;
  color: #7e2fdc;
  padding-left: 10px;
  font-weight: normal;
}

.sample-table {
  width: 90%;
  margin: 0 auto;
  background-color: #FFFFFF;
}

.sample-table, th, td {
  border: 1px solid;
}

.table-header {
  background-color: #f4f4f4;
  text-align: center;
  border: 1px solid black
}

.sample-table td:nth-child(1) {
  text-align: center
}

.sample-table td:nth-child(2) {
  padding-left: 3px;
}

.sample-table td:nth-child(3) {
  text-align: center
}

.upload-icon {
  width: 60px;
  height: 60px;
}

.download-icon {
  width: 18px;
  height: 18px;
}

.upload-important-section {
  padding-left: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 3px;
}

.upload-important-section-terms {
  font-size: 11px;
  padding-left: 18px
}

.cross-div-button {
  border-radius: 50%;
  height: 16px;
  width: 16px;
  background-color: #acacac;
  color: white;
  text-align: center;
  cursor: pointer;
  line-height: 15px;
  margin-top: 2px;
  margin-right: 3px;
}

</style>
