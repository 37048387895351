<template>
  <div class="w-full mb-4">
    <MachineFlowOutput />
  </div>
</template>

<script>
import MachineFlowOutput from "@/components/MachineFlow/machineFlowOutput.vue";

export default {
  name: "ProcessParameterMachineFlowOutputView",
  components: {
    MachineFlowOutput
  }
};
</script>
