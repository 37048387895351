<template>
  <div :class="`${isActive ? 'active-box' : 'inactive-box'} p-4 normal-input-text`">
    <Loader :loading="loading" />
    <div :class="`section-number text-white p-1 ${isActive ? 'active-section-number' : 'inactive-section-number'}`">
      2
    </div>
    <div class="section-header">
      Provide context and parameters
    </div>
    <div>
      Please provide the context of the review and the specific insights you are seeking to tailor the sentiment
      analysis to your needs
    </div>

    <div class="text-xs purple-text flex items-center space-x-1 mt-2 mb-2">
      <span>Fetch Context </span>
    </div>
    <select :disabled="!isActive" v-model="selectedCommodity" @change="updateContextValue"
      class="bg-gray-200 p-1 rounded-md border border-gray-400">
      <option value="">Select Commodity</option>
      <option value="steel">Steel</option>
      <option value="crude">Brent</option>
    </select>

    <textarea v-model="providedContext" :disabled="!isActive" class="input-text-area h-44" />
    <span class="context-input-words-count">{{ providedContextWordCount }}/{{ MAX_WORDS_FOR_CONTEXT }} words</span>
    <div class="flex justify-end">
      <button v-if="!keyAspectPerSentiment && isActive"
        :class="`input-button mx-1 ${isActive ? 'button-enabled' : 'button-disabled'}`" :disabled="!isActive"
        @click="handleBack">
        Back
      </button>
      <button
        :class="`input-button ${providedContext.trim().length > 0 && isActive ? 'button-enabled' : 'button-disabled'}`"
        :disabled="!isActive" @click="handleContextProvided">
        Next
      </button>
    </div>
    <div :class="`${keyAspectPerSentiment || !isActive ? '' : 'view-hidden'}`">
      <div class="mt-3 mb-3">
        List the key aspects you want to analyze in the reviews to focus the sentiment analysis on relevant themes.
      </div>
      <div :class="`mt-3`">
        <div class="p-1 brand-text">
          <div v-if="keyAspectPerSentiment" class="font-bold">
            Suggested key aspects as per context:
          </div>
          <div>{{ keyAspectPerSentiment }}</div>
          <button @click="copyText" class="text-xs font-semibold purple-text flex items-center space-x-1 mt-2">
            <img height="16" width="16" :src="CopyIcon" alt="Copy" class="copy-icon" />
            <span>Copy Aspects</span>
          </button>
        </div>
      </div>
      <textarea v-model="providedParameters" :disabled="!(isActive && keyAspectPerSentiment)"
        :placeholder="keyAspectPerSentiment" class="input-text-area h-20" />
      <div class="flex justify-end">
        <button :class="`input-button mx-1 ${isActive ? 'button-enabled' : 'button-disabled'}`" :disabled="!isActive"
          @click="handleBack">
          Back
        </button>
        <button
          :class="`input-button ${providedParameters.trim().length > 0 && isActive ? 'button-enabled' : 'button-disabled'}`"
          :disabled="!isActive" @click="handleParametersProvided">
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import Papa from "papaparse";
import axios from "axios";
import CopyIcon from "@/assets/img/copy-icon.svg";

const ASPECT_SUGGESTION_ENDPOINT = `https://powerpulseapicombustdev.kearney.com/suggest_aspects`;

const MAX_WORDS_FOR_CONTEXT = 500;

export default {
  name: "SensaAnalysisContextSection",
  components: { Loader },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    apiKey: {
      type: String,
      required: true,
    },
    handleNext: {
      type: Function,
      required: true,
    },
    handleBack: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      providedContext: "",
      providedParameters: "",
      loading: false,
      keyAspectPerSentiment: "",
      MAX_WORDS_FOR_CONTEXT,
      CopyIcon,
      commodities: {
        steel: `I am a Sales and Distribution Lead for Steel Authority of India Limited. Analyze the following customer reviews related to our distributors and evaluate each review on the following parameters:
Product Availability and Knowledge: Customers may mention about availability of stock, variety, quality, and product knowledge by the distributor. Check for sentences in reviews that show that the distributor has sufficient stock and variety of the steel products.
Pricing Competitiveness: Customers may mention about pricing in ways on how they perceive it at current distributor compared to market standards. If there are mentions about hidden costs or unexpected charges, treat them negatively.
Customer Service: Customers may comment about how responsive and helpful the distributor in was resolving issues that arose during sales or post-sales. They may also mention the communication quality and speed, where a quick response is appreciated and rude or ignoring behavior can be taken as negative sentiment.
Delivery & Logistics: Customers may mention in review about delivery time and condition of the goods delivered. Any mention of delays of damages in delivery or excessive charging may be interpreted as unfavorable action. While on-time delivery, good quality products or free of charge delivery are considered by customers as positives.
Relationship Management: In reviews, the distributor may be mentioned about their long-term relationship management with clients. Any mention of professional behavior, trustworthiness and extended support over expected behavior may be taken as positive by the users.
For each review:
Provide a score (1 to 10) for each parameter. Where give 1 for a worst level of performance and 10 for highest standard of performance in any of the parameters.
If any parameter is missing from the review allocate it a value of 0.`,
        crude: `I am the marketing manager for IOCL. Help me learn how our retail outlets are doing on the following parameters:
Housekeeping: Customers may talk about maintenance of user convenience facilities such as cleanliness and availability of washrooms and toilets/restrooms, availability of drinking water. Any other technical facilities like an air pump or fuel pump are not included in housekeeping. If any other service is unavailable, don’t consider it under this aspect.
Location: Customers may talk about the location of the petrol pump, its ease of access and its entrance. They may also talk about its visibility and the area it has been built in. A crowded petrol pump is not a location issue, so don’t consider crowds in this aspect. If somebody says “it’s a good place/nice place” don’t consider it as a location parameter.
Quality: Customers talk about fuel mixing, adulteration, and quality of fuel. Customers may also talk indirectly about this such as “good performance of fuel”, “good fuel”.
Quantity: Customers talk about the fuel quantity they receive. They may talk about the measurements, the amount, any cheating, or fraud in quantity. They may also detail any scam going on. Customers can detail fraudulent activities as an issue. Only fuel frauds should be considered as quantity frauds.
Service: Customers may talk about service directly or they may talk about staff’s politeness, language, and attentiveness. They may also express this by their waiting time or queues formed, the times the outlet is busy or crowded. Further, free air is supposed to be available at all retail outlets, customers may talk about this also under service. So, charging for air is extremely poor service. Customers expect tire repairs as a service at retail outlets. Payment issues are also service issues especially around non-acceptance of cards, dysfunctional POS, etc. Security and first-aid also come under service. Discussion around lubricant oils also comes under service. If a UPI payment fails or card isn’t accepted, it is poor service. If the outlet is crowded, it is poor service. It someone is charged for air; it is poor service. If outlet is not opened on time, it is poor service. If a machinery is not working, it is poor service. For every review, only score based on the parameter present in the review.
If the performance on an aspect is low, give a score greater than equal to 1.
If the performance on an aspect is high, give a score less than or equal to 5.
If the aspect is missing in the review, give a score of 0.`,
      },
      selectedCommodity: "",
    }
  },
  methods: {
    copyText() {
      this.providedParameters = this.keyAspectPerSentiment;
    },
    updateContextValue() {
      this.providedContext = this.commodities[this.selectedCommodity] ? this.commodities[this.selectedCommodity] : '';
    },
    async handleContextProvided() {
      if (this.providedContext.trim().length > 0) {
        this.loading = true;

        const resp = await axios
          .post(ASPECT_SUGGESTION_ENDPOINT, {
            "context": this.providedContext.trim(),
            "api_key": this.apiKey
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            this.loading = false;
          });
        this.keyAspectPerSentiment = resp?.data?.aspects ?? "ERROR";
        const regex = /(\d+)\.\s([^\n]+)/g;
        let match;
        const parameters = [];

        while ((match = regex.exec(this.keyAspectPerSentiment)) !== null) {
          parameters.push(match[2].trim());
        }

        this.providedParameters = parameters.join(', ');
        this.loading = false;
      }
    },
    handleParametersProvided() {
      if (this.providedParameters.trim().length > 0) {
        this.handleNext(this.providedContext.trim(), this.providedParameters.trim());
      }
    }
  },
  computed: {
    providedContextWordCount() {
      const trimmedProvidedContext = this.providedContext.trim();
      if (trimmedProvidedContext.length > 0) {
        const wordsInTrimmedProvidedContext = trimmedProvidedContext.split(" ")
        const wordsCount = wordsInTrimmedProvidedContext.length;
        if (wordsCount > MAX_WORDS_FOR_CONTEXT) {
          this.providedContext = wordsInTrimmedProvidedContext.slice(0, MAX_WORDS_FOR_CONTEXT).join(" ");
          return MAX_WORDS_FOR_CONTEXT;
        }
        return wordsCount;
      }
      return 0;
    }
  }
};
</script>

<style scoped>
.context-input-words-count {
  position: relative;
  float: right;
  margin-top: -30px;
  z-index: 9;
  padding: 3px;
  margin-right: 6px;
  color: rgba(0, 0, 0, 0.3);
}
</style>
