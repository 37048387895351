// store/modules/machineModule.js
export default {
  namespaced: true,
  state: {
    machineData: null,
    ash: 0,
  },
  mutations: {
    SET_MACHINE_DATA(state, payload) {
      state.machineData = payload;
    },
    SET_ASH(state, ash) {
      state.ash = ash;
    },
  },
};
