<template>
  <div :key="chartKey" id="chart">
    <apexchart type="line" height="450" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

export default {
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
        },
        colors: ['grey', '#8737e1'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [5, 5],
          curve: 'straight'
        },
        title: {
          text: 'Price Trend',
          align: 'left'
        },
        legend: {
          tooltipHoverFormatter: function(val, opts) {
            return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>';
          }
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return value?.toFixed(2);
            }
          }
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            }
          ]
        },
        grid: {
          borderColor: '#f1f1f1',
          // xaxis: {
          //   lines: {
          //     show: true
          //   }
          // },
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        annotations: {
          xaxis: [
            {
              x: 605,  // Start of the range
              x2: 850,  // End of the range
              borderColor: '#808080',
              fillColor: '#808080',  // Grey color for the background
              opacity: 0.2,  // Set the opacity to make the background lighter
              label: {
                text: 'Forecasted',
                style: {
                  color: '#000',
                  fontWeight: 'bold'
                }
              }
            }
          ]
        }
      },
      chartKey: 0
    };
  },
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    currentData: {
      type: Array,
      required: true,
    },
    forecastData: {
      type: Array,
      required: true,
    },
    lineCategory: {
      type: Array,
      required: true,
    },
    viewType: {
      type: String,
      required: true,
    }
  },
  mounted() {
    this.initializeChart();
  },
  watch: {
    viewType() {
      this.initializeChart();
    },
    currentData() {
      this.initializeChart();
    },
    forecastData() {
      this.initializeChart();
    }
  },
  methods: {
    initializeChart() {
      const transformedData = this.transformDataByViewType(this.viewType);
      this.series = [
        {
          name: "Actual Price",
          data: transformedData.currentData
        },
        {
          name: "Forecasted Price",
          data: transformedData.forecastData
        }
      ];
      this.chartOptions.xaxis.categories = transformedData.categories;

      // Adjust x-axis labels for daily view
      if (this.viewType === 'daily') {
        this.chartOptions.xaxis.tickAmount = Math.min(transformedData.categories.length, 20); // Show at most 10 ticks
      }
      // Generate annotations for null values
      this.chartOptions.annotations.xaxis = this.getNullValueAnnotations(
        transformedData.currentData,
        transformedData.forecastData,
        transformedData.categories
      );

      this.chartKey += 1;
    },

    getNullValueAnnotations(currentData, forecastData, categories) {
      const annotations = [];
      let startIndex = null;

      currentData.forEach((value, index) => {
        if (value === null || forecastData[index] === null) {
          if (startIndex === null) {
            // Start of a new null region
            startIndex = index;
          }
        } else if (startIndex !== null) {
          // End of a null region, create an annotation
          annotations.push({
            x: categories[startIndex], // Start category
            x2: categories[index - 1], // End category
            borderColor: '#808080',
            fillColor: '#808080',
            opacity: 0.2,
            label: {
              text: 'Future View',
              style: {
                color: '#000',
                fontWeight: 'bold'
              }
            }
          });
          startIndex = null;
        }
      });

      // Handle case where null region extends to the last data point
      if (startIndex !== null) {
        annotations.push({
          x: categories[startIndex - 1],
          x2: categories[categories.length - 1],
          borderColor: '#808080',
          fillColor: '#808080',
          opacity: 0.2,
          label: {
            text: 'Future View',
            style: {
              color: '#000',
              fontWeight: 'bold'
            }
          }
        });
      }

      return annotations;
    },
    transformDataByViewType(viewType) {
      const { currentData, forecastData, lineCategory } = this;
      if (viewType === 'weekly') {
        return this.mapDataToInterval(currentData, forecastData, lineCategory, 'isoWeek');
      } else if (viewType === 'monthly') {
        return this.mapDataToInterval(currentData, forecastData, lineCategory, 'month');
      } else if (viewType === 'yearly') {
        return this.mapDataToInterval(currentData, forecastData, lineCategory, 'year');
      }
      return { currentData, forecastData, categories: lineCategory };
    },
    mapDataToInterval(currentData, forecastData, lineCategory, interval) {
      let mappedCurrentData = [];
      let mappedForecastData = [];
      let mappedCategories = [];
      let tempDataMap = {};
      lineCategory.forEach((date, index) => {
        let period;
        if (interval === 'isoWeek') {
          period = dayjs(date).startOf('isoWeek').format('YYYY-MM-DD');
        } else {
          period = dayjs(date).startOf(interval).format('YYYY-MM-DD');
        }
        if (!tempDataMap[period]) {
          tempDataMap[period] = {
            currentData: null,
            forecastData: null
          };
        }
        tempDataMap[period].currentData = Number(currentData[index]) == 0 ? null : Number(currentData[index]);
        tempDataMap[period].forecastData = Number(forecastData[index]);
      });

      let sortedPeriods = Object.keys(tempDataMap).sort((a, b) => dayjs(a).isBefore(dayjs(b)) ? -1 : 1);

      sortedPeriods.forEach(period => {
        mappedCategories.push(period);
        mappedCurrentData.push(tempDataMap[period].currentData);
        mappedForecastData.push(tempDataMap[period].forecastData);
      });
      return {
        currentData: mappedCurrentData,
        forecastData: mappedForecastData,
        categories: mappedCategories
      };
    }
  },
};
</script>

<style scoped>
#chart {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0;
}
</style>
