<template>
  <div class="flex-1 mb-16 overflow-y-auto">
    <div class="grid h-auto bg-white shadow-md rounded-md">
      <div
        class="flex items-center justify-between mb-4 px-4 py-2 text-sm"
      >
        <div
          class="ml-auto bg-white rounded-md overflow-hidden w-[90%] pb-2 relative image-container"
          :style="{
            backgroundImage: 'url(' + icons.dcmCover + ')',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: 'auto', // Maintain dynamic height for responsiveness
          }"
        >
          <div
            id="pellet"
            class="absolute bottom-[84%] left-[77.3%] transform -translate-x-1/2 text-black text-[0.65rem] font-bold"
          >
            {{ optimizedData && optimizedData['Pellet%']
              ? optimizedData['Pellet%'].toFixed(1) + '%'
              : '-' }}
          </div>
          <div
            id="sinter"
            class="absolute bottom-[87.5%] left-[38.7%] transform -translate-x-1/2 text-black text-[0.65rem] font-bold"
          >
            {{ optimizedData && optimizedData['Sinter%']
              ? optimizedData['Sinter%'].toFixed(1) + '%'
              : '-' }}
          </div>
          <div
            id="top_gas"
            class="absolute bottom-[48.6%] left-[50%] transform -translate-x-1/2 text-black text-[0.65rem] font-bold"
          >
            {{ optimizedData && optimizedData['top_gas_pressure_average']
              ? optimizedData['top_gas_pressure_average'].toFixed(1) +
                ' gm/cm2'
              : '-' }}
          </div>
          <div
            id="oxygen"
            class="absolute bottom-[16.10%] left-[20.5%] transform -translate-x-1/2 text-black text-[0.65rem] font-bold"
          >
            {{ optimizedData && optimizedData['oxygen_enriched_rate']
              ? optimizedData['oxygen_enriched_rate'].toFixed(1) + '%'
              : '-' }}
          </div>
          <div
            id="pci"
            class="absolute bottom-[13%] left-[56.2%] transform -translate-x-1/2 text-black text-[0.65rem] font-bold"
          >
            {{ optimizedData && optimizedData['pci_rate']
              ? optimizedData['pci_rate'].toFixed(1) + ' kg/thm'
              : '-' }}
          </div>
          <div
            id="steam"
            class="absolute bottom-[16%] left-[28.7%] transform -translate-x-1/2 text-black text-[0.65rem] font-bold"
          >
            {{ optimizedData && optimizedData['steam_addition']
              ? optimizedData['steam_addition'].toFixed(1) + ' kg/thm'
              : '-' }}
          </div>
          <div
            id="blast_temp"
            class="absolute bottom-[28.6%] left-[50%] transform -translate-x-1/2 text-black text-[0.65rem] font-bold"
          >
            {{ optimizedData && optimizedData['blast_temp']
              ? optimizedData['blast_temp'].toFixed(1) + '°C'
              : '-' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "cokeLanding",
  props: {
    optimizedData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      icons: {
        dcmCover: require('@/assets/img/bf.jpg'), // Path to your image
      },
      imageHeight: 'auto', // Store dynamically calculated height
    };
  },
  mounted() {
    this.calculateImageHeight(); // Call the method on mount
    window.addEventListener('resize', this.calculateImageHeight); // Recalculate on window resize
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateImageHeight); // Cleanup listener
  },
  methods: {
    calculateImageHeight() {
      const container = document.querySelector('.image-container');
      const image = new Image();
      image.src = this.icons.dcmCover;

      image.onload = () => {
        if (container?.offsetWidth) {
          const aspectRatio = image.height / image.width;
          // Dynamically set the height based on the aspect ratio and container width
          this.imageHeight = `${container?.offsetWidth * aspectRatio}px`;
          container.style.height = this.imageHeight;
        }
      };
    },
  },
};
</script>

<style>
.dummy {
  position: absolute;
  left: 24%;
  top: 15%;
  font-size: 0.675rem;
  font-weight: 600;
}
.blue-custom {
  background: #232B60;
}

.body-bg {
  background: #F5F6FA;
}

.tab-button {
  border: #232B60 1px solid;
  font-size: 1rem;
}

.dp__input_icon_pad {
  height: 33px;
}
</style>
