<template>
  <div class="flex h-screen">
    <TreeMenu :treeMenuFor="'BUY'"></TreeMenu>
    <div class="flex-1 p-4 mb-16 body-bg overflow-y-auto">
      <div class="space-y-4 p-4">
        <div class="flex justify-between items-center mt-4">
          <h2 class="text-lg font-bold mr-4">
            {{ selectedCommodity === 'Coking Coal' ? 'Coking Coal' : selectedCommodity }}
          </h2>
          <button
            class="bg-purple-500 text-white text-sm font-semibold px-6 py-2 rounded-sm shadow-lg hover:bg-purple-600 transition"
            @click="navigateTo('home')">
            Explore
          </button>
        </div>
        <div class="relative flex flex-col md:flex-row items-center bg-white shadow-lg rounded-sm overflow-hidden py-2">
          <div class="w-full md:w-1/4">
            <img :src="icons.buy1" alt="Row 1 Image" class="w-full h-full object-cover" />
          </div>
          <div class="w-full md:w-2/3 p-6">
            <span class="w-32 absolute bg-purple-500 text-white text-sm font-semibold px-8 py-2 box-badge">
              Price Pilot
            </span>
            <p class="text-gray-700 text-[1.10rem]">
              Price Pilot leverages Machine Learning models to predict the future price movements letting organizations
              proactively plan their commodity procurement.
            </p>
          </div>
        </div>
        <div class="relative flex flex-col md:flex-row items-center bg-white shadow-lg rounded-sm overflow-hidden py-2">
          <div class="w-full md:w-1/4">
            <img :src="icons.buy2" alt="Row 1 Image" class="w-full h-full object-cover" />
          </div>
          <div class="w-full md:w-2/3 p-6">
            <span class="w-32 absolute bg-purple-500 text-white text-sm font-semibold px-8 py-2 box-badge">
              Pulse
            </span>
            <p class="text-gray-700 text-[1.10rem]">
              Pulse - news sentiment analyzer assists organizations make timely and informed decisions by gauging
              overall market sentiment for commodities
            </p>
          </div>
        </div>
        <div class="relative flex flex-col md:flex-row items-center bg-white shadow-lg rounded-sm overflow-hidden py-2">
          <div class="w-full md:w-1/4">
            <img :src="icons.buy3" alt="Row 1 Image" class="w-full h-full object-cover" />
          </div>
          <div class="w-full md:w-2/3 p-6">
            <span class="w-32 absolute bg-purple-500 text-white text-sm font-semibold px-8 py-2 box-badge">
              Text Tune
            </span>
            <p class="text-gray-700 text-[1.10rem]">
              Text Tune - document summarizer assists organizations draw insights from long-form reports and make
              informed decisions by incorporating expert viewpoints for commodities
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TreeMenu from '../components/CommodityPathfinder/TreeMenu.vue';
import { mapActions } from 'vuex';
export default {
  components: {
    TreeMenu
  },
  data() {
    return {
      icons: {
        buy1: require('@/assets/img/buy-1.png'), // Your image path
        buy2: require('@/assets/img/buy-2.png'), // Your image path
        buy3: require('@/assets/img/buy-3.png'), // Your image path
      },
    }
  },
  methods: {
    ...mapActions('sheets', [
      'fetchAndStoreArticleWiseOutputData',
      'fetchAndStorePriceProjectionsData'
    ]),
    navigateTo(page) {
      const commodity = this.$route.params.commodity;
      this.$router.push(`/commodity-pathfinder/buy/${commodity}/${page}`);
    },
    updateCommodity() {
      this.selectedCommodity = this.$route.params.commodity?.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
    }
  },
  mounted() {
    this.fetchAndStorePriceProjectionsData()
    this.fetchAndStoreArticleWiseOutputData();
  },
  watch: {
    '$route'(to, from) {
      this.updateCommodity();
    }
  },
  created() {
    this.updateCommodity();
  }
};
</script>

<style scoped>
.body-bg {
  border: none !important;
}

.box-badge {
  top: 0;
  align-items: first baseline;
  text-align: center;
  left: 80%;
}
</style>
