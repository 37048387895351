<template>
  <div class="w-full mb-4">
    <processParameterIntroduction />
  </div>
</template>

<script>
import processParameterIntroduction from "@/components/ProcessParameterOptimizer/processParameterIntroduction.vue";

export default {
  name: "ProcessParameterIntroductionView",
  components: {
    processParameterIntroduction
  }
};
</script>
