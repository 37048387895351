<template>
  <div class="flex h-screen">
    <TreeMenu></TreeMenu>
    <div class="flex-1 p-4 mb-16 body-bg overflow-y-auto">
      <div class="grid grid-rows-[auto,auto,1fr] p-4 h-screen-auto bg-white shadow-md rounded-md p-4 mx-4">
        <div class="h-16 mb-4">
          <h1 class="font-bold text-[2rem] mt-2">Coal Beneficiation Digital Twin - Input</h1>
        </div>
        <div class="flex items-center justify-between mb-4 pl-1 text-sm ">
          <div class="bg-white shadow-md rounded-md overflow-hidden w-2/3 pb-2">
            <table class="w-full text-left">
              <thead>
                <tr class="bg-blue-custom">
                  <th colspan="2" class="py-3 px-4 border-b text-white" style="width: 60%;">Control Parameters</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="py-2 px-4">Separation Density HMC, kg/m3</td>
                  <td class="py-2 px-4">
                    <input type="number" v-model="value1"
                      class="text-[1.5rem] text-center rounded-sm font-bold text-ppo-custom-blue w-full h-10 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      style="width: 60%;" />
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-4">Separation Density Hydrosizer, kg/m3 </td>
                  <td class="py-2 px-4">
                    <input type="number" v-model="value2"
                      class="text-[1.5rem] text-center rounded-sm font-bold text-ppo-custom-blue w-full h-10 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      style="width: 60%;" />
                  </td>
                </tr>
              </tbody>
            </table>

            <div>
              <button v-if="!loading" @click="fetchData"
                class="ml-4 my-4 px-12 py-2 blue-custom text-white text-sm font-medium hover:blue-custom-400 transition-all">
                Estimate
              </button>
              <div v-if="loading" class="flex mt-4 ml-12 pb-3">
                <div
                  class="spinner-border animate-spin border-4 border-blue-600 border-t-transparent rounded-full w-10 h-10">
                </div>
              </div>
            </div>
          </div>

          <div class="w-1/4 pr-1">
            <div class="bg-white rounded-md shadow-md p-3 max-w-xs h-28">
              <div class="text-sm text-gray-800 font-semibold mb-5">
                Concentrate (t/h)
              </div>
              <div key="concentrate" :class="['value-transition', isConcentrateChanged ? 'fade-in' : 'fade-in-active']"
                @transitionend="handleTransitionEnd('concentrate')" class="flex items-center mb-1">
                <div class="text-[2.5rem] font-bold text-ppo-custom-blue">
                  {{ machineData?.concentrate ? Math.round(machineData?.concentrate) : 0 }}
                </div>
                <div class="text-[1.3rem] font-bold text-ppo-custom-blue pt-4">&nbsp;t/h</div>
              </div>
            </div>
            <div class="bg-white rounded-md shadow-md p-3 max-w-xs mt-3 h-28">
              <div class="text-sm text-gray-500 font-semibold mb-5">
                Ash (%)
              </div>
              <!-- <transition name="fade"> -->
              <div key="ash" :class="['value-transition', isConcentrateChanged ? 'fade-in' : 'fade-in-active']"
              @transitionend="handleTransitionEnd('ash')"  class="flex items-center mb-1">
                <div class="text-[2.5rem] font-bold text-ppo-custom-blue">{{ ash ||
                  0 }}</div>
                <div class="text-[1.3rem] font-bold text-ppo-custom-blue pt-4">&nbsp;%</div>
              </div>
              <!-- </transition> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TreeMenu from './TreeMenu.vue';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'machineFlow',
  components: { TreeMenu },
  data() {
    return {
      value1: '',
      value2: '',
      // ash: 0,
      icons: {
        machineFlow: require('@/assets/img/machine_flow.png'), // Your image path
      },
      imageHeight: 0,
      isConcentrateChanged: false,
      isAshChanged: false,
      loading: false,
    };
  },
  computed: {
    ...mapState('machineFlow', ['machineData', 'ash']),
  },
  mounted() {
    this.SET_MACHINE_DATA(null);
    this.SET_ASH(0);
  },
  methods: {
    ...mapMutations('machineFlow', ['SET_MACHINE_DATA', 'SET_ASH']),
    findNearestKey(value1, value2, machineFlowData) {
      const keys = Object.keys(machineFlowData);
      let nearestKey = null;
      let smallestDistance = Infinity;

      keys.forEach((key) => {
        const [key1, key2] = key.split('_').map(Number); // Extract numbers from the key
        const distance = Math.sqrt(
            Math.pow(key1 - value1, 2) + Math.pow(key2 - value2, 2)
        );

        if (distance < smallestDistance) {
            smallestDistance = distance;
            nearestKey = key;
        }
      });

      return nearestKey;
    },
    async fetchData() {
      this.loading = true;

      // Simulate fetching data
      setTimeout(() => {
        const machineFlowData = require('./machineFlowData.json'); // Ensure path is correct
        const key = `${this.value1}_${this.value2}`;
        if (machineFlowData[key]) {
          // Update Vuex state
          this.SET_MACHINE_DATA(machineFlowData[key]);
          this.SET_ASH(7.7);
        } else {
          const nearestKey = this.findNearestKey(this.value1, this.value2, machineFlowData);
          if (nearestKey) {
            this.SET_MACHINE_DATA(machineFlowData[nearestKey]);
            this.SET_ASH(7.7);
          } else {
            alert('Please enter correct values!');
            this.SET_MACHINE_DATA(null);
            this.SET_ASH(0);
          }
        }
        this.loading = false;
      }, 1000);
    },
    handleTransitionEnd(type) {
      // Reset the transition flags after the transition ends
      if (type === 'concentrate') {
        this.isConcentrateChanged = false;
      } else if (type === 'ash') {
        this.isAshChanged = false;
      }
    },

  },
};
</script>


<style>
.blue-custom {
  background: #232B60;
}

.body-bg {
  background: #F5F6FA;
  border-left: #D9D9D9 1px solid;
}

.tab-button {
  border: #232B60 1px solid;
  font-size: 1rem;
}

.dp__input_icon_pad {
  height: 33px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}


/* Default CSS: Applied to all resolutions */
.image-container div[id^="magnetic_separator"],
.image-container div[id^="classification_screen"],
.image-container div[id^="heavy_medium_cyclone"],
.image-container div[id^="refuse_screen1"],
.image-container div[id^="clean_coal_screen1"],
.image-container div[id^="centrifuge1"],
.image-container div[id^="centrifuge2"],
.image-container div[id^="refuse_screen2"],
.image-container div[id^="clean_coal_screen2"],
.image-container div[id^="hydrosizer"],
.image-container div[id^="hydroclone_1_stage"],
.image-container div[id^="decanter"],
.image-container div[id^="filter_flotation"],
.image-container div[id^="main_flotation"],
.image-container div[id^="hydroclone_2_stage"] {
  position: absolute;
  transform: translate(-50%, -5%);
  /* Default centering */
}

.image-container {
  width: 100% !important;
}

/* Media Query for 1536 x 864 resolution and 16:9 aspect ratio */
@media (min-width: 1536px) and (max-width: 1536px) and (min-height: 864px) and (max-height: 864px) and (aspect-ratio: 16/9) {
  .image-container {
    width: 100% !important;
  }

  .image-container div[id^="magnetic_separator"],
  .image-container div[id^="classification_screen"],
  .image-container div[id^="heavy_medium_cyclone"],
  .image-container div[id^="refuse_screen1"],
  .image-container div[id^="clean_coal_screen1"],
  .image-container div[id^="centrifuge1"],
  .image-container div[id^="centrifuge2"],
  .image-container div[id^="refuse_screen2"],
  .image-container div[id^="clean_coal_screen2"],
  .image-container div[id^="hydrosizer"],
  .image-container div[id^="hydroclone_1_stage"],
  .image-container div[id^="decanter"],
  .image-container div[id^="filter_flotation"],
  .image-container div[id^="main_flotation"],
  .image-container div[id^="hydroclone_2_stage"] {
    position: absolute;
    transform: translate(-55%, -13%);
    line-height: 1.6rem;
  }
}

/* For other devices or viewport sizes */
@media (max-width: 1280px) and (max-height: 800px) {

  .image-container div[id^="magnetic_separator"],
  .image-container div[id^="classification_screen"],
  .image-container div[id^="heavy_medium_cyclone"],
  .image-container div[id^="refuse_screen1"],
  .image-container div[id^="clean_coal_screen1"],
  .image-container div[id^="centrifuge1"],
  .image-container div[id^="centrifuge2"],
  .image-container div[id^="refuse_screen2"],
  .image-container div[id^="clean_coal_screen2"],
  .image-container div[id^="hydrosizer"],
  .image-container div[id^="hydroclone_1_stage"],
  .image-container div[id^="decanter"],
  .image-container div[id^="filter_flotation"],
  .image-container div[id^="main_flotation"],
  .image-container div[id^="hydroclone_2_stage"] {
    transform: translate(-50%, -5%);
    /* Default for smaller screens */
  }
}


.image-container {
  position: relative;
  width: 100%;
  height: auto;
  /* Maintain aspect ratio */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.value-transition {
  transition: transform 1s ease, opacity 1s ease;
}

.fade-in {
  opacity: 0;
}

.fade-in-active {
  opacity: 1;
  transform: scale(1);
}
</style>
