<template>
  <div class="w-full mb-4">
    <machineFlowIntroduction />
  </div>
</template>

<script>
import machineFlowIntroduction from "@/components/MachineFlow/machineFlowIntroduction.vue";

export default {
  name: "ProcessParameterMachineFlowInputView",
  components: {
    machineFlowIntroduction
  }
};
</script>
