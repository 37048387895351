<template>
  <div class="w-64 bg-white text-black pl-1 pb-16 h-full overflow-y-auto sidebar">
    <ul class="space-y-2 mt-8">
      <li v-for="item in menuItems" :key="item.name" class="relative">
        <router-link
          :to="item.url"
          :class="['flex items-center mx-8 pl-4 text-center py-2 active-menu rounded', { 'active-menu1': isActive(item) }]"
        >
          {{ item.name }}
        </router-link>
      </li>
    </ul>
    <!-- Bottom menu -->
    <div class="bottom-menu">
      <ul>
        <li class="relative">
          <router-link
            to="#"
            class="flex items-center pl-4 py-2 rounded cursor-not-allowed opacity-50"
            @click.prevent
          >
          <img :src="icons['settings']" alt="" class="text-white inline h-7 m-1 mr-2"> Settings
          </router-link>
        </li>
        <li class="relative">
          <router-link
            to="#"
            class="flex items-center pl-4 py-2 rounded cursor-not-allowed opacity-50"
            @click.prevent
          >
          <img :src="icons['help']" alt="" class="inline h-7 m-1 mr-2"> Help
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuItems: [
        {
          name: 'Introduction',
          url: '/machine-flow/introduction',
          isOpen: false,
        },
        {
          name: 'Control Center Input',
          url: '/machine-flow/input',
          isOpen: false,
        },
        {
          name: 'Output: Process Flow',
          url: '/machine-flow/output',
          isOpen: false,
        },
      ],
      icons: {
        help: require('@/assets/img/help-icon.svg'),
        settings: require('@/assets/img/settings-icon.svg'),
      }
    };
  },
  methods: {
    isActive(item) {
      const currentRoute = this.$route.path;
      return currentRoute.startsWith(item.url);
    },
  },
};
</script>

<style scoped>
.sidebar {
  height: 100%;
  background-color: #232B60;
  color: #fff;
  position: relative; /* Ensure child elements respect the container */
}
.active-menu:hover {
  color: #232B60;
  background-color: #fff;
}
.active-menu1 {
  color: #232B60;
  background-color: #fff;
}
.bottom-menu {
  position: absolute;
  bottom: 12%;
  width: 86%;
  padding-bottom: 1rem;
  padding-left: 2rem;
}
</style>
